import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Row,
  Col,
} from "reactstrap";
import { BsList } from "react-icons/bs";

import "bootstrap/dist/css/bootstrap.min.css";
import "../elements/Styles.css";
import GetTokenApi from "../pages/KeyCloak/GetTokenApi";
import CheckPermission from "../pages/Auth/CheckPermission";

const Header = (props) => {
  const navigate = useNavigate();

  let url = window.location.href.split("/");

  let page = url[3];

  // Recupera dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  // Define o hook para armazenamento do token de acesso à API
  const [userPermission, setUserPermission] = useState(null);
  // Realiza a requisição de verificação da autenticação do usuário
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  function getUserData() {}

  // Aguarda até a verificação da autenticação do usuário seja realizada
  // e inicia a requisição dos dados à API
  useEffect(() => {
    if (userPermission) {
      getUserData();
      getPermissionsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  let args = {
    fixed: "top",
    expand: true,
  };

  // Define os hooks de abertura do menu
  const [isOpen, setIsOpen] = useState(false);
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // Define os hooks de abertuda do modal de detalhes do usuário
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  // Recurso do logout e redirecionamento do sistema
  const logout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/login");
    }, 100);
  };

  // Hook de armazenamento do dicionário de permissões
  const [permissionsData, setPermissionsData] = useState([]);

  // Requisição de obtenção do dicionário de permissões
  function getPermissionsData() {
    fetch(apiUrl + "/users/roles", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setPermissionsData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  // Construção do layout da página
  return (
    <div style={{ zIndex: "1000" }}>
      <Modal
        size="md"
        isOpen={modalIsOpen}
        toggle={toggleModal}
        backdrop={true}
        keyboard={false}
        style={{ overflow: "hidden" }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: "0",
            right: "-100px",
            bottom: "-80px",
            background: "url('/imgs/logo_robotictech_alpha.png')",
            backgroundSize: "cover",
            opacity: "0.08",
            width: "500px",
            height: "500px",
          }}
        ></div>
        <ModalBody style={{ zIndex: "20" }}>
          <Row className="mb-2">
            <Col className="text-center">
              {localStorage.getItem("robotictech_company_logo") &&
              localStorage.getItem("robotictech_company_logo") !== "null" &&
              localStorage.getItem("robotictech_company_logo") !== "" ? (
                <img
                  alt="company_logo"
                  src={localStorage.getItem("robotictech_company_logo")}
                  style={{ maxWidth: "50%" }}
                  className="m-1"
                />
              ) : (
                <img
                  alt="company_logo"
                  src="/imgs/logo_empty.png"
                  style={{ maxWidth: "200px" }}
                  className="m-auto"
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <Row>
                <Col>Nome</Col>
              </Row>
              <Row>
                <Col>
                  <h3>
                    {userPermission ? userPermission.first_name : ""}{" "}
                    {userPermission ? userPermission.last_name : ""}
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Row>
                <Col>Username</Col>
              </Row>
              <Row>
                <Col>
                  <h3 style={{ wordWrap: "break-word" }}>
                    {userPermission ? userPermission.username : "-"}
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Row>
                <Col>Email</Col>
              </Row>
              <Row>
                <Col>
                  <h3 style={{ wordWrap: "break-word" }}>
                    {userPermission ? userPermission.email : "-"}
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Row>
                <Col>Permissão</Col>
              </Row>
              <Row>
                <Col>
                  <h3>
                    {userPermission
                      ? permissionsData.map((permission) => {
                          if (permission.role === userPermission.role)
                            return permission.name;
                          else return false;
                        })
                      : "-"}
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Row>
                <Col>Empresa</Col>
              </Row>
              <Row>
                <Col>
                  <h3>
                    {localStorage.getItem("robotictech_company_name")
                      ? localStorage.getItem("robotictech_company_name")
                      : "-"}
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bg-white" style={{ zIndex: "20" }}>
          {userPermission ? (
            <Link
              className={"btn btn-outline-secondary"}
              to={"/editarusuario/" + userPermission.id}
            >
              Editar dados
            </Link>
          ) : (
            ""
          )}
          <Button color="secondary" onClick={toggleModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Navbar
        fixed="top"
        {...args}
        className="nav-header"
        style={page === "mapa" ? { zIndex: "1000" } : { zIndex: "1050" }}
      >
        <Button
          className="me-3"
          color="outline-secondary"
          onClick={() => setOffCanvasIsOpen(true)}
        >
          <BsList />
        </Button>
        <NavbarBrand
          //  href={props && props.url ? "/" + props.url : "/home"}
          onClick={() =>
            navigate(props && props.url ? "/" + props.url : "/home")
          }
          style={{ cursor: "pointer", overflow: "hidden" }}
        >
          {props && props.title ? props.title : ""}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar></Nav>
          {window.location.pathname.indexOf("mapa") === -1
            ? localStorage.getItem("robotictech_company_name")
              ? localStorage.getItem("robotictech_company_name")
              : "RoboticTech"
            : ""}
          {false &&
          localStorage.getItem("robotictech_company_logo") &&
          localStorage.getItem("robotictech_company_logo") !== "null" &&
          localStorage.getItem("robotictech_company_logo") !== "" ? (
            <img
              alt="company_logo"
              src={localStorage.getItem("robotictech_company_logo")}
              style={{ maxHeight: "30px" }}
              className="m-1"
            />
          ) : (
            <img
              alt="company_logo"
              src="/imgs/logo_robotictech_alpha.png"
              style={{ maxHeight: "30px" }}
              className="m-1"
            />
          )}
        </Collapse>
      </Navbar>
      <Offcanvas isOpen={offCanvasIsOpen} style={{ zIndex: "1900 !important" }}>
        <OffcanvasHeader toggle={() => setOffCanvasIsOpen(false)}>
          <strong>Menu</strong>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Link to={"/home"}>
            <p className="text-start">Home</p>
          </Link>
          <Link onClick={toggleModal}>
            <p className="text-start">Meu perfil</p>
          </Link>
          {CheckPermission(
            userPermission && userPermission.role ? userPermission.role : "",
            "MANAGER"
          ) ? (
            CheckPermission(
              userPermission && userPermission.role ? userPermission.role : "",
              "ADMIN"
            ) ? (
              <Link to={"/companiesManagement"}>
                <p className="text-start">Gerenciamento</p>
              </Link>
            ) : (
              <Link
                to={
                  "/companiesManagement/" +
                  localStorage.getItem("robotictech_company_id")
                }
              >
                <p className="text-start">Gerenciamento</p>
              </Link>
            )
          ) : (
            ""
          )}
          <hr />
          <Link onClick={logout}>
            <p className="text-start">Logout</p>
          </Link>
        </OffcanvasBody>
      </Offcanvas>
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          height: "50vh",
          width: "50vw",
          zIndex: "-90",
          overflow: "hidden",
          opacity: "0.025",
        }}
      >
        <img
          alt="strap_ball_alpha"
          src="/imgs/strap_ball_alpha.png"
          id="bigball"
          style={{
            width: "100%",
            position: "relative",
            right: "-50%",
            top: "-60%",
          }}
        />
      </div>

      <div
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
          height: "100vh",
          width: "50vw",
          zIndex: "-90",
          overflow: "hidden",
          opacity: "0.04",
        }}
      >
        <img
          alt="big_ball_alpha"
          src="/imgs/big_ball_alpha.png"
          id="bigball"
          style={{
            width: "100%",
            position: "relative",
            top: "10vh",
            left: "-50%",
            bottom: "0%",
          }}
        />
      </div>
      <div
        className="text-center"
        style={{
          position: "fixed",
          bottom: "0px",
          left: "0px",
          width: "100vw",
          zIndex: "2000",
          backgroundColor: "#ecf0f3",
          boxShadow: "0px 0px 8px 8px #ecf0f3",
        }}
      >
        Powered by RoboticTech
      </div>
    </div>
  );
};
export default Header;
