import React, {
  useEffect,
  // useCallback,
  // useLayoutEffect,
  // useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
// import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// import { Form, Button, Container } from "reactstrap";
let cont = 0;
function ImageCrop(props) {
  const [image, setImage] = useState(null);
  //let elementCanvas = <canvas ref="myCanvas" />;

  const canvasRef = React.createRef();
  useEffect(() => {
    // console.log(elementCanvas);
    // const canvas = ReactDOM.findDOMNode(elementCanvas.ref);
    let canvas = ReactDOM.findDOMNode(canvasRef.current);
    // console.log(canvas);
    //const canvas = <canvas />;
    const ctx = canvas.getContext("2d");

    var imageFile = new Image();
    imageFile.src = props.imageSrc;

    imageFile.onload = function () {
      let imageRatio =
        (props.detectionData.height_bb * imageFile.height) /
        (props.detectionData.width_bb * imageFile.width);
      canvas.height = imageRatio * canvas.width;
      ctx.drawImage(
        imageFile,
        props.detectionData.xcenter_bb * imageFile.width -
          (props.detectionData.width_bb * imageFile.width) / 2, // sourceX
        props.detectionData.ycenter_bb * imageFile.height -
          (props.detectionData.height_bb * imageFile.height) / 2, // sourceY
        props.detectionData.width_bb * imageFile.width, //sourceWidth
        props.detectionData.height_bb * imageFile.height, // sourceHeight
        0, // destinationX
        0, // destinationY
        canvas.width, // destinationWidth
        canvas.height // destinationHeight
      );
      //   let imageRatio = canvas.width * props.detectionData.width_bb;
      //   console.log(imageRatio * props.detectionData.height_bb);
      //   canvas.width = "100%";
      //   canvas.height =
      //     imageRatio * props.detectionData.height_bb * canvas.height;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);
  if (cont++ < 2) {
    setImage(props.imageSrc);
  }
  //return canvas;
  return (
    <canvas
      ref={canvasRef}
      //   width={canvasWidth * ratio}
      //   height={canvasHeight * ratio}
      style={{
        // display: loadingImage ? "none" : "block",
        border: "2px solid #000",
        margin: "auto",
        width: "100%",
        //height: "200px",
        //height: `${canvasHeight}px`
        backgroundColor: "black",
      }}
    ></canvas>
  );
}

export default ImageCrop;
