import React, { useState, useEffect } from "react";
import {
  BsArrowLeft,
  BsArrowsMove,
  BsCheckLg,
  BsClockHistory,
  BsCrop,
  BsFillTrashFill,
  BsMouseFill,
  BsPinMap,
  BsPlusSquareDotted,
  BsSdCard,
  BsTrash,
  BsXLg,
} from "react-icons/bs";
import {
  CircleMarker,
  MapContainer,
  TileLayer,
  useMapEvents,
  LayersControl,
  LayerGroup,
  Marker,
} from "react-leaflet";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  // Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Progress,
  Row,
  Spinner,
  Table,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Header from "../../elements/Header";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import cidadesEstadosCoords from "../../elements/CidadesEstadosCoords";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import JSZip from "jszip";
import MarkerClusterGroup from "react-leaflet-cluster";
import CheckPermission from "../Auth/CheckPermission";
import Leaflet from "leaflet";
import axios from "axios";

require("leaflet-path-drag");
require("leaflet-path-transform");

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
const EditPoints = (props) => {
  document.title = "Editar Marcações - RoboticTech";
  let { id: missionId } = useParams();

  const navigate = useNavigate();

  let defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
  // let selectedPointColor = process.env.REACT_APP_SELECTED_POINT_COLOR;
  let apiUrl = process.env.REACT_APP_API_URL;
  let baseMaps = JSON.parse(process.env.REACT_APP_LEAFLET_BASEMAPS);
  let minZoomToGroupPoints = process.env.REACT_APP_MIN_ZOOM_TO_GROUP_POINTS;

  let selectedPointColor = process.env.REACT_APP_SELECTED_POINT_COLOR;
  let defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;
  // let maxZoomToGroupPoints = process.env.REACT_APP_MAX_ZOOM_TO_GROUP_POINTS;
  // let colorScale = JSON.parse(process.env.REACT_APP_COLOR_SCALE);
  const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);
  const selectedPointIcon = new Leaflet.Icon({
    iconUrl: "/imgs/selectedPointMap.png",
    iconAnchor: [22, 62],
    popupAnchor: [10, -44],
    iconSize: [44, 72],
  });
  const [loadedData, setLoadedData] = useState(false);
  const [centerScreenMap, setCenterScreenMap] = useState(null);
  //Begin KMZ

  const [kmzProject, setKmzProject] = useState([]);
  const [kmzActive, setKmzActive] = useState(false);
  const [loadingKmz, setLoadingKmz] = useState(false);
  const [activeKmzSelected, setActiveKmzSelected] = useState(false);

  const [kmlList, setKmlList] = useState({});
  const [kmlListPreview, setKmlListPreview] = useState();
  const [zoomLevelInfo, setZoomLevelInfo] = useState([]);

  const [progressBar, setProgressBar] = useState(0);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [orientationMode, setOrientationMode] = useState(null);

  const [axiosCancelToken, setAxiosCancelToken] = useState(
    axios.CancelToken.source()
  );

  const [map, setMap] = useState(null);

  const [transformLayer, setTransformLayer] = useState(false);
  const [drawningPolygonList, setDrawningPolygonList] = useState([]);
  const [drawning, setDrawning] = useState(false);
  const [drawningLayerTemp, setDrawningLayerTemp] = useState([]);

  const [rotatingLayer, setRotatingLayer] = useState(null);

  function handleResize() {
    setWindowSize(null);
    setWindowSize([window.innerWidth, window.innerHeight]);
  }

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    console.log("Orientarion: " + orientationMode);
  }, [orientationMode]);

  useEffect(() => {
    if (map) {
      if (drawning) map.dragging.disable();
      else map.dragging.enable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawning]);

  useEffect(() => {
    // handleResize();
    if (
      windowSize &&
      windowSize[0] &&
      windowSize[1] &&
      windowSize[0] > windowSize[1]
    ) {
      setOrientationMode("portrait");
    } else if (
      windowSize &&
      windowSize[0] &&
      windowSize[1] &&
      windowSize[0] <= windowSize[1]
    ) {
      setOrientationMode("landscape");
    } else {
      setOrientationMode(null);
    }
  }, [windowSize]);

  useEffect(() => {
    if (drawningLayerTemp && drawningLayerTemp.length > 0) {
      if (map)
        map.eachLayer(function (layer) {
          if (
            layer &&
            layer.options &&
            layer.options.id &&
            layer.options.id.indexOf("marcacoes") !== -1
            // layer.options.id === "marcacoes"
          ) {
            map.removeLayer(layer);
          }
        });

      var layers = Leaflet.layerGroup();

      let auxDrawningPolygonList = [];
      drawningLayerTemp.forEach((layer, contLayer = 0) => {
        if (layer[0] && layer[0][0] && layer[0][1]) {
          let circlePlPoint = Leaflet.circleMarker(layer[0], {
            key: Math.random(),
            radius: defaultRadiusMarker,
            fillOpacity: 0.9,
            color:
              colorLayersList[contLayer] && colorLayersList[contLayer][2]
                ? colorLayersList[contLayer][2]
                : "purple",
            fillColor:
              colorLayersList[contLayer] && colorLayersList[contLayer][2]
                ? colorLayersList[contLayer][2]
                : "purple",
          });
          var mapTapHoldTimeoutCircle;

          circlePlPoint
            .addTo(layers)
            .on("mousedown", function () {
              if (!modalNewPublicLightPoint && !offCanvas) {
                if (!pointsNewPolygonMapExpressMode) {
                  setPointsNewPolygonMapSelectedLayer(contLayer - 1);
                }
                mapTapHoldTimeoutCircle = setTimeout(() => {
                  moverPlPoint(contLayer - 1);
                  setTransformLayer(-1);
                }, 350);
              }
            })
            .on("mouseup", function () {
              if (mapTapHoldTimeoutCircle) {
                clearTimeout(mapTapHoldTimeoutCircle);
              }
            });
        }
        if (
          layer[1] &&
          layer[1][0] &&
          layer[1][0][0] &&
          layer[1][0][1] &&
          layer[1][1] &&
          layer[1][1][0] &&
          layer[1][1][1] &&
          layer[1][2] &&
          layer[1][2][0] &&
          layer[1][2][1] &&
          layer[1][3] &&
          layer[1][3][0] &&
          layer[1][3][1]
        ) {
          let polygon = Leaflet.polygon(layer[1], {
            key: Math.random(),
            transform: true,
            color:
              colorLayersList[contLayer] && colorLayersList[contLayer][2]
                ? colorLayersList[contLayer][2]
                : "purple",
            fillColor:
              colorLayersList[contLayer] && colorLayersList[contLayer][2]
                ? colorLayersList[contLayer][2]
                : "purple",
            interactive:
              processMovingRectangle && processMovingRectangle >= 0
                ? false
                : true,
          });

          polygon.on("click", function () {});

          var mapTapHoldTimeout;

          polygon.on("mousedown", function () {
            if (!modalNewPublicLightPoint && !offCanvas) {
              mapTapHoldTimeout = setTimeout(() => {
                moverMarcacao(contLayer - 1);
              }, 350);
            }
          });

          polygon.on("mouseup", function () {
            if (mapTapHoldTimeout) {
              clearTimeout(mapTapHoldTimeout);
            }
            if (!modalNewPublicLightPoint && !offCanvas) {
              if (!pointsNewPolygonMapExpressMode) {
                setTimeout(() => {
                  setPointsNewPolygonMapSelectedLayer(contLayer - 1);
                  setTransformLayer(-1);
                  setTransformLayer(contLayer - 1);
                }, 300);
              }
            }
          });

          polygon.addTo(layers);
          if (transformLayer === contLayer) {
            setRotatingLayer(polygon);
          } else {
            polygon.transform.disable();
          }

          drawningPolygonList.map((polygon) => {
            return polygon.transform.disable();
          });

          auxDrawningPolygonList.push(polygon);
        }
        contLayer = contLayer + 1;
      });
      setDrawningPolygonList(auxDrawningPolygonList);

      let idLayer = "marcacoes" + Math.random();
      if (layers) {
        if (!layers.options) {
          layers.options = {};
        }
        if (!layers.options.id) {
          layers.options.id = idLayer;
        }
      }
      if (map && layers) map.addLayer(layers);
    } else {
      // if (!drawningLayerTemp || drawningLayerTemp.length === 0) {
      // let auxDrawningLayerTemp = drawningLayerTemp;
      // auxDrawningLayerTemp = [[]];
      // setDrawningLayerTemp(auxDrawningLayerTemp);
      // }
      // if (map)
      //   map.eachLayer(function (layer) {
      //     //if (layer instanceof Leaflet.layerGroup)
      //     if (
      //       layer &&
      //       layer.options &&
      //       layer.options.id &&
      //       layer.options.id === "layer"
      //     ) {
      //       map.removeLayer(layer);
      //     }
      //   });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawningLayerTemp, transformLayer, zoomLevelInfo]);

  useEffect(() => {
    if (rotatingLayer) {
      try {
        let auxRotatingLayer = rotatingLayer;
        auxRotatingLayer.transform.enable({
          rotation: true,
          scaling: true,
          draggable: true,
          uniformScaling: true,
        });

        auxRotatingLayer.on("transformed", (i) => {
          let auxDrawningLayerTemp = drawningLayerTemp;

          auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][0] = [
            i.sourceTarget._latlngs[0][0].lat,
            i.sourceTarget._latlngs[0][0].lng,
          ];
          auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][1] = [
            i.sourceTarget._latlngs[0][1].lat,
            i.sourceTarget._latlngs[0][1].lng,
          ];
          auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][2] = [
            i.sourceTarget._latlngs[0][2].lat,
            i.sourceTarget._latlngs[0][2].lng,
          ];
          auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][3] = [
            i.sourceTarget._latlngs[0][3].lat,
            i.sourceTarget._latlngs[0][3].lng,
          ];
          setDrawningLayerTemp(auxDrawningLayerTemp);
          setTransformLayer(-1);
        });

        setRotatingLayer(auxRotatingLayer);
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotatingLayer]);

  const getExtension = (fileName) => {
    let ext = "";
    let aux = fileName.split(".");
    ext = aux[aux.length - 1];
    return ext;
  };
  const parser = new DOMParser();
  //End KMZ

  const [originMarkers, setOriginMarkers] = useState([]);
  const [baseMapSource, setBaseMapSource] = useState(null);

  const [missionDetails, setMissionDetails] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);
  const [centerMapTag, setCenterMapTag] = useState(false);
  const [oneTimeCenter, setOneTimeCenter] = useState(false);
  // const [centerMapTagUpdate, setCenterMapTagUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const [modalNotificacao, setModalNotificacao] = useState(false);

  const [modalProcessamento, setModalProcessamento] = useState(false);

  const [showProcessedPoints, setShowProcessedPoints] = useState(true);

  const [pointsNewPolygonMap, setPointsNewPolygonMap] = useState([]);
  const [pointsNewPolygonMapExpressMode, setPointsNewPolygonMapExpressMode] =
    useState(false);
  const [
    pointsNewPolygonMapSelectedLayer,
    setPointsNewPolygonMapSelectedLayer,
  ] = useState(0);
  const [messageDiv, setMessageDiv] = useState(null);
  const [processMarkingRectangle, setProcessMarkingRectangle] = useState(false);
  const [processMovingPLPoint, setProcessMovingPLPoint] = useState(-1);
  const [processMovingRectangle, setProcessMovingRectangle] = useState(-1);
  const [processMovingRectangleOrigin, setProcessMovingRectangleOrigin] =
    useState(-1);
  const [processMovingAllMarker, setProcessMovingAllMarker] = useState(-1);

  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  // functions KMZ

  const ZoomUpdate = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setZoomLevelInfo(mapEvents.getZoom());
      },
      moveend: () => {
        setCenterScreenMap(map.getCenter());
      },
    });
    return null;
  };

  // begin controls offcanvas detalhes ponto

  const [offCanvas, setOffCanvas] = useState(false);
  const toggleOffCanvas = () => {
    setPointDetailOffcanvas();
    setNewPointPreviewMap([]);
    setOffCanvas(!offCanvas);
  };
  const [pointDetailOffcanvas, setPointDetailOffcanvas] = useState(null);

  const setFocusPoint = (point) => {
    if (originMarkers.length > 0) {
      originMarkers.map((value) => {
        value.radius = false;
        value.customColor = false;
        return value;
      });
      if (point) {
        point.radius = 15;
        point.customColor = selectedPointColor;
      }
    }
  };

  // Abertura dos detalhes do ponto na lateral direita
  const openPointDetails = (point) => {
    if (!modalNewPublicLightPoint) {
      setPointDetailOffcanvas(point);

      // Atualiza o token de acesso à API
      GetTokenApi().then((res) => {
        // Atualiza o token de acesso dentro do hook
        setUserPermission(res);

        // Início: Quando selecionado um ponto, reseta a camada atual
        // se a mesma estiver incompleta
        if (
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer] ||
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer][0] ||
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1] ||
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][0] ||
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][1] ||
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][2] ||
          !drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][3]
        ) {
          let auxDrawningLayerTemp = drawningLayerTemp;
          auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer] = [[], []];
          setDrawning(false);
          setDrawningLayerTemp(auxDrawningLayerTemp);
          setMessageDiv(null);
          setProcessMarkingRectangle(false);
        }
        // Fim: Quando selecionado um ponto, reseta a camada atual
        // se a mesma estiver incompleta
      });
      setOffCanvas(true);
    }
  };

  // end controls offcanvas detalhes ponto

  // begin controls lamp types
  const [lampTypes, setLampTypes] = useState(null);
  // Realiza a requisição para obtenção do dicionário de tipos de lâmpadas
  const getLampTypes = () => {
    fetch(apiUrl + "/publiclightingpoints/lamp-types", {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setLampTypes(data);
      })
      .catch((error) => {
        console.log(error);
        setCount(Math.random());
      })
      .finally(() => {});
  };
  // end controls lamp types

  // begin controls offcanvas editar ponto

  const [newPointPreviewMap, setNewPointPreviewMap] = useState([]);
  const [count, setCount] = useState();
  const [modalNewPublicLightPoint, setModalNewPublicLightPoint] =
    useState(false);
  const [newPublicLightPointMessage, setNewPublicLightPointMessage] =
    useState("");
  const showPublicLoghtPointMessage = (msg) => {
    setNewPublicLightPointMessage(msg);
    setTimeout(() => {
      setNewPublicLightPointMessage("");
    }, 2000);
  };

  // Recebe os dados do formulário de edição/novo plpoint
  const handleSubmitNewPoint = (e) => {
    setLoading(true);

    let auxPointDetailOffcanvas = pointDetailOffcanvas;
    setPointDetailOffcanvas([]);
    e.preventDefault();

    // Constrói a requisição no padrão da API
    let objectToSend = {};
    Object.entries(e.target).map((element) => {
      if (
        (element[1].value && element[1].name !== "mission") ||
        (element[1].name === "id" && element[1].value > 0)
      ) {
        objectToSend[element[1].name] = element[1].value;
      }
      return null;
    });

    // Define o tipo de requisição com base na criação ou edição
    let methodFetch = "POST";
    let link = apiUrl + "/publiclightingpoints";
    if (objectToSend.id) {
      methodFetch = "PUT";
      link = apiUrl + "/publiclightingpoints/" + objectToSend.id;
    }

    // Realiza a requisição
    fetch(link, {
      method: methodFetch,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(objectToSend),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        if (!objectToSend.id)
          setModalNotificacao({
            title: "Ponto adicionado.",
            content: "O ponto foi adicionado com sucesso.",
            okButton: true,
          });
        else
          setModalNotificacao({
            title: "Ponto alterado.",
            content: "O ponto foi alterado com sucesso.",
            okButton: true,
          });
        setPointDetailOffcanvas(null);
        setNewPointPreviewMap([]);
        setModalNewPublicLightPoint(false);

        setCount(Math.random());
        setTimeout(() => {
          setPointDetailOffcanvas(auxPointDetailOffcanvas);
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        alert("Não foi possível realizar a ação.");
        setLoading(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setOffCanvas(false);
        setLoading(false);
      });
  };

  // Gera o formulário para edição/criação de pontos
  const generateEditPointForm = () => {
    return (
      <Form onSubmit={(e) => handleSubmitNewPoint(e)}>
        Coordenadas:
        <Row className="m-2">
          <Label for={"newPointLatitude"} sm={4}>
            Latitude:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointLatitude"}
              name={"latitude"}
              placeholder={"Latitude"}
              type="text"
              required
              onChange={(event) => {
                if (isNumber(event.target.value)) {
                  setNewPointPreviewMap([
                    [
                      event.target.value.replaceAll(",", "."),
                      newPointPreviewMap[0] && newPointPreviewMap[0][1]
                        ? newPointPreviewMap[0][1]
                        : modalNewPublicLightPoint.longitude,
                    ],
                  ]);
                } else {
                  showPublicLoghtPointMessage("Valor inválido.");
                }
              }}
              value={
                newPointPreviewMap.length > 0
                  ? newPointPreviewMap[0][0]
                  : modalNewPublicLightPoint.latitude
                  ? modalNewPublicLightPoint.latitude
                  : ""
              }
            />
          </Col>
        </Row>
        <Row className="m-2">
          <Label for={"newPointLongitude"} sm={4}>
            Longitude:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointLongitude"}
              name={"longitude"}
              placeholder={"Longitude"}
              type="text"
              required
              onChange={(event) => {
                if (isNumber(event.target.value)) {
                  setNewPointPreviewMap([
                    [
                      newPointPreviewMap[0] && newPointPreviewMap[0][0]
                        ? newPointPreviewMap[0][0]
                        : modalNewPublicLightPoint.latitude,
                      event.target.value.replaceAll(",", "."),
                    ],
                  ]);
                } else {
                  showPublicLoghtPointMessage("Valor inválido.");
                }
              }}
              value={
                newPointPreviewMap.length > 0
                  ? newPointPreviewMap[0][1]
                  : modalNewPublicLightPoint.longitude
                  ? modalNewPublicLightPoint.longitude
                  : ""
              }
            />
          </Col>
        </Row>
        <div className="text-end" style={{ fontSize: ".7em" }}>
          Clique no mapa para indicar a localização.
        </div>
        <Row>
          <Label for={"newPointAltitude"} sm={4}>
            Altitude:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointAltitude"}
              name={"altitude"}
              placeholder={"Altitude"}
              type="number"
              step="0.5"
              required
              defaultValue={
                modalNewPublicLightPoint.altitude &&
                isNumber(modalNewPublicLightPoint.altitude)
                  ? Math.floor(modalNewPublicLightPoint.altitude)
                  : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Label for={"newPointLongitude"} sm={4}>
            Potência da lâmpada:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointPotencia"}
              name={"lamp_power"}
              placeholder={"Potência"}
              type="number"
              required
              defaultValue={
                modalNewPublicLightPoint.lamp_power
                  ? modalNewPublicLightPoint.lamp_power
                  : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Label for={"newPointTipo"} sm={4}>
            Tipo da lâmpada:
          </Label>
          <Col sm={8} className="m-auto">
            <select
              className="form-control"
              id={"newPointTipo"}
              name={"lamp_type"}
              placeholder={"Tipo"}
              defaultValue={modalNewPublicLightPoint.lamp_type}
            >
              {lampTypes
                ? Object.entries(lampTypes).map((type) => {
                    return (
                      <option key={Math.random()} value={type[0]}>
                        {type[1]}
                      </option>
                    );
                  })
                : ""}
            </select>
          </Col>
        </Row>
        <Row style={{ display: "none" }}>
          <Label for={"newPointProjeto"} sm={4}>
            Projeto:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointProjeto"}
              name={"project_id"}
              placeholder={"Projeto"}
              type="hidden"
              defaultValue={projectDetails.id}
            />
          </Col>
        </Row>
        <Row style={{ display: "none" }}>
          <Label for={"newPointId"} sm={4}>
            Point Id:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointId"}
              name={"id"}
              placeholder={"Point ID"}
              type="hidden"
              value={
                modalNewPublicLightPoint.id ? modalNewPublicLightPoint.id : ""
              }
            />
          </Col>
        </Row>
        {newPublicLightPointMessage ? (
          <Row className="text-danger mt-2">
            <p className="text-center">{newPublicLightPointMessage}</p>
          </Row>
        ) : (
          <Row>
            {CheckPermission(
              userPermission && userPermission.role ? userPermission.role : "",
              "EMPLOYEE"
            ) && modalNewPublicLightPoint.id ? (
              <Col>
                <Button
                  outline
                  className="col-12 mt-3 btn-outline-danger"
                  onClick={() => {
                    setModalNewPublicLightPoint(false);
                    setModalNotificacao({
                      title: "Excluir ponto.",
                      content: "Deseja exluir o ponto?",
                      cancelButton: () => {
                        setModalNotificacao(false);
                        setModalNewPublicLightPoint(modalNewPublicLightPoint);
                      },
                      confirmButton: () =>
                        deletePoint(modalNewPublicLightPoint.id),
                    });
                  }}
                >
                  <BsTrash className="me-2" />
                  Apagar Ponto
                </Button>
              </Col>
            ) : (
              ""
            )}
            <Col>
              <Button className="col-12 mt-3">
                <BsSdCard className="me-2" />
                {!modalNewPublicLightPoint.id ? "Adicionar" : "Salvar"}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    );
  };
  const deletePoint = (id) => {
    setLoading(true);
    fetch(apiUrl + "/publiclightingpoints/" + id, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        //alert("Ponto removido!");
        setModalNotificacao({
          title: "Ponto removido",
          content: "O ponto foi removido com sucesso.",
          okButton: true,
        });

        setOffCanvas(false);
        setPointDetailOffcanvas(null);
        setNewPointPreviewMap([]);
        setModalNewPublicLightPoint(false);
        setCount(Math.random());
      })
      .catch((error) => {
        alert("Erro ao apagar o ponto: " + error);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // end controls offcanvas editar ponto

  const getKmzMission = (id) => {
    //console.log(missionsOfProject[id]);
    //console.log(missionsOfProject.length);
    if (!loadedData)
      if (missionDetails) {
        let link = apiUrl + "/missions/" + missionDetails.id + "/kmz";
        fetch(link, {
          method: "GET",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + userPermission.access_token,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error(response.status);
            }
          })
          .then((mlista) => {
            try {
              mlista = JSON.parse(mlista);
            } catch (error) {}
            //setMissionsOfProject(mlista.content);
            console.log(mlista);

            let auxList = kmzProject;
            let exists = false;
            auxList.forEach((eid) => {
              if (eid.id === mlista.id) {
                exists = true;
              }
            });
            if (!exists) {
              let aux = {
                id: missionDetails.id,
                name: missionDetails.name,
                option: "KMZ",
                path: mlista,
                project_id: missionDetails.project_id,
              };
              auxList.push(aux);
            }
            setKmzProject(auxList);
            setKmzActive(true);
            setActiveKmzSelected(auxList[0]);
          })
          .finally(() => {
            setLoadedData(true);
          })
          .catch((error) => {
            console.log(error);
            let message = "Houve um erro ao tentar buscar o KMZ no servidor.";
            if (error.toString().indexOf("404") !== -1)
              message = "Não foi encontrado um KMZ para esta missão.";
            showMessageDiv(
              {
                title: "Erro ao obter KMZ",
                message: message,
                buttonText1: "Ok",
                buttonAction1: () => {
                  setMessageDiv(null);
                },
              },
              4000
            );
          });
      } else {
      }
  };
  useEffect(() => {
    setTimeout(() => {
      updateOrthoPhotoMapFunction(kmlList);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmlList, loadingKmz, zoomLevelInfo, centerScreenMap]);

  const getKmzDataP1 = () => {
    setLoadingKmz(true);
    if (activeKmzSelected && activeKmzSelected.path) {
      setProgressBar(0);
      axios({
        url: activeKmzSelected.path,
        cancelToken: axiosCancelToken.token,
        responseType: "blob",
        onDownloadProgress(progress) {
          setProgressBar(progress.progress);
        },
      })
        .then((res) => new File([res.data], "KMZ"))
        .then(async (res) => {
          let file = {};
          file["file"] = res;
          file["data"] = activeKmzSelected;
          getKmzDataP2(file);
        })
        .finally(() => {})
        .catch((error) => {
          if (axios.isCancel(error)) {
            setAxiosCancelToken(axios.CancelToken.source());
            setModalNotificacao({
              title: "Cancelado.",
              content: "Cancelado pelo usuário.",
              okButton: true,
            });
          } else {
            setModalNotificacao({
              title: "Erro ao carregar KMZ.",
              content: "Houve um erro ao carregar o arquivo KMZ. " + error,
              okButton: true,
            });
          }
        });
    } else {
      setLoadingKmz(false);
    }
  };

  const getKmzDataP2 = (kmlZipListFiles) => {
    let res = kmlZipListFiles;
    if (res) {
      JSZip.loadAsync(res.file)
        .then(function (zip) {
          let objectsZip = Object.entries(zip.files);
          if (!res["contentZip"]) res["contentZip"] = {};
          res["contentZip"]["zip"] = zip;
          objectsZip.map((file) => {
            if (
              getExtension(file[0].toString()) === "kml" &&
              file[0].toString() !== "doc.kml"
            ) {
              if (!res["contentZip"]["contentKmls"])
                res["contentZip"]["contentKmls"] = [];
              res["contentZip"]["contentKmls"].push(file);
            }
            return true;
          });
        })
        .catch(function (err) {
          // setLoading(false);
          setLoadingKmz(false);
          console.error(err);
          if (err.name === "AbortError") {
            // handle abort()
            console.log("Aborted");
            setLoading(false);
          }
        })
        .finally(() => {
          getKmzDataP3(kmlZipListFiles, 0, {});
        });
    } else {
      setLoadingKmz(false);
    }
  };

  const getKmzDataP3 = (kmlZipListFiles, kmlPos, auxKmlList) => {
    let file = kmlZipListFiles["contentZip"]["contentKmls"][kmlPos];
    let zip = kmlZipListFiles["contentZip"]["zip"];
    let kmzItemActive = kmlZipListFiles["data"];

    let au = file[0].split("/");
    file[1]
      .async("text")
      .then(async (kmlFileContent) => {
        let converted = kmlFileContent.replaceAll("ns2:", "");

        let imgPathFile = file[0].replaceAll(".kml", ".jpg");
        if (!zip.files[imgPathFile]) {
          imgPathFile = file[0].replaceAll(".kml", ".png");
        }
        if (zip.files[imgPathFile]) {
          await zip.files[imgPathFile]
            .async("base64")
            .then((imgFileContent) => {
              let position = au[0] > 0 ? au[0] : 0;
              if (!auxKmlList[kmzItemActive.id])
                auxKmlList[kmzItemActive.id] = [];
              if (!auxKmlList[kmzItemActive.id][position])
                auxKmlList[kmzItemActive.id][position] = [];
              var src = "data:image/jpeg;base64," + imgFileContent;
              auxKmlList[kmzItemActive.id][position].push([
                file[0],
                converted,
                src,
              ]);
              // setKmlList(auxKmlList);
            })
            .catch(() => {});
        } else {
          let position = au[0] > 0 ? au[0] : 0;
          if (!auxKmlList[kmzItemActive.id]) auxKmlList[kmzItemActive.id] = [];
          if (!auxKmlList[kmzItemActive.id][position])
            auxKmlList[kmzItemActive.id][position] = [];
          var src = "data:image/jpeg;base64,";
          console.log(position, kmzItemActive.id);
          auxKmlList[kmzItemActive.id][position].push([
            file[0],
            converted,
            src,
          ]);

          // setKmlList(auxKmlList);
        }
      })
      .catch(() => {
        setLoadingKmz(false);
      })
      .finally(() => {
        if (kmlPos < kmlZipListFiles["contentZip"]["contentKmls"].length - 1) {
          getKmzDataP3(kmlZipListFiles, ++kmlPos, auxKmlList);
        } else {
          updateOrthoPhotoMapFunction(auxKmlList);
          setTimeout(() => {
            setProgressBar(0);
            setKmlList(auxKmlList);
            console.log(auxKmlList);
            setLoadingKmz(false);
          }, 100);
        }
      });
  };

  const updateOrthoPhotoMapFunction = (insideKmlList) => {
    let useKmlList = insideKmlList;
    if (!useKmlList) {
      useKmlList = kmlList;
    }

    Object.entries(useKmlList).forEach((kmls) => {
      useKmlList = kmls[1];

      setKmlListPreview(null);

      if (true || !loadingKmz) {
        if (map) {
          let auxKmlListPreview = [];
          let auxKmlListPoint = [];
          let activeLayer = 0;
          let diffLoadImages = 1;
          if (zoomLevelInfo > 20) {
            activeLayer =
              useKmlList.length - 1 >= 0 ? useKmlList.length - 1 : 0; //5
            diffLoadImages = 0.00008;
          } else if (zoomLevelInfo > 19) {
            let objectiveLayer =
              useKmlList.length - 2 >= 0 ? useKmlList.length - 2 : 0; //4
            diffLoadImages = 0.00025;
            if (useKmlList[objectiveLayer]) activeLayer = objectiveLayer;
          } else if (zoomLevelInfo > 18) {
            let objectiveLayer =
              useKmlList.length - 3 >= 0 ? useKmlList.length - 3 : 0; //3
            diffLoadImages = 0.0004;
            if (useKmlList[objectiveLayer]) activeLayer = objectiveLayer;
          } else if (zoomLevelInfo > 17) {
            let objectiveLayer =
              useKmlList.length - 4 >= 0 ? useKmlList.length - 4 : 0; //2
            diffLoadImages = 0.0008;
            if (useKmlList[objectiveLayer]) activeLayer = objectiveLayer;
          } else if (zoomLevelInfo > 16) {
            let objectiveLayer =
              useKmlList.length - 5 >= 0 ? useKmlList.length - 5 : 0; //1
            diffLoadImages = 0.01;
            if (useKmlList[objectiveLayer]) activeLayer = objectiveLayer;
          } else activeLayer = 0;
          diffLoadImages = diffLoadImages * zoomLevelInfo;
          console.log(activeLayer);
          setKmlListPreview();
          setTimeout(() => {
            let i = useKmlList[activeLayer];
            if (i) {
              auxKmlListPreview = [];
              auxKmlListPoint = [];
              var layers = Leaflet.layerGroup();
              for (let k = 0; k < i.length; k++) {
                let point = parser
                  .parseFromString(i[k][1], "text/xml")
                  .getElementsByTagName("Region")[0];
                let pointer = parser
                  .parseFromString(i[k][1], "text/xml")
                  .getElementsByTagName("GroundOverlay")[0]
                  .getElementsByTagName("coordinates")[0];
                pointer = pointer.textContent.split(",0");
                let centerScreenCoords = centerScreenMap;
                if (
                  point &&
                  pointer[3].split(",")[1] >
                    centerScreenCoords.lat - diffLoadImages / 2 &&
                  pointer[3].split(",")[1] <
                    centerScreenCoords.lat + diffLoadImages / 2 &&
                  pointer[3].split(",")[0] >
                    centerScreenCoords.lng - diffLoadImages / 2 &&
                  pointer[3].split(",")[0] <
                    centerScreenCoords.lng + diffLoadImages / 2
                ) {
                  //cont++;
                  let imageToMap = Leaflet.imageOverlay.rotated(
                    i[k][2],
                    Leaflet.latLng(
                      parseFloat(pointer[3].split(",")[1]),
                      parseFloat(pointer[3].split(",")[0])
                    ),
                    Leaflet.latLng(
                      parseFloat(pointer[2].split(",")[1]),
                      parseFloat(pointer[2].split(",")[0]) + 0.0000035
                    ),
                    Leaflet.latLng(
                      parseFloat(pointer[0].split(",")[1]) - 0.0000035,
                      parseFloat(pointer[0].split(",")[0])
                    ),
                    {
                      opacity: 1,
                      interactive: false,
                    }
                  );

                  imageToMap.addTo(layers);
                }
              }
              auxKmlListPreview.push(
                <LayersControl.Overlay
                  checked
                  name={projectDetails.name + " (" + missionDetails.name + ")"}
                >
                  <LayerGroup>{auxKmlListPoint}</LayerGroup>
                </LayersControl.Overlay>
              );
              setLoading(false);
            }
            let idLayer = "layer" + Math.random();
            if (layers) {
              if (!layers.options) {
                layers.options = {};
              }
              if (!layers.options.id) {
                layers.options.id = idLayer;
              }
            }

            if (map && layers) map.addLayer(layers);
            setTimeout(() => {
              map.eachLayer(function (layer) {
                if (
                  layer &&
                  layer.options &&
                  layer.options.id &&
                  layer.options.id.indexOf("layer") !== -1 &&
                  layer.options.id !== idLayer
                ) {
                  map.removeLayer(layer);
                }
              });
            }, 20);
            setLoading(false);
            setTransformLayer(-1);
            setKmlListPreview(
              <LayersControl position="bottomright">
                {auxKmlListPreview}
              </LayersControl>
            );
          }, 10);
        }
      }
    });
  };

  useEffect(() => {
    getKmzDataP1([], 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmzActive, activeKmzSelected]);

  // Functions KMZ

  const showMessageDiv = (
    { title, message, buttonText1, buttonAction1, buttonText2, buttonAction2 },
    retain
  ) => {
    setMessageDiv({
      title: title,
      message: message,
      buttonText1: buttonText1,
      buttonAction1: buttonAction1,
      buttonText2: buttonText2,
      buttonAction2: buttonAction2,
    });
    if (!retain)
      setTimeout(() => {
        setMessageDiv(null);
      }, 1000);
    else if (retain > 1)
      setTimeout(() => {
        setMessageDiv(null);
      }, retain);
  };

  useEffect(() => {
    setPointDetailOffcanvas(null);
    setNewPointPreviewMap([]);
    setModalNewPublicLightPoint(false);
    if (userPermission) {
      getMissionData();
      setPointDetailOffcanvas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const getMissionData = () => {
    setBaseMapSource(baseMaps.Street);
    setLoading(true);
    getLampTypes();
    fetch(apiUrl + "/missions/" + missionId, {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            "Erro " + response.status + " ao tentar obter os dados da missão."
          );
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setMissionDetails(data);
        if (data.status !== "Processamento finalizado") {
          setModalNotificacao({
            title: "Editar marcações",
            content: "Esta missão ainda não foi processada.",
            okButton: true,
          });
          setTimeout(() => {
            navigate("/missoes/" + data.project_id);
          }, 2000);
        }
        getProjectData(data.project_id);
      })
      .finally(() => {
        setOffCanvas(false);
        setModalNewPublicLightPoint(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjectData = (id) => {
    fetch(apiUrl + "/missions/" + missionId + "/project?size=10000", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            "Erro " +
              response.status +
              " ao tentar recuperar os dados do projeto."
          );
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setProjectDetails(data);
        // cidadesEstadosCoords.forEach((el) => {
        //   if (el.nome === data.city) {
        //     setCenterMapTag([el.latitude, el.longitude]);
        //     setCenterMapTagUpdate([el.latitude, el.longitude]);
        //   }
        // });
        setTimeout(() => {
          getProjectPoints(data);
        }, 100);
      });
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const getProjectPoints = (projectDataReturn) => {
    fetch(
      apiUrl + "/missions/" + missionId + "/public-lighting-points?size=10000",
      {
        //fetch(apiUrl + "/projects/" + id + "/publiclightingpoints", {
        method: "GET",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            "Erro " +
              response.status +
              " ao tentar obter as marcações do projeto."
          );
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        // setProjectPublicLightingPoints(data);

        setOriginMarkers(null);
        setTimeout(() => {
          setOriginMarkers(data.content);
          if (data.content.length > 0) {
            if (!centerMapTag)
              setCenterMapTag([
                data.content[0].latitude,
                data.content[0].longitude,
              ]);
            // setCenterMapTagUpdate([
            //   data.content[0].latitude,
            //   data.content[0].longitude,
            // ]);
          } else {
            cidadesEstadosCoords.forEach((el) => {
              if (el.nome === projectDataReturn.city) {
                console.log(projectDataReturn.city);
                setCenterMapTag([el.latitude, el.longitude]);
                // setCenterMapTagUpdate([el.latitude, el.longitude]);
              }
            });
          }
        }, 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const CenterMap = () => {
    // const map = useMap();
    if (!oneTimeCenter && centerMapTag) {
      map.setView(centerMapTag, 15);
      setTimeout(() => {
        setOneTimeCenter(true);
        setCenterMapTag(false);
      }, 1);
    }
  };

  useEffect(() => {
    if (userPermission && !missionDetails) getMissionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  useEffect(() => {
    // if (map) {
    //   // console.log(document.getElementById("map"));
    if (
      processMovingPLPoint ||
      processMovingAllMarker ||
      processMovingRectangle
    ) {
      props.toast({
        title: "Movendo marcação",
        message:
          "Movimente o mouse para definir a nova localização da marcação.",
        timer: 2500,
      });
      // document.getElementById("map").style.cursor = "crosshair";
    } else {
      // document.getElementById("map").style.cursor = "pointer";
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processMovingPLPoint, processMovingAllMarker, processMovingRectangle]);

  useEffect(() => {
    getKmzMission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionDetails]);

  const enviarDemarcacoes = () => {
    let sendData = {
      manual_annotations: [],
    };

    drawningLayerTemp.forEach((el) => {
      let bounding_box_gps_ = [el[1][0], el[1][1], el[1][2], el[1][3]];
      let aux = {
        light_point_gps: el[0],
        bounding_box_gps: bounding_box_gps_,
      };
      sendData.manual_annotations.push(aux);
    });
    setLoading(true);

    fetch(apiUrl + "/missions/" + missionId + "/add-manual-annotations", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },

      body: JSON.stringify(sendData),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(
            "Erro " + response.status + " ao tentar salvar as marcações."
          );
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setModalNotificacao({
          title: "Editar marcação",
          content: "Enviado para processamento.",
          okButtonRedirect: true,
        });
      })
      .catch((error) => {
        setModalNotificacao({
          title: "Editar marcação",
          content: "Algo de errado ocorreu. Não foi possível enviar os dados.",
          okButtonRedirect: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removerPonto = (layer, point) => {
    drawningPolygonList.forEach((polygon) => {
      polygon.transform.disable();
    });
    setTransformLayer(-1);
    let auxDrawningLayerTemp = drawningLayerTemp;
    setPointsNewPolygonMap(false);
    if (point) {
      if (point === -1) {
        let auxList = [];
        if (auxDrawningLayerTemp.length > 1) {
          for (let i = 0; i < auxDrawningLayerTemp.length; i++) {
            if (i !== layer) {
              auxList.push(auxDrawningLayerTemp[i]);
            }
          }
        }
        auxDrawningLayerTemp = auxList;
      } else {
        auxDrawningLayerTemp[layer][1] = auxDrawningLayerTemp[layer][1].filter(
          (el) => {
            if (point !== el) return true;
            else return false;
          }
        );
      }
      if (!auxDrawningLayerTemp || auxDrawningLayerTemp.length === 0) {
        auxDrawningLayerTemp.push([]);
      }
    }
    if (!auxDrawningLayerTemp || auxDrawningLayerTemp.length === 0) {
      auxDrawningLayerTemp = [[]];
    }

    setTimeout(() => {
      if (pointsNewPolygonMapSelectedLayer > 0)
        setPointsNewPolygonMapSelectedLayer(
          pointsNewPolygonMapSelectedLayer - 1
        );
      else setPointsNewPolygonMapSelectedLayer(0);
      setDrawningLayerTemp(auxDrawningLayerTemp);
    }, 1);
  };

  const moverMarcacao = (cont) => {
    setTransformLayer(-1);
    setPointsNewPolygonMapExpressMode(false);
    setProcessMovingRectangleOrigin(drawningLayerTemp[cont]);
    setTimeout(() => {
      setProcessMovingRectangle(cont);
    }, 1);
  };
  const moverTodaMarcacao = (cont) => {
    setTransformLayer(-1);
    setPointsNewPolygonMapExpressMode(false);
    setProcessMovingRectangleOrigin(drawningLayerTemp[cont]);
    setTimeout(() => {
      setProcessMovingAllMarker(cont);
    }, 1);
  };

  const moverPlPoint = (cont) => {
    setTransformLayer(-1);
    setPointsNewPolygonMapExpressMode(false);
    setTimeout(() => {
      setProcessMovingPLPoint(cont);
    }, 1);
  };

  const copiarMarcacao = (layer) => {
    setTransformLayer(-1);
    let auxDrawningLayerTemp = drawningLayerTemp;
    setDrawningLayerTemp(false);
    try {
      auxDrawningLayerTemp.push([
        [...drawningLayerTemp[layer][0]],
        [
          [...drawningLayerTemp[layer][1][0]],
          [...drawningLayerTemp[layer][1][1]],
          [...drawningLayerTemp[layer][1][2]],
          [...drawningLayerTemp[layer][1][3]],
        ],
      ]);
    } catch (error) {}

    setTimeout(() => {
      setPointsNewPolygonMapSelectedLayer(auxDrawningLayerTemp.length - 1);
      setDrawningLayerTemp(auxDrawningLayerTemp);
    }, 0.1);
  };

  const MapEvents = (props) => {
    useMapEvents({
      click(e) {
        if (
          drawningLayerTemp[pointsNewPolygonMapSelectedLayer] ||
          pointsNewPolygonMapExpressMode
        ) {
          if (!modalNewPublicLightPoint) {
            if (!offCanvas) {
              let auxDrawningLayerTemp = drawningLayerTemp;
              if (
                processMovingRectangle > -1 ||
                processMovingAllMarker > -1 ||
                processMovingPLPoint > -1
              ) {
                setTimeout(() => {
                  setProcessMovingRectangle(-1);
                  setProcessMovingAllMarker(-1);
                }, 1);
                if (processMovingPLPoint > -1) {
                  let auxPointsNewPolygonMap = drawningLayerTemp;
                  auxPointsNewPolygonMap[processMovingPLPoint][0] = [
                    e.latlng.lat,
                    e.latlng.lng,
                  ];
                  setDrawningLayerTemp(false);
                  setProcessMovingPLPoint(-1);
                  setTimeout(() => {
                    setDrawningLayerTemp(auxPointsNewPolygonMap);
                  }, 0.01);
                }
              } else {
                if (
                  !auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer] ||
                  !auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][0] ||
                  !auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][0][0]
                ) {
                  auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer] = [];
                  setDrawning(true);
                  setTransformLayer(-1);
                }
                if (
                  !auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][0]
                ) {
                  auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][0] = [
                    e.latlng.lat,
                    e.latlng.lng,
                  ];
                } else {
                  if (
                    !auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][1]
                  ) {
                    auxDrawningLayerTemp[pointsNewPolygonMapSelectedLayer][1] =
                      [];
                    auxDrawningLayerTemp[
                      pointsNewPolygonMapSelectedLayer
                    ][1][0] = [e.latlng.lat, e.latlng.lng];
                  } else {
                    if (drawning) {
                      if (
                        !auxDrawningLayerTemp[
                          pointsNewPolygonMapSelectedLayer
                        ][1][0]
                      ) {
                        auxDrawningLayerTemp[
                          pointsNewPolygonMapSelectedLayer
                        ][1][0] = [
                          e.latlng.lat,
                          auxDrawningLayerTemp[
                            pointsNewPolygonMapSelectedLayer
                          ][1][0][1],
                        ];
                      } else {
                        auxDrawningLayerTemp[
                          pointsNewPolygonMapSelectedLayer
                        ][1][1] = [
                          e.latlng.lat + (30 - zoomLevelInfo) / 1200000,
                          auxDrawningLayerTemp[
                            pointsNewPolygonMapSelectedLayer
                          ][1][0][1],
                        ];
                        auxDrawningLayerTemp[
                          pointsNewPolygonMapSelectedLayer
                        ][1][2] = [
                          e.latlng.lat + (30 - zoomLevelInfo) / 1200000,
                          e.latlng.lng - (30 - zoomLevelInfo) / 1200000,
                        ];
                        auxDrawningLayerTemp[
                          pointsNewPolygonMapSelectedLayer
                        ][1][3] = [
                          auxDrawningLayerTemp[
                            pointsNewPolygonMapSelectedLayer
                          ][1][0][0],
                          e.latlng.lng - (30 - zoomLevelInfo) / 1200000,
                        ];
                        setTimeout(() => {
                          setDrawning(false);
                        }, 10);
                      }
                    }
                    if (pointsNewPolygonMapExpressMode) {
                      let auxDrawningLayerTemp = drawningLayerTemp;
                      if (!auxDrawningLayerTemp[drawningLayerTemp.length]) {
                        auxDrawningLayerTemp.push([[], []]);
                        setDrawningLayerTemp(auxDrawningLayerTemp);
                      }
                      setPointsNewPolygonMapSelectedLayer(
                        drawningLayerTemp.length - 1
                      );
                    }
                  }
                }
              }
              setDrawningLayerTemp(false);
              setTimeout(() => {
                setDrawningLayerTemp(auxDrawningLayerTemp);
              }, 0.000001);
            }
          } else {
            if (newPointPreviewMap.length > 0) setNewPointPreviewMap([]);
            if (modalNewPublicLightPoint) {
              setNewPointPreviewMap([
                [e.latlng.lat.toString(), e.latlng.lng.toString()],
              ]);
            }
          }
        }
      },
      mousemove(e) {
        if (!modalNewPublicLightPoint && !offCanvas) {
          if (drawning) {
            if (
              drawningLayerTemp &&
              drawningLayerTemp[pointsNewPolygonMapSelectedLayer] &&
              drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1]
            ) {
              let auxDrawningLayerTemp = drawningLayerTemp;
              drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][1] = [
                e.latlng.lat + (30 - zoomLevelInfo) / 1200000,
                drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][0][1],
              ];
              drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][2] = [
                e.latlng.lat + (30 - zoomLevelInfo) / 1200000,
                e.latlng.lng - (30 - zoomLevelInfo) / 1200000,
              ];
              drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][3] = [
                drawningLayerTemp[pointsNewPolygonMapSelectedLayer][1][0][0],
                e.latlng.lng - (30 - zoomLevelInfo) / 1200000,
              ];
              setDrawningLayerTemp(false);
              setTimeout(() => {
                setDrawningLayerTemp(auxDrawningLayerTemp);
              }, 1);
            }
          } else if (
            processMovingRectangle > -1 ||
            processMovingAllMarker > -1
          ) {
            setTransformLayer(-1);
            //setProcessMovingRectangle(0);
            let auxSelectedLayer = processMovingRectangle;

            if (processMovingAllMarker !== -1)
              auxSelectedLayer = processMovingAllMarker;
            try {
              let diff = 0.0000005;
              let auxPointsNewPolygonMap = drawningLayerTemp;
              if (auxPointsNewPolygonMap) {
                setDrawningLayerTemp(false);
                let diffPoint1 = [
                  processMovingRectangleOrigin[1][1][0] -
                    processMovingRectangleOrigin[1][0][0],
                  processMovingRectangleOrigin[1][1][1] -
                    processMovingRectangleOrigin[1][0][1],
                ];
                let diffPoint2 = [
                  processMovingRectangleOrigin[1][2][0] -
                    processMovingRectangleOrigin[1][0][0],
                  processMovingRectangleOrigin[1][2][1] -
                    processMovingRectangleOrigin[1][0][1],
                ];
                let diffPoint3 = [
                  processMovingRectangleOrigin[1][3][0] -
                    processMovingRectangleOrigin[1][0][0],
                  processMovingRectangleOrigin[1][3][1] -
                    processMovingRectangleOrigin[1][0][1],
                ];

                let middlePoint1lat =
                  (processMovingRectangleOrigin[1][0][0] -
                    processMovingRectangleOrigin[1][2][0]) /
                  2;
                let middlePoint1lng =
                  (processMovingRectangleOrigin[1][0][1] -
                    processMovingRectangleOrigin[1][2][1]) /
                  2;

                let diffPointPl = [
                  processMovingRectangleOrigin[0][0] -
                    processMovingRectangleOrigin[1][0][0],
                  processMovingRectangleOrigin[0][1] -
                    processMovingRectangleOrigin[1][0][1],
                ];
                if (processMovingAllMarker > -1) {
                  auxPointsNewPolygonMap[auxSelectedLayer][0][0] =
                    e.latlng.lat + diff + diffPointPl[0] + middlePoint1lat;
                  auxPointsNewPolygonMap[auxSelectedLayer][0][1] =
                    e.latlng.lng + diff + diffPointPl[1] + middlePoint1lng;
                }
                auxPointsNewPolygonMap[auxSelectedLayer][1][0][0] =
                  e.latlng.lat + diff + middlePoint1lat;
                auxPointsNewPolygonMap[auxSelectedLayer][1][0][1] =
                  e.latlng.lng + diff + middlePoint1lng;
                auxPointsNewPolygonMap[auxSelectedLayer][1][1][0] =
                  e.latlng.lat + diff + diffPoint1[0] + middlePoint1lat;
                auxPointsNewPolygonMap[auxSelectedLayer][1][1][1] =
                  e.latlng.lng + diff + diffPoint1[1] + middlePoint1lng;
                auxPointsNewPolygonMap[auxSelectedLayer][1][2][0] =
                  e.latlng.lat + diff + diffPoint2[0] + middlePoint1lat;
                auxPointsNewPolygonMap[auxSelectedLayer][1][2][1] =
                  e.latlng.lng + diff + diffPoint2[1] + middlePoint1lng;
                auxPointsNewPolygonMap[auxSelectedLayer][1][3][0] =
                  e.latlng.lat + diff + diffPoint3[0] + middlePoint1lat;
                auxPointsNewPolygonMap[auxSelectedLayer][1][3][1] =
                  e.latlng.lng + diff + diffPoint3[1] + middlePoint1lng;
                setTimeout(() => {
                  setDrawningLayerTemp(auxPointsNewPolygonMap);
                }, 0.000001);
              }
            } catch (error) {}
          } else if (processMovingPLPoint > -1) {
            try {
              let auxPointsNewPolygonMap = drawningLayerTemp;
              auxPointsNewPolygonMap[processMovingPLPoint][0] = [
                e.latlng.lat,
                e.latlng.lng,
              ];
              setDrawningLayerTemp(false);
              setTimeout(() => {
                setDrawningLayerTemp(auxPointsNewPolygonMap);
              }, 0.000001);
            } catch (error) {}
          }
        }
      },
    });
    return false;
  };

  const loadingIcon = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          //display: "flex",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  const getPointsMarkers = () => {
    let pointMarkers = [];

    originMarkers.map((point) => {
      pointMarkers.push(
        <CircleMarker
          key={Math.random()}
          center={[point.latitude, point.longitude]}
          fillColor={defaultPointColor}
          radius={defaultRadiusMarker}
          fillOpacity={0.6}
          color={defaultPointColor}
          eventHandlers={{
            click: (record) => {
              if (
                processMovingPLPoint === -1 &&
                !processMarkingRectangle &&
                processMovingRectangle === -1
              ) {
                setFocusPoint(point);
                return openPointDetails(point);
              } else {
                return false;
              }
            },
          }}
        ></CircleMarker>
      );
      return null;
    });

    return pointMarkers;
  };

  return (
    <>
      {loadingIcon()}
      <Header
        title={projectDetails && projectDetails.name ? projectDetails.name : ""}
        url={
          "detalhesprojeto/" +
          (projectDetails && projectDetails.name ? projectDetails.id : "")
        }
      />

      {/* Início modal de notificação */}
      <Modal // Modal de notificação
        style={{ zIndex: "2050" }}
        isOpen={modalNotificacao ? true : false}
      >
        <ModalHeader>
          {modalNotificacao && modalNotificacao.title
            ? modalNotificacao.title
            : ""}
        </ModalHeader>
        <ModalBody>
          <div className="row m-1">
            {modalNotificacao && modalNotificacao.content
              ? modalNotificacao.content
              : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          {modalNotificacao && modalNotificacao.cancelButton ? (
            <Button color="secondary" onClick={modalNotificacao.cancelButton}>
              Cancelar
            </Button>
          ) : (
            ""
          )}

          {modalNotificacao && modalNotificacao.okButton ? (
            <Link
              color="secondary"
              className="btn btn-secondary"
              onClick={() => setModalNotificacao(false)}
              //to={"/missoes/" + projectDetails.id}
            >
              Ok
            </Link>
          ) : (
            ""
          )}

          {modalNotificacao && modalNotificacao.okButtonRedirect ? (
            <Link
              color="secondary"
              className="btn btn-secondary"
              //onClick={() => setModalNotificacao(false)}
              to={"/missoes/" + projectDetails.id}
            >
              Ok
            </Link>
          ) : (
            ""
          )}
          {/* {modalNotificacao && modalNotificacao.actionButton ? (
            <Button color="secondary" onClick={modalNotificacao.actionButton}>
              Ok
            </Button>
          ) : (
            ""
          )} */}
          {modalNotificacao && modalNotificacao.confirmButton ? (
            <Button color="success" onClick={modalNotificacao.confirmButton}>
              Confirmar
            </Button>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
      {/* Fim modal de notificação */}

      {/* Início modal de confirmação de processamento  */}
      <Modal
        style={{ zIndex: "2050" }}
        isOpen={modalProcessamento ? true : false}
      >
        <ModalHeader>Processar marcações</ModalHeader>
        <ModalBody className="p-1">
          <div className="row m-1">
            {drawningLayerTemp &&
            drawningLayerTemp.length > 0 &&
            drawningLayerTemp.filter(
              (layer) =>
                !layer[0] ||
                !layer[0][0] ||
                !layer[0][1] ||
                !layer[1] ||
                !layer[1][0] ||
                !layer[1][0][0] ||
                !layer[1][0][1] ||
                !layer[1][1] ||
                !layer[1][1][0] ||
                !layer[1][1][1] ||
                !layer[1][2] ||
                !layer[1][2][0] ||
                !layer[1][2][1] ||
                !layer[1][3] ||
                !layer[1][3][0] ||
                !layer[1][3][1]
            ).length > 0 ? (
              <div>
                Existem camadas que não foram desenhadas corretamente, por favor
                verifique as camadas marcadas com ' <b>*</b> ' na lista de
                camadas.
              </div>
            ) : (
              <>
                <div>
                  Ao solicitar o processamento das marcações, será necessário
                  aguardar seu término para que possa ser realizada uma nova
                  solicitação.
                </div>
                <div className="mt-4" style={{ textAlign: "text-justify" }}>
                  Deseja continuar?
                </div>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setModalProcessamento(false)}
          >
            Cancelar
          </Button>
          {drawningLayerTemp &&
          drawningLayerTemp.length > 0 &&
          drawningLayerTemp.filter(
            (layer) =>
              !layer[0] ||
              !layer[0][0] ||
              !layer[0][1] ||
              !layer[1] ||
              !layer[1][0] ||
              !layer[1][0][0] ||
              !layer[1][0][1] ||
              !layer[1][1] ||
              !layer[1][1][0] ||
              !layer[1][1][1] ||
              !layer[1][2] ||
              !layer[1][2][0] ||
              !layer[1][2][1] ||
              !layer[1][3] ||
              !layer[1][3][0] ||
              !layer[1][3][1]
          ).length > 0 ? (
            ""
          ) : (
            <Button color="success" onClick={() => enviarDemarcacoes()}>
              Confirmar
            </Button>
          )}
        </ModalFooter>
      </Modal>
      {/* Fim modal de confirmação de processamento */}

      {/* Begin Modal new public light point */}
      <Card
        style={{
          right: "5%",
          top: "5%",
          position: "absolute",
          zIndex: "2000",
          display: modalNewPublicLightPoint ? "block" : "none",
        }}
      >
        <CardTitle>
          <Row className="m-2 p-0">
            <Col style={{ fontSize: "1.2em" }}>
              {!modalNewPublicLightPoint.id
                ? "Novo Public Lightining Point"
                : "Editar Public Lightning Point"}
            </Col>
            <Col
              sm={1}
              onClick={() => {
                setNewPointPreviewMap([]);
                return setModalNewPublicLightPoint(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <BsXLg />
            </Col>
          </Row>
        </CardTitle>
        <hr className="m-0" />
        <CardBody className="m-0">
          {modalNewPublicLightPoint ? generateEditPointForm() : ""}
        </CardBody>
      </Card>

      {/* End Modal new public light point */}

      {/* Início offcanvas de detalhes do ponto */}
      <Offcanvas
        backdrop={false}
        direction="end"
        isOpen={offCanvas}
        toggle={toggleOffCanvas}
      >
        <OffcanvasHeader
          toggle={() => {
            toggleOffCanvas();
            return setFocusPoint();
          }}
        >
          Detalhes do ponto
        </OffcanvasHeader>
        <OffcanvasBody>
          <Table hover>
            <tbody>
              <tr>
                <th>Id da detecção:</th>
                <td>
                  {pointDetailOffcanvas && pointDetailOffcanvas.id
                    ? pointDetailOffcanvas.id
                    : ""}
                </td>
              </tr>
              <tr>
                <th>Latitude:</th>
                <td>
                  {pointDetailOffcanvas && pointDetailOffcanvas.latitude
                    ? Math.floor(pointDetailOffcanvas.latitude * 1000000) /
                      1000000
                    : "-"}
                </td>
              </tr>
              <tr>
                <th>Longitude:</th>
                <td>
                  {pointDetailOffcanvas && pointDetailOffcanvas.longitude
                    ? Math.floor(pointDetailOffcanvas.longitude * 1000000) /
                      1000000
                    : "-"}
                </td>
              </tr>
              <tr>
                <th>Altitude:</th>
                <td>
                  {pointDetailOffcanvas && pointDetailOffcanvas.altitude
                    ? Math.floor(pointDetailOffcanvas.altitude)
                    : "-"}
                  m
                </td>
              </tr>
              <tr>
                <th>Tipo de lâmpada:</th>
                <td>
                  {pointDetailOffcanvas &&
                  pointDetailOffcanvas.lamp_type &&
                  pointDetailOffcanvas.lamp_type !== ""
                    ? lampTypes && lampTypes[pointDetailOffcanvas.lamp_type]
                      ? lampTypes[pointDetailOffcanvas.lamp_type]
                      : pointDetailOffcanvas.lamp_type
                    : "Não definido"}
                </td>
              </tr>
              <tr>
                <th>Potência da lâmpada:</th>
                <td>
                  {pointDetailOffcanvas &&
                  pointDetailOffcanvas.lamp_power &&
                  pointDetailOffcanvas.lamp_power !== ""
                    ? pointDetailOffcanvas.lamp_power + "w"
                    : "Não definido"}
                </td>
              </tr>
              {pointDetailOffcanvas && pointDetailOffcanvas.edited ? (
                <tr>
                  <td colSpan={2} className="text-center">
                    <i>
                      <small>Ponto editado/inserido manualmente</small>
                    </i>
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <th>Confiança:</th>
                    <td>
                      <Progress
                        style={{
                          height: "20px",
                        }}
                        color={
                          pointDetailOffcanvas
                            ? pointDetailOffcanvas.classification_confidence >
                              0.5
                              ? "success"
                              : pointDetailOffcanvas.classification_confidence >
                                0.2
                              ? "warning"
                              : "danger"
                            : "#FFF"
                        }
                        value={
                          pointDetailOffcanvas
                            ? pointDetailOffcanvas.classification_confidence *
                              100
                            : 0
                        }
                      >
                        {Math.ceil(
                          pointDetailOffcanvas
                            ? pointDetailOffcanvas.classification_confidence *
                                100
                            : 0
                        )}
                        %
                      </Progress>
                    </td>
                  </tr>
                  <tr>
                    <th>Precisão GPS:</th>
                    <td>
                      <Progress
                        style={{
                          height: "20px",
                        }}
                        color={
                          pointDetailOffcanvas
                            ? pointDetailOffcanvas.gps_confidence > 0.5
                              ? "success"
                              : pointDetailOffcanvas.gps_confidence > 0.2
                              ? "warning"
                              : "danger"
                            : "#FFF"
                        }
                        value={
                          pointDetailOffcanvas
                            ? pointDetailOffcanvas.gps_confidence * 100
                            : 0
                        }
                      >
                        {pointDetailOffcanvas
                          ? Math.ceil(pointDetailOffcanvas.gps_confidence * 100)
                          : 0}
                        %
                      </Progress>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          <Row style={{ marginBottom: "1em" }}>
            {CheckPermission(
              userPermission && userPermission.role ? userPermission.role : "",
              "EMPLOYEE"
            ) ? (
              <Col>
                <button
                  //onClick={() => openEditModal(pointDetailOffcanvas)}

                  onClick={() =>
                    setModalNewPublicLightPoint(pointDetailOffcanvas)
                  }
                  className="btn btn-outline-secondary col-12"
                  type="button"
                >
                  Editar ponto
                </button>
              </Col>
            ) : (
              ""
            )}

            {/* <Col>
                <button
                  onClick={() => openDetailsModal(pointDetailOffcanvas)}
                  className="btn btn-outline-secondary col-12"
                  type="button"
                >
                  Ver detalhes
                </button>
              </Col> */}
          </Row>
        </OffcanvasBody>
      </Offcanvas>

      {/* Início offcanvas de detalhes do ponto */}

      <div
        style={{
          minHeight: "10vh",
          minWidth: "30vw",
          position: "absolute",
          top: messageDiv ? "5vh" : "-50vh",
          right: "5vh",
          zIndex: "1050",
          background: "white",
          borderRadius: "20px",
          padding: "1em",
          transition: "all .5s",
          border: "#e0e0e0 solid 1px",
        }}
      >
        <Tooltip id="my-tooltip" />
        <strong>
          {messageDiv && messageDiv["title"] ? messageDiv["title"] : ""}
        </strong>
        <hr className="m-1 ms-0 me-0 p-0" />
        <small>
          {messageDiv && messageDiv["message"] ? messageDiv["message"] : ""}
        </small>
        <Row className="m-0 mt-2">
          {messageDiv && messageDiv["buttonText1"] ? (
            <Col>
              <Link
                style={{ height: "100%" }}
                className="btn btn-outline-secondary col-12"
                onClick={messageDiv["buttonAction1"]}
              >
                <div className="m-auto">{messageDiv["buttonText1"]}</div>
              </Link>
            </Col>
          ) : (
            ""
          )}
          {messageDiv && messageDiv["buttonText2"] ? (
            <Col>
              <Link
                style={{ height: "100%" }}
                className="btn btn-success col-12"
                onClick={messageDiv["buttonAction2"]}
              >
                {messageDiv["buttonText2"]}
              </Link>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
      {!drawning &&
      processMovingAllMarker === -1 &&
      processMovingPLPoint === -1 &&
      !processMarkingRectangle &&
      processMovingRectangle === -1 &&
      !modalNewPublicLightPoint &&
      !offCanvas ? (
        <div
          id="barraLateral"
          className={"menu-lateral-unhover text-center m-0 pt-2 p-1"}
          style={
            orientationMode && orientationMode === "landscape"
              ? {
                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: "auto",
                  // top: "auto",
                  left: "10px",
                  right: "10px",
                  // bottom: "50px",
                }
              : { overflowY: "scroll", overflowX: "hidden" }
          }
          // style={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          <Row>
            <Col
              className={
                orientationMode && orientationMode === "landscape"
                  ? "col-7"
                  : "col-12"
              }
            >
              <Row className="m-0 pb-1">
                <Link
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Voltar para a lista de missões"
                  className="text-end m-0 p-0"
                  style={{ marginRight: "0px" }}
                  to={"/missoes/" + projectDetails.id}
                >
                  <h5 className="col-12 m-0 text-center">
                    <BsArrowLeft
                      className="nav-button-circle m-1 ms-0 ps-0"
                      style={{ boxShadow: "none", maxHeight: "25px" }}
                    />
                    Voltar
                  </h5>
                </Link>
              </Row>
              <ListGroup>
                {drawningLayerTemp
                  ? drawningLayerTemp.map((layer, cont = 0) => {
                      return (
                        <ListGroupItem
                          key={Math.random()}
                          className="m-0 p-1"
                          action={true}
                          active={
                            pointsNewPolygonMapSelectedLayer === cont
                              ? true
                              : false
                          }
                          onClick={() => {
                            setPointsNewPolygonMapSelectedLayer(cont);

                            // setTransformLayer(-1);
                            setTimeout(() => {
                              setTransformLayer(cont);
                            }, 100);
                          }}
                        >
                          <Row className="ps-3 pe-3">
                            {!layer[0] ||
                            !layer[0][0] ||
                            !layer[0][1] ||
                            !layer[1] ||
                            !layer[1][0] ||
                            !layer[1][0][0] ||
                            !layer[1][0][1] ||
                            !layer[1][1] ||
                            !layer[1][1][0] ||
                            !layer[1][1][1] ||
                            !layer[1][2] ||
                            !layer[1][2][0] ||
                            !layer[1][2][1] ||
                            !layer[1][3] ||
                            !layer[1][3][0] ||
                            !layer[1][3][1]
                              ? "*"
                              : ""}
                            <div
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={"Marcação #" + cont}
                              className="m-auto"
                              style={{
                                width: "30px",
                                height: "20px",
                                backgroundColor:
                                  colorLayersList[cont] &&
                                  colorLayersList[cont][1]
                                    ? colorLayersList[cont][1]
                                    : "purple",
                                borderRadius: "7px",
                              }}
                              onClick={() => {
                                // setTransformLayer(cont);
                              }}
                            ></div>
                            {/* <Col>Ponto #{cont}</Col> */}
                            <div
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={"Mover marcação #" + cont}
                              className="m-auto"
                              style={{
                                width: "10px",
                                cursor: "pointer",
                                padding: "0",
                                margin: "0",
                              }}
                              // onClick={() => {
                              //   moverMarcacao(cont);
                              // }}
                            >
                              <UncontrolledDropdown style={{ zIndex: "2000" }}>
                                <DropdownToggle className="nav-link" tag="a">
                                  <BsArrowsMove />
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "5px",
                                    zIndex: "2000",
                                  }}
                                >
                                  <DropdownItem header className="m-0 ms-1 p-0">
                                    Mover
                                  </DropdownItem>
                                  <DropdownItem className="mt-1 mb-1" divider />
                                  <DropdownItem
                                    href="#"
                                    tag="a"
                                    className="m-0 ms-1 p-0"
                                    onClick={() => {
                                      moverPlPoint(cont);
                                    }}
                                  >
                                    Ponto
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#"
                                    tag="a"
                                    className={"m-0 ms-1 p-0"}
                                    onClick={() => {
                                      if (layer[1] && layer[1].length === 4) {
                                        moverMarcacao(cont);
                                      } else {
                                        setModalNotificacao({
                                          title: "Camada incompleta",
                                          content:
                                            "Esta ação não pode ser realizada pois a camada está incompleta.",
                                          okButton: true,
                                        });
                                      }
                                    }}
                                  >
                                    Área
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#"
                                    tag="a"
                                    onClick={() => {
                                      if (layer[1] && layer[1].length === 4) {
                                        moverTodaMarcacao(cont);
                                      } else {
                                        setModalNotificacao({
                                          title: "Camada incompleta",
                                          content:
                                            "Esta ação não pode ser realizada pois a camada está incompleta.",
                                          okButton: true,
                                        });
                                      }
                                    }}
                                    className="m-0 ms-1 p-0"
                                  >
                                    Toda a camada
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={"Copiar marcação #" + cont}
                              className="m-auto"
                              style={{
                                width: "10px",
                                cursor: "pointer",
                                padding: "0",
                                margin: "0",
                              }}
                              onClick={() => {
                                copiarMarcacao(cont);
                              }}
                            >
                              <BsMouseFill />
                            </div>
                            <div
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                "Apagar a marcação #" + cont
                              }
                              className="m-auto"
                              style={{
                                width: "10px",
                                cursor: "pointer",
                                padding: "0",
                                margin: "0",
                              }}
                              onClick={() => {
                                setTransformLayer(-1);
                                removerPonto(cont, -1);
                              }}
                            >
                              <BsFillTrashFill />
                            </div>
                            <div
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                "Editar a marcação #" + cont
                              }
                              className="m-auto"
                              style={{
                                width: "10px",
                                cursor: "pointer",
                                padding: "0",
                                margin: "0",
                              }}
                              onClick={() => {
                                setTransformLayer(-1);
                                setTransformLayer(cont, -1);
                              }}
                            >
                              <BsCrop />
                            </div>
                          </Row>
                        </ListGroupItem>
                      );
                    })
                  : ""}
                {drawningLayerTemp.length < 100 ? (
                  <ListGroupItem
                    key={Math.random()}
                    className="p-1"
                    action={true}
                    onClick={() => {
                      setTransformLayer(-1);
                      // set

                      let auxDrawningLayerTemp = drawningLayerTemp;
                      auxDrawningLayerTemp.push([]);
                      setPointsNewPolygonMap(auxDrawningLayerTemp);
                      setPointsNewPolygonMapSelectedLayer(
                        auxDrawningLayerTemp.length - 1
                      );
                    }}
                  >
                    <Row
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Adicionar uma nova marcação"
                    >
                      <Col className="m-0 p-0">
                        <BsPlusSquareDotted className="m-0 me-1" />
                        <small>Nova marcação</small>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ) : (
                  ""
                )}
              </ListGroup>
            </Col>
            <Col>
              <Row
                className="p-1"
                // action={true}
                onClick={() => {
                  showProcessedPoints
                    ? setShowProcessedPoints(false)
                    : setShowProcessedPoints(true);
                }}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={"Exibir os pontos já processados."}
              >
                <Col className="m-0 col-1">
                  <div
                    className="m-0 p-0"
                    style={{
                      width: "22px",
                      height: "21px",
                      background: "#FFF",
                      borderRadius: "4px",
                    }}
                  >
                    {showProcessedPoints ? <BsCheckLg className="m-1" /> : ""}
                  </div>
                </Col>
                <Col className="m-0 ms-2">
                  <Label>
                    <BsPinMap className="me-2" /> <small>Pontos</small>
                  </Label>
                </Col>
              </Row>
              <Row
                className="p-1"
                // action={true}
                onClick={() => {
                  setTransformLayer(-1);
                  pointsNewPolygonMapExpressMode
                    ? setPointsNewPolygonMapExpressMode(false)
                    : setPointsNewPolygonMapExpressMode(true);
                }}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={
                  "Modo expresso: elimina a necessidade de criar uma nova marcação, basta clicar."
                }
              >
                <Col className="m-0 col-1">
                  <div
                    className="m-0 p-0"
                    style={{
                      width: "22px",
                      height: "21px",
                      background: "#FFF",
                      borderRadius: "4px",
                    }}
                  >
                    {pointsNewPolygonMapExpressMode ? (
                      <BsCheckLg className="m-1" />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col className="m-0 ms-2">
                  <Label>
                    <BsClockHistory className="me-2" />
                    <small>Expresso</small>
                  </Label>
                </Col>
              </Row>
              <Row
                className="p-2 pt-0" // action={true}
              >
                <Col className="row m-0 p-0">
                  <Button
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={
                      drawningLayerTemp.length > 0
                        ? "Enviar para processamento"
                        : "Primeiro realize uma marcação no mapa."
                    }
                    disabled={drawningLayerTemp.length > 0 ? false : true}
                    className="btn-success m-auto pe-0 ps-0 col-10"
                    onClick={() => {
                      setModalProcessamento(true);
                    }}
                  >
                    <small>Processar</small>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      {CheckPermission(
        userPermission && userPermission.role ? userPermission.role : "",
        "EMPLOYEE"
      ) ? (
        <MapContainer
          id={"map"}
          className="m-0 no-select"
          center={[-20.32138449535715, -40.339507198852004]}
          zoom={18}
          ref={setMap}
          zoomControl={false}
          style={{
            position: "relative",
            width: "100vw",
            height: "100vh",
            userSelect: "none",
            cursor: "move !important",
          }}
          interactive={
            false
            // processMovingRectangle && processMovingRectangle >= 0 ? true : false
          }
        >
          {baseMapSource && baseMapSource.url && baseMapSource.subDomains ? (
            <TileLayer
              maxZoom={22}
              attribution={'<a href="http://www.google.com">Google&copy</a>'}
              url={baseMapSource.url}
              subdomains={baseMapSource.subDomains}
            />
          ) : (
            ""
          )}
          <LayersControl position="bottomright">
            {showProcessedPoints &&
            originMarkers &&
            originMarkers.length > 0 ? (
              <>
                <LayersControl.Overlay
                  checked
                  key={Math.random()}
                  name="PointMarkers"
                >
                  <LayerGroup key={Math.random()}>
                    {zoomLevelInfo > minZoomToGroupPoints ? (
                      <MarkerClusterGroup
                        key={Math.random()}
                        spiderfyDistanceMultiplier={0}
                        maxClusterRadius={10}
                        showCoverageOnHover={false}
                      >
                        {getPointsMarkers()}
                      </MarkerClusterGroup>
                    ) : (
                      getPointsMarkers()
                    )}
                  </LayerGroup>
                </LayersControl.Overlay>
              </>
            ) : (
              ""
            )}

            {newPointPreviewMap && newPointPreviewMap.length > 0 ? (
              <LayersControl.Overlay
                checked
                key={Math.random()}
                name="Marcação de um novo Pl Point"
              >
                <LayerGroup>
                  {newPointPreviewMap.map((point) => {
                    let idElement = Math.floor(Math.random() * 1000);
                    return (
                      <Marker
                        key={"marker-" + idElement}
                        position={[point[0], point[1]]}
                        fillColor={"#2058b3"}
                        radius={defaultRadiusMarker}
                        fillOpacity={0.6}
                        color={"#2058b3"}
                        icon={selectedPointIcon}
                      />
                    );
                  })}
                </LayerGroup>
              </LayersControl.Overlay>
            ) : (
              ""
            )}

            {pointsNewPolygonMap && pointsNewPolygonMap.length > 0 ? (
              <>
                <LayersControl.Overlay
                  checked
                  key={Math.random()}
                  name="Manually PL Points"
                >
                  <LayerGroup>
                    {pointsNewPolygonMap.map((layers, contLayer = 0) => {
                      return layers[0] && layers[0].length > 0 ? (
                        <CircleMarker
                          key={Math.floor(Math.random() * 1000)}
                          center={layers[0]}
                          fillColor={
                            //colorLayersList[contLayer][2]
                            colorLayersList[contLayer] &&
                            colorLayersList[contLayer][2]
                              ? colorLayersList[contLayer][2]
                              : "purple"
                          }
                          radius={defaultRadiusMarker}
                          fillOpacity={0.9}
                          color={
                            colorLayersList[contLayer] &&
                            colorLayersList[contLayer][2]
                              ? colorLayersList[contLayer][2]
                              : "purple"
                          }
                        />
                      ) : (
                        ""
                      );
                    })}
                  </LayerGroup>
                </LayersControl.Overlay>
              </>
            ) : (
              ""
            )}
          </LayersControl>
          <MapEvents />
          {centerMapTag ? <CenterMap /> : ""}
          {kmlListPreview ? kmlListPreview : null}

          <ZoomUpdate />
        </MapContainer>
      ) : (
        <h4>Sem permissão para acessar esta área.</h4>
      )}
      <Card
        style={{
          right: "2%",
          bottom: "5%",
          width: "300px",
          position: "absolute",
          zIndex: "4000",
          display: progressBar && progressBar !== 0 ? "block" : "none",
        }}
      >
        <CardBody className="m-0">
          {progressBar && progressBar === 1 ? "Carregado." : "Carregando..."}
          <Progress
            className="mt-2 mb-2"
            style={{
              height: "20px",
            }}
            color={"success"}
            value={progressBar && progressBar * 100}
          >
            {Math.ceil(progressBar && progressBar * 100)}%
          </Progress>
          {progressBar && progressBar === 1 ? (
            ""
          ) : (
            <div
              className="btn btn-outline-secondary col-12 m-0"
              onClick={() => {
                setProgressBar(0);
                axiosCancelToken.cancel();
                setLoadingKmz(false);
                // source.cancel();
                // abortController.abort();
              }}
            >
              Cancelar
            </div>
          )}
        </CardBody>
      </Card>

      {!drawning &&
      processMovingAllMarker === -1 &&
      processMovingPLPoint === -1 &&
      !processMarkingRectangle &&
      processMovingRectangle === -1 ? (
        drawningLayerTemp &&
        drawningLayerTemp.length > 1 &&
        drawningLayerTemp.filter(
          (layer) =>
            !layer[0] ||
            !layer[0][0] ||
            !layer[0][1] ||
            !layer[1] ||
            !layer[1][0] ||
            !layer[1][0][0] ||
            !layer[1][0][1] ||
            !layer[1][1] ||
            !layer[1][1][0] ||
            !layer[1][1][1] ||
            !layer[1][2] ||
            !layer[1][2][0] ||
            !layer[1][2][1] ||
            !layer[1][3] ||
            !layer[1][3][0] ||
            !layer[1][3][1]
        ).length > 0 ? (
          <Card
            style={{
              right: "2%",
              bottom: "5%",
              width: "300px",
              position: "absolute",
              zIndex: "4000",
              display: "block",
            }}
          >
            <CardBody className="m-0">
              Existem camadas que não foram desenhadas corretamente, por favor
              verifique as camadas marcadas com ' <b>*</b> ' na lista de
              camadas.
            </CardBody>
          </Card>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

export default EditPoints;
