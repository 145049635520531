import React, { useRef, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./Login.css";

const Login = (props) => {
  // Define o título da aba/página
  document.title = "Entrar no sistema - RoboticTech";
  // Recupera dados do arquivo ".env"
  let keyCloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
  const navigate = useNavigate();
  const refContainer = useRef(null);
  const [message, setMessage] = useState();

  // Verifica se o usuário já está logado e o redireciona
  if (localStorage.getItem("robotictech_accessToken"))
    return <Navigate to="/home" />;

  // Recebe os dados digitados nos inputs (usuário e senha)
  const handleSubmit = (event, values) => {
    //Impede que o formulário atue de forma padrão
    event.preventDefault();

    //Verifica se os campos foram preenchidos
    if (event.target.email.value && event.target.password.value) {
      //Apresenta o indicador de carregamento
      refContainer.current.innerHTML =
        '<div class="m-auto"><div class="spinner-border" style="height:18px; width:18px" role="status"><span class="sr-only"></span></div> Aguarde...</div>';

      // Constrói o body da requisição
      let user;
      let details = {
        grant_type: "password",
        client_id: "login-app",
        username: event.target.email.value,
        password: event.target.password.value,
      };
      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // Realiza a requisição ao KeyCloak com os dados informados
      fetch(keyCloakUrl + "/realms/Census/protocol/openid-connect/token", {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
          else {
            switch (res.status) {
              case 401:
                setMessage("Usuário e/ou senha incorreta.");
                break;
              case 400:
                setMessage("Usuário não encontrado.");
                break;

              default:
                break;
            }
            return false;
          }
        })
        .then((data) => {
          if (data) {
            // Altera o indicador de carregamento e redireciona para a página "/home"
            setMessage("Usuário autenticado");
            localStorage.setItem("robotictech_username", details.username);
            //localStorage.setItem("robotictech_password", details.password);
            localStorage.setItem("robotictech_accessToken", data.access_token);
            localStorage.setItem(
              "robotictech_refreshToken",
              data.refresh_token
            );
            navigate("/home");
            return user;
          }
        })
        .catch((error) => {
          console.log(error.toString());
          if (
            error.toString().indexOf("50") ||
            error.toString().indexOf("404")
          ) {
            setMessage("Falha de comunicação com o servidor");
          } else {
            setMessage("Algo errado ocorreu: " + error);
          }
        })
        .finally(() => {
          // Altera o indicador de carregamento retornando o botão padrão
          refContainer.current.innerHTML = '<div class="m-auto">Entrar</div>';
        });
    } else {
      // Informa que existe um ou mais campos vazios
      setMessage("Informe o usuário e a senha.");
    }
  };
  // Constrói o layout da página
  return (
    <section
      style={{
        background:
          "linear-gradient(to top left, #68f1fc 2%, #0082ff 44%, #202e79 100%)",
        fontFamily: '"Maven Pro", serif',
        height: "100vh",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: "1320px",
          margin: "0 auto",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="custom-grid">
          <div className="custom-grid-col1">
            <div className="flight-census-logo-container">
              <img
                alt="logo_robotictech"
                className="img-fluid"
                src="/imgs/flight-census-logo.svg"
                style={{ width: "100%" }}
              />
            </div>
            <div className="drone-container">
              <img
                alt="logo_robotictech"
                className="img-fluid"
                src="/imgs/drone.svg"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="custom-grid-col2">
            <form onSubmit={handleSubmit}>
              <div className="logo_container">
                <img src="/imgs/robotictech_logo.svg" alt="logo" />
              </div>

              <div className="inputs_container">
                <div>
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    <b>Usuário</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Ex: exemplo@exemplo.com"
                    required
                    onKeyDown={() => setMessage()}
                    aria-describedby="emailHelp"
                  />
                </div>
                <div>
                  <label className="form-label">
                    <b>Senha</b>
                  </label>
                  <input
                    placeholder="Insira sua senha"
                    required
                    onKeyDown={() => setMessage()}
                    type="password"
                    className="form-control"
                    name="password"
                  />
                </div>
              </div>

              {message && <div className="text-center">{message}</div>}

              <div className="btns_container">
                <button
                  ref={refContainer}
                  type="submit"
                  id="btnEntrar"
                  className="btn btn-success w-100"
                  value="Entrar"
                  style={{ fontSize: "1.2em", background: "#00C280" }}
                >
                  Entrar
                </button>
              </div>

              <div className="mascot_container">
                <img className="mascot" src="/imgs/mascot.svg" alt="logo" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
