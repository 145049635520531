import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  ListGroupItem,
  ListGroup,
  Spinner,
  Container,
  Card,
} from "reactstrap";
import { BsArrowLeft, BsArrowsMove, BsFillTrashFill } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import cidadesEstados from "../../elements/CidadesEstados";
import cidadesEstadosCoords from "../../elements/CidadesEstadosCoords";
import {
  Circle,
  MapContainer,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from "react-leaflet";
import Leaflet from "leaflet";

const NewProject = () => {
  // Define o título aba/página
  document.title = "Criar Projeto";

  // Obtém os parâmetros enviados pela URL
  let { id: projectId } = useParams();

  // Recupera dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  let mapSourcePreview = JSON.parse(process.env.REACT_APP_LEAFLET_PREVIEWMAP);
  const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);
  let defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;

  const [cidadesForm, setCidadesForm] = useState();
  const [projectData, setProjectData] = useState();
  const [recenterMap, setRecenterMap] = useState();

  const [map, setMap] = useState(null);
  const [movePolygonPoint, setMovePolygonPoint] = useState(null);

  useEffect(() => {
    // console.log(movePolygonPoint);
  }, [movePolygonPoint]);

  // Define o hook para centralizar o mapa quando houver alteração na escolha da cidade
  const [cityCoordsRecenter, setCityCoordsRecenter] = useState(null);

  // Define o hook para armazenamento do token de acesso à API
  const [userPermission, setUserPermission] = useState(null);

  // Realiza a requisição de verificação da autenticação do usuário
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  // Define o hook do indicador de carregamento
  const [loading, setLoading] = useState(!projectId ? false : true);

  // Construção do indicador de carregamento
  const loadingIcon = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  // Define o hook e controle de camada das formas desenhadas no mapa
  const [pointsNewPolygonMap, setPointsNewPolygonMap] = useState([[]]);
  const [
    pointsNewPolygonMapSelectedLayer,
    setPointsNewPolygonMapSelectedLayer,
  ] = useState(0);

  // Define os hooks e controles do modal
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
    buttonAction: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = "";
    if (!content.buttonAction) content.buttonAction = false;

    setModalContent(content);
    setModal(!modal);
  };

  // Define os parâmetros para centralização do mapa
  let centerTag = {
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
    pointsCount: 0,
  };

  useEffect(() => {
    if (pointsNewPolygonMap) {
      var layers = Leaflet.layerGroup();

      pointsNewPolygonMap.forEach((layer, contLayer = 0) => {
        if (layer.length > 0) {
          let polygon = Leaflet.polygon(layer, {
            key: Math.random(),
            transform: true,
            style: "zIndex: 1",
            color:
              colorLayersList[contLayer] && colorLayersList[contLayer][2]
                ? colorLayersList[contLayer][2]
                : "purple",
            fillColor:
              colorLayersList[contLayer] && colorLayersList[contLayer][2]
                ? colorLayersList[contLayer][2]
                : "purple",
          });
          polygon.setStyle({
            zIndexOffset: 3,
          });
          polygon.addTo(layers);
        }
      });
      if (layers) {
        if (!layers.options) {
          layers.options = {};
        }
        if (!layers.options.id) {
          layers.options.id = "marcacoes";
        }
      }

      let auxLayers = [];
      if (map) {
        map.eachLayer(function (layer) {
          if (
            layer &&
            layer.options &&
            layer.options.id &&
            (layer.options.id === "marcacoes" || layer.options.id === "circles")
          ) {
            if (layer.options.id === "circles") {
              auxLayers.push(layer);
            }
            map.removeLayer(layer);
          }
        });
        if (map && layers) {
          map.addLayer(layers, true);
        }
        auxLayers.forEach((e) => {
          map.addLayer(e, true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsNewPolygonMap]);

  // Recurso de centralização do mapa
  const RecenterAutomatically = ({ lat, lng, zoom }) => {
    let diffLat = centerTag.latMax - centerTag.latMin;
    if (diffLat < 0) diffLat *= -1;
    const map = useMap();
    useEffect(() => {
      if (true) {
        // Define o melhor zoom aplicável
        let zoomLevel = 17;

        if (diffLat * 10000 < 10) zoomLevel = 19;
        else if (diffLat * 10000 < 70) zoomLevel = 16;
        else if (diffLat * 10000 < 150) zoomLevel = 15;
        else if (diffLat * 10000 < 300) zoomLevel = 14;
        else if (diffLat * 10000 < 500) zoomLevel = 13;
        else if (diffLat * 10000 < 1000) zoomLevel = 12;
        else if (diffLat * 10000 < 8000) zoomLevel = 11;
        else if (diffLat * 10000 < 10000) zoomLevel = 10;
        else if (diffLat * 10000 < 35000) zoomLevel = 9;
        else if (diffLat * 10000 < 50000) zoomLevel = 8;
        else if (diffLat * 10000 < 80000) zoomLevel = 7;
        else if (diffLat * 10000 < 100000) zoomLevel = 6;
        else if (diffLat * 10000 < 150000) zoomLevel = 5;
        else zoomLevel = 4;

        let newLat = lat;
        let newLng = lng;
        if (lat === 0 || lng === 0) {
          // Caso não seja possível obter as coordenadas da cidade selecionada,
          // é aplicada a localização do espírito santo.
          // Também é aplicada quando a página é aberta e ainda não foi
          // selecionada uma cidade
          newLat = -19.54708428614826;
          newLng = -40.513774803548415;
          zoomLevel = 7;
        }

        // Define os parâmetros para o mapa
        map.setView([newLat, newLng], zoom ? zoom : zoomLevel);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    return null;
  };

  // Obtém os dados do projeto, caso esteja editando um projeto
  function getProjectData() {
    if (userPermission && projectId && !projectData) {
      fetch(apiUrl + "/projects/" + projectId, {
        method: "GET",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",

          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          setProjectData(data);

          // Procura os pontos extremos para o processo de centralização da cidade selecionada
          data["areas_of_interest"].map((point) => {
            return point.forEach((elt) => {
              if (!centerTag.latMax || elt[0] < centerTag.latMax)
                centerTag.latMax = elt[0];
              if (!centerTag.latMin || elt[0] > centerTag.latMin)
                centerTag.latMin = elt[0];
              if (!centerTag.lngMax || elt[1] < centerTag.lngMax)
                centerTag.lngMax = elt[1];
              if (!centerTag.lngMin || elt[1] > centerTag.lngMin)
                centerTag.lngMin = elt[1];
              centerTag.pointsCount++;
              return point;
            });
          });

          // Utiliza os parâmetros encontrados anteriormente e define a centralização
          setRecenterMap(
            <RecenterAutomatically
              lat={(centerTag.latMax + centerTag.latMin) / 2}
              lng={(centerTag.lngMax + centerTag.lngMin) / 2}
            />
          );
          cidadesEstados.estados.filter((estados) => {
            return estados.cidades.forEach((efo) => {
              if (efo === data.city) {
                setCidadesForm(estados);
                return estados;
              }
            });
          });

          // Define os parâmetros para início do desenho no mapa

          // Seleciona a primeira camada
          setPointsNewPolygonMapSelectedLayer(0);

          // Define os desenhos das camadas no mapa
          if (data.areas_of_interest.length > 0)
            setPointsNewPolygonMap(data.areas_of_interest);

          // Desativa a recentralização
          setCityCoordsRecenter(null);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          // Desabilita o indicador de carregamento
          setLoading(false);
        });
    }
  }

  // Ao obter as permissões do usuário, solicita a requisição para
  // obter os dados do projeto.
  useEffect(() => {
    if (userPermission) getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  let fields = [
    {
      id: "name",
      name: "Nome",
      type: "text",
      initialValue: null,
      hidden: false,
    },
    {
      id: "city",
      name: "Cidade",
      type: "text",
      initialValue: null,
      hidden: false,
    },
    {
      id: "description",
      name: "Descrição",
      type: "text",
      initialValue: null,
      hidden: false,
    },
    {
      id: "start_date",
      name: "Data de início",
      type: "date",
      initialValue: null,
      hidden: false,
    },
    {
      id: "expected_end_date",
      name: "Data de término estimada",
      type: "date",
      initialValue: null,
      hidden: false,
    },
    // {
    //   id: "status",
    //   name: "Status",
    //   type: "text",
    //   initialValue: null,
    //   hidden: false,
    // },
    {
      id: "company_id",
      name: "Company",
      type: "number",
      initialValue: parseInt(localStorage.getItem("robotictech_company_id")),
      hidden: true,
    },
  ];

  // Formata números para dois dígitos
  function doisDigitos(number) {
    var output = number + "";
    while (output.length < 2) {
      output = "0" + output;
    }
    return output;
  }

  // Formatação visual de data
  function formatData(element) {
    if (
      typeof element === "string" &&
      element[1] &&
      element[1].toString().length === 1
    ) {
      let aux = element.toString().split("T");
      let aux_ = aux[0].toString().split("-");
      if (aux_[0] && aux_[1] && aux_[2]) return aux[0];
      else return false;
    } else if (element && element[0] && element[0].toString().length) {
      let dia =
        element[0] +
        "-" +
        doisDigitos(element[1]) +
        "-" +
        doisDigitos(element[2]);
      return dia;
    } else {
      return false;
    }
  }

  // Verifica se a string fornecida pela API está no formato de data
  const dateDetect = (stringDate) => {
    let aux = stringDate.split("-");
    if (
      aux[0] &&
      aux[0].length === 4 &&
      aux[1] &&
      aux[1].length === 2 &&
      aux[2] &&
      aux[2].length === 2
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Converte para o padrão de data UTC
  const convertUTC = (stringDate) => {
    var date = new Date(stringDate);
    return date.toISOString();
  };

  // Recebe os valores preenchidos para novo projeto
  const onFormSubmit = (values) => {
    values.preventDefault();

    if (values.target["name"].value.length <= 0) {
      toggleModal({
        title: "Campos incompletos",
        content: "É obrigatório inserir um nome para criar o projeto.",
        buttonAction: () => setModal(false),
      });
    } else if (
      !values.target["company_id"].value ||
      values.target["company_id"].value === "null" // Verifica se existe uma empresa vinculada
    ) {
      //Mostra a mensagem sobre a necessidade de estar vinculada a uma empresa
      toggleModal({
        title: "Erro ao inserir projeto",
        content:
          "Não foi possível detectar uma empresa vinculada ao usuário. O projeto não foi criado.",
        buttonAction: () => setModal(false),
      });
    } else {
      // Formata os valores no padrão para envio à API
      let fieldsAndValues = {};
      fields.forEach((ele) => {
        if (values.target[ele.id]) {
          let value = values.target[ele.id].value;
          if (value) {
            if (dateDetect(value)) fieldsAndValues[ele.id] = convertUTC(value);
            else fieldsAndValues[ele.id] = value;
          }
        }
      });

      // Verifica se algo foi desenhado, caso negativo é necessário
      // enviar o campo vazio à API
      if (
        pointsNewPolygonMap &&
        pointsNewPolygonMap.length > 0 &&
        pointsNewPolygonMap[0].length > 0
      ) {
        let auxPointsNewPolygonMap = [];
        pointsNewPolygonMap.forEach((e) => {
          if (e.length > 0) {
            auxPointsNewPolygonMap.push(e);
          }
        });
        fieldsAndValues["areas_of_interest"] = auxPointsNewPolygonMap;
      } else {
        fieldsAndValues["areas_of_interest"] = null;
      }

      // Caso o projectId tenha sido fornecido, indica que é uma edição do projeto,
      // caso negativo, é o cadastro de um projeto novo
      if (!projectId) {
        // Realiza a requisição com o envio dos dados fornecidos
        fetch(apiUrl + "/projects", {
          method: "POST",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + userPermission.access_token,
          },
          body: JSON.stringify(fieldsAndValues),
        })
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("Erro " + response.status);
            }
          })
          .then((data) => {
            // Mostra a mensagem positiva e redireciona para a página de projetos
            toggleModal({
              title: "Inserir novo projeto",
              content:
                "Novo projeto inserido! Você poderá vê-lo na página de projetos.",
              buttonHref: "/projetos",
            });
          })
          .catch((error) => {
            //Mostra a mensagem negativa e fecha o modal
            toggleModal({
              title: "Inserir novo projeto",
              content: "Houve um erro ao inserir o projeto. Detalhes: " + error,
              buttonAction: () => setModal(false),
            });
            console.error("Error:", error);
          });
      } else {
        // Realiza a requisição com o envio dos dados fornecidos
        fetch(apiUrl + "/projects/" + projectId, {
          method: "PUT",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + userPermission.access_token,
          },
          body: JSON.stringify(fieldsAndValues),
        })
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("Erro");
            }
          })
          .then((data) => {
            // Mostra a mensagem positiva e redireciona para a página de projetos
            toggleModal({
              title: "Editar projeto",
              content:
                "Projeto alterado!\n\nVocê poderá vê-lo na página de projetos.",
              buttonHref: "/projetos",
            });
          })
          .catch((error) => {
            //Mostra a mensagem negativa e fecha o modal
            toggleModal({
              title: "Editar projeto",
              content:
                "Houve um erro ao editar o projeto.\n\nDetalhes:\n" + error,
              buttonAction: () => setModal(false),
            });
            console.error("Error:", error);
          });
      }
    }
  };

  // Exclui um ponto desenhado no mapa
  const removerPonto = (layer, point) => {
    let auxPointsNewPolygonMap = pointsNewPolygonMap;
    setPointsNewPolygonMap(false);
    if (point) {
      if (point === -1) {
        let auxList = [];
        for (let i = 0; i < auxPointsNewPolygonMap.length; i++) {
          if (i !== layer) {
            auxList.push(auxPointsNewPolygonMap[i]);
          }
        }
        auxPointsNewPolygonMap = auxList;
      } else {
        auxPointsNewPolygonMap[layer] = auxPointsNewPolygonMap[layer].filter(
          (el) => point !== el
        );
      }
    } else {
      auxPointsNewPolygonMap[layer] = auxPointsNewPolygonMap[layer].slice(
        0,
        auxPointsNewPolygonMap[layer].length - 1
      );
    }

    if (!auxPointsNewPolygonMap) {
      auxPointsNewPolygonMap = [];
    }

    setTimeout(() => {
      setPointsNewPolygonMap(auxPointsNewPolygonMap);
    }, 10);
  };

  // Recurso para capturar os eventos do mapa (click, move, etc)
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setCityCoordsRecenter(null);
        let auxPointsNewPolygonMap = pointsNewPolygonMap;
        setPointsNewPolygonMap(false);

        if (movePolygonPoint) {
          auxPointsNewPolygonMap[movePolygonPoint[1]][movePolygonPoint[0]] = [
            e.latlng.lat,
            e.latlng.lng,
          ];
          setMovePolygonPoint(false);
        } else {
          if (!pointsNewPolygonMap[pointsNewPolygonMapSelectedLayer]) {
            auxPointsNewPolygonMap[pointsNewPolygonMapSelectedLayer] = [
              [e.latlng.lat, e.latlng.lng],
            ];
          } else {
            auxPointsNewPolygonMap[pointsNewPolygonMapSelectedLayer].push([
              e.latlng.lat,
              e.latlng.lng,
            ]);
          }
        }

        setTimeout(() => {
          setPointsNewPolygonMap(auxPointsNewPolygonMap);
        }, 1);
      },
      mousemove(e) {
        let auxPointsNewPolygonMap = pointsNewPolygonMap;
        if (movePolygonPoint) {
          try {
            auxPointsNewPolygonMap[movePolygonPoint[1]][movePolygonPoint[0]] = [
              e.latlng.lat,
              e.latlng.lng,
            ];
            setPointsNewPolygonMap(null);
            setTimeout(() => {
              setPointsNewPolygonMap(auxPointsNewPolygonMap);
            }, 0);
          } catch (error) {}
        }
      },
    });
    return false;
  };

  // Constrói o layout da página
  return (
    <div className="p-2">
      {loadingIcon()}
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        <ModalFooter>
          <Link
            className="btn btn-outline-secondary"
            onClick={
              modalContent.buttonAction ? modalContent.buttonAction : false
            }
            to={modalContent.buttonHref ? modalContent.buttonHref : false}
          >
            {modalContent.button}
          </Link>
        </ModalFooter>
      </Modal>
      <Header />
      <Tooltip id="my-tooltip" />
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container  /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <h2 className="col-10 m-0 text-start">
                {/* Inicio botão voltar pagina */}
                <div className="m-2">
                  <Link
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Voltar a Página Anterior"
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={
                      projectId && projectId !== "undefined"
                        ? "/detalhesprojeto/" + projectId
                        : "/home"
                    }
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  {!projectId ? "Novo Projeto" : "Editar Projeto"}
                </div>
                {/* Final Botão voltar pagina */}
              </h2>
              <div className="col-2 pr-0 m-0 row"></div>
            </div>
            <hr />
            <div
              className="main-content mt-0 p-3"
              style={{ display: "flex", padding: "5px" }}
            >
              <div style={{ width: "100%" }} id="uprojetos">
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "MANAGER"
                ) ? (
                  <Form onSubmit={onFormSubmit}>
                    <FormGroup row>
                      <Label for={"name"} sm={3}>
                        Nome
                      </Label>
                      <Col sm={9}>
                        <Input
                          // required={true}
                          id="name"
                          name="name"
                          type="text"
                          defaultValue={
                            projectData && projectData.name
                              ? projectData.name
                              : ""
                          }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"state"} sm={3}>
                        Estado
                      </Label>
                      <Col sm={9}>
                        <select
                          id="state"
                          name="state"
                          type="select"
                          className="form-control"
                          onChange={(item) => {
                            return cidadesEstados.estados.filter((citys) => {
                              if (citys.nome === item.target.value) {
                                setCidadesForm(citys);
                              }
                              return citys;
                            });
                          }}
                          defaultValue={
                            cidadesForm && cidadesForm.nome
                              ? cidadesForm.nome
                              : ""
                          }
                        >
                          <option value={null}>Selecione...</option>;
                          {cidadesEstados.estados.map((citys, i) => {
                            return (
                              <option key={i} value={citys.nome}>
                                {citys.nome}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for={"city"} sm={3}>
                        Cidade
                      </Label>
                      <Col sm={9}>
                        {cidadesForm ? (
                          <select
                            // required={true}
                            id="city"
                            name="city"
                            type="select"
                            className="form-control"
                            onChange={(item) => {
                              return cidadesEstadosCoords.map((city) => {
                                if (city.nome === item.target.value) {
                                  setCityCoordsRecenter([
                                    city.latitude,
                                    city.longitude,
                                  ]);
                                }
                                return city;
                              });
                            }}
                            defaultValue={projectData && projectData.city}
                          >
                            <option value={false}>Selecione...</option>;
                            {cidadesForm["cidades"].map((citys, i) => {
                              return (
                                <option key={i} value={citys}>
                                  {citys}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          ""
                        )}
                        {!cidadesForm && (
                          <select className="form-control" disabled={true}>
                            <option value={false}>Selecione...</option>
                          </select>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for={"description"} sm={3}>
                        Descrição
                      </Label>
                      <Col sm={9}>
                        <Input
                          id="description"
                          name="description"
                          type="text"
                          defaultValue={
                            projectData && projectData.description
                              ? projectData.description
                              : ""
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for={"start_date"} sm={3}>
                        Data de início
                      </Label>
                      <Col sm={9}>
                        <Input
                          id="start_date"
                          name="start_date"
                          type="date"
                          defaultValue={
                            projectId
                              ? projectData && projectData.start_date
                                ? formatData(projectData.start_date)
                                : ""
                              : formatData(new Date(Date.now()).toISOString())
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for={"expected_end_date"} sm={3}>
                        Data de término estimada
                      </Label>
                      <Col sm={9}>
                        <Input
                          id="expected_end_date"
                          name="expected_end_date"
                          type="date"
                          defaultValue={
                            projectData && projectData.expected_end_date
                              ? formatData(projectData.expected_end_date)
                              : ""
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row hidden={true}>
                      <Label for={"company_id"} sm={3}>
                        Empresa
                      </Label>
                      <Col sm={9}>
                        <Input
                          id="company_id"
                          name="company_id"
                          type="text"
                          defaultValue={
                            projectData && projectData.company_id
                              ? projectData.company_id
                              : localStorage.getItem("robotictech_company_id")
                          }
                        />
                      </Col>
                    </FormGroup>

                    <h3 className="text-center mt-4 pt-4">
                      Marque a área do projeto
                    </h3>
                    <Row>
                      <Col sm={12} lg={12}>
                        <Card
                          className="m-0 p-0 border-0"
                          style={{
                            minHeight: "300px",
                            borderRadius: "10px",
                          }}
                        >
                          <Row
                            className="ms-0 me-0 mt-3 mb-3 m-md-3 p-0"
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "0px",
                              width: "200px",
                              zIndex: "1000",
                            }}
                          >
                            {!movePolygonPoint && (
                              <small>
                                <Button
                                  className="mb-2 p-0 col-12"
                                  disabled={
                                    pointsNewPolygonMap &&
                                    pointsNewPolygonMap.length > 0
                                      ? false
                                      : true
                                  }
                                  onClick={() => {
                                    removerPonto(
                                      pointsNewPolygonMapSelectedLayer
                                    );
                                  }}
                                >
                                  <small>Excluir último ponto</small>
                                </Button>
                                <ListGroup>
                                  {pointsNewPolygonMap
                                    ? pointsNewPolygonMap.map(
                                        (layer, cont = 0) => {
                                          return (
                                            <ListGroupItem
                                              key={Math.random()}
                                              action
                                              className="pt-1 pb-1"
                                              active={
                                                pointsNewPolygonMapSelectedLayer ===
                                                cont
                                                  ? true
                                                  : false
                                              }
                                              onClick={() => {
                                                setPointsNewPolygonMapSelectedLayer(
                                                  cont
                                                );
                                              }}
                                            >
                                              <Row className="ps-2 pe-2">
                                                <div
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    backgroundColor:
                                                      colorLayersList[cont] &&
                                                      colorLayersList[cont][1]
                                                        ? colorLayersList[
                                                            cont
                                                          ][1]
                                                        : "",
                                                    borderRadius: "7px",
                                                  }}
                                                ></div>
                                                <Col>Layer #{cont}</Col>
                                                <div
                                                  style={{
                                                    width: "20px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    removerPonto(cont, -1);
                                                  }}
                                                >
                                                  <BsFillTrashFill />
                                                </div>
                                              </Row>
                                            </ListGroupItem>
                                          );
                                        }
                                      )
                                    : ""}
                                  {pointsNewPolygonMap &&
                                  pointsNewPolygonMap.length < 10 ? (
                                    <ListGroupItem
                                      action
                                      className="pt-1 pb-1"
                                      onClick={() => {
                                        let auxPointsNewPolygonMap =
                                          pointsNewPolygonMap;
                                        auxPointsNewPolygonMap.push([]);
                                        setPointsNewPolygonMap(
                                          auxPointsNewPolygonMap
                                        );
                                        setPointsNewPolygonMapSelectedLayer(
                                          auxPointsNewPolygonMap.length - 1
                                        );
                                      }}
                                    >
                                      <Row>
                                        <Col>Nova camada</Col>
                                      </Row>
                                    </ListGroupItem>
                                  ) : (
                                    ""
                                  )}
                                </ListGroup>
                              </small>
                            )}
                          </Row>
                          <MapContainer
                            ref={setMap}
                            className="m-0"
                            center={[-20.32138449535715, -40.339507198852004]}
                            zoom={10}
                            style={{
                              position: "relative",
                              width: "100%",
                              // height: "50vh",
                            }}
                          >
                            <TileLayer
                              attribution={mapSourcePreview.attribution}
                              url={mapSourcePreview.url}
                              subdomains={mapSourcePreview.subDomains}
                            />
                            {/* {pointsNewPolygonMap
                            ? pointsNewPolygonMap.map((points, cont = 0) => {
                                return (
                                  <Polygon
                                    key={Math.random()}
                                    pathOptions={{
                                      color: colorLayersList[cont][1]
                                        ? colorLayersList[cont][1]
                                        : "purple",
                                    }}
                                    positions={points}
                                  />
                                );
                              })
                            : ""} */}
                            {pointsNewPolygonMap &&
                            pointsNewPolygonMap.length > 0
                              ? pointsNewPolygonMap.map(
                                  (layers, contLayer = 0) => {
                                    if (layers.length > 0) {
                                      return layers.map((point, cont = 0) => {
                                        return (
                                          <Circle
                                            id={"circles"}
                                            key={Math.random()}
                                            zIndex={10000}
                                            center={point}
                                            fillColor={
                                              cont === layers.length - 1
                                                ? colorLayersList[contLayer][2]
                                                : colorLayersList[contLayer][0]
                                            }
                                            radius={defaultRadiusMarker}
                                            fillOpacity={0.9}
                                            color={
                                              cont === layers.length - 1
                                                ? colorLayersList[contLayer][2]
                                                : colorLayersList[contLayer][0]
                                            }
                                          >
                                            {!movePolygonPoint && (
                                              <Popup>
                                                <Row className="m-0 p-0">
                                                  <p>
                                                    Ponto #{cont} da camada{" "}
                                                    {contLayer}
                                                  </p>
                                                  <Row className="m-0">
                                                    <Button
                                                      className="col-5 btn btn-primary p-0 m-auto"
                                                      onClick={() => {
                                                        removerPonto(
                                                          contLayer,
                                                          point
                                                        );
                                                      }}
                                                    >
                                                      <BsFillTrashFill />
                                                    </Button>
                                                    <Button
                                                      className="col-5 btn btn-primary p-0 m-auto"
                                                      onClick={() => {
                                                        setTimeout(() => {
                                                          setMovePolygonPoint([
                                                            cont,
                                                            contLayer,
                                                          ]);
                                                        }, 10);
                                                      }}
                                                    >
                                                      <BsArrowsMove />
                                                    </Button>
                                                  </Row>
                                                </Row>
                                              </Popup>
                                            )}
                                          </Circle>
                                        );
                                      });
                                    } else {
                                      return false;
                                    }
                                  }
                                )
                              : ""}
                            <MapEvents />

                            {recenterMap}
                            {cityCoordsRecenter ? (
                              <RecenterAutomatically
                                lat={cityCoordsRecenter[0]}
                                lng={cityCoordsRecenter[1]}
                                zoom={13}
                              />
                            ) : (
                              ""
                            )}
                          </MapContainer>
                        </Card>
                      </Col>
                    </Row>
                    {!projectId ? (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Criar um Novo Projeto"
                      >
                        <div className="row mt-2 mb-2">
                          <Button outline className="col-11 col-lg-8 m-auto">
                            Criar Projeto
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Editar o projeto"
                      >
                        <div className="row mt-2 mb-2">
                          <Button outline className="col-8 m-auto">
                            Editar Projeto
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                ) : (
                  <h4>Sem permissão para acessar esta área.</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewProject;
