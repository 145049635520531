import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Form,
  Table,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Label,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Progress,
  Spinner,
  ListGroup,
  ListGroupItem,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../elements/Header";
import {
  Marker,
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  LayersControl,
  Polygon,
  CircleMarker,
} from "react-leaflet";
import {
  BsArrowDownUp,
  BsTrash,
  BsSdCard,
  BsFileEarmarkPlus,
  BsFilter,
  BsPlugFill,
  BsXLg,
  BsFillLightbulbFill,
  BsFillBookmarkPlusFill,
  BsMapFill,
  BsFillLayersFill,
  BsFillFlagFill,
  BsLayers,
  BsFlag,
} from "react-icons/bs";
import Leaflet from "leaflet";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Map.css";
import Canvas from "./Canvas";
import * as ReactDOM from "react-dom/client";
import ImageCrop from "./ImageCrop";
import SideBarMap from "../../elements/SideBarMap";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import JSZip from "jszip";
import MarkerClusterGroup from "react-leaflet-cluster";
import SideBarMapRight from "../../elements/SideBarMapRight";
import "leaflet-imageoverlay-rotated";

import axios from "axios";

const Map = (props) => {
  document.title = "Map Page";
  let cont = 0;
  const [numberValuesApi, setNumberValuesApi] = useState(100000);
  let { id: projectId, type: typeVisualization } = useParams();
  let defaultPointColor = process.env.REACT_APP_DEFAULT_POINT_COLOR;
  let selectedPointColor = process.env.REACT_APP_SELECTED_POINT_COLOR;
  let minZoomToGroupPoints = process.env.REACT_APP_MIN_ZOOM_TO_GROUP_POINTS;
  let maxZoomToGroupPoints = process.env.REACT_APP_MAX_ZOOM_TO_GROUP_POINTS;
  let defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;
  // let showMergedPublicLightingPoints =;
  let colorScale = JSON.parse(process.env.REACT_APP_COLOR_SCALE);
  let apiUrl = process.env.REACT_APP_API_URL;

  const scrollImagesCropListCanvas = React.createRef();

  const scrollToBottom = () => {
    if (scrollImagesCropListCanvas && scrollImagesCropListCanvas.current)
      scrollImagesCropListCanvas.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  const [mergedPoints, setMergedPoints] = useState([]);
  const [showOnlyMergedPoints, setShowOnlyMergedPoints] = useState(true);
  const [originMarkers, setOriginMarkers] = useState([]);
  const [originMarkersFull, setOriginMarkersFull] = useState([]);
  const [areaOfInterest, setAreaOfInterest] = useState();
  const [activeProject, setactiveProject] = useState(null);
  const [powerList, setPowerList] = useState([]);
  const [activeFilterPower, setActiveFilterPower] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [activeFilterType, setActiveFilterType] = useState([]);
  const [centerAuto, setCenterAuto] = useState(true);
  const [pointDetailOffcanvas, setPointDetailOffcanvas] = useState({});
  const [updatePointsMap, setUpdatePointsMap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zoomLevelInfo, setZoomLevelInfo] = useState([]);
  const [count, setCount] = useState();
  const [missionsOfProject, setMissionsOfProject] = useState([]);
  const [kmzProject, setKmzProject] = useState(false);
  const [kmzActive, setKmzActive] = useState(false);
  const [loadingKmz, setLoadingKmz] = useState(false);
  const [activeKmzSelected, setActiveKmzSelected] = useState(false);
  const [listFieldSorted, setListFieldSorted] = useState(null);
  const [areasOfInterestActive, setAreasOfInterestActive] = useState(false);

  const [modalNotificacao, setModalNotificacao] = useState(false);

  const [enablePointsView, setEnablePointsView] = useState(true);

  const [progressBar, setProgressBar] = useState(0);
  const [progressBarState, setProgressBarState] = useState(0);

  const [projectDetails, setProjectDetails] = useState(null);

  const [missionPointsEnabled, setMissionPointsEnabled] = useState([]);

  const [centerScreenMap, setCenterScreenMap] = useState(null);
  const [activeZoomPage, setActiveZoomPage] = useState(null);
  const [alertZoom, setAlertZoom] = useState(true);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    if (windowSize !== [window.innerWidth, window.innerHeight]) {
      setWindowSize(null);
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, window.innerHeight]);

  // const [updateOrthoMap, setUpdateOrthoMap] = useState(true);

  const [axiosCancelToken, setAxiosCancelToken] = useState(
    axios.CancelToken.source()
  );

  const colorLayersList = JSON.parse(process.env.REACT_APP_COLOR_LAYERS_MAP);

  const selectedPointIcon = new Leaflet.Icon({
    iconUrl: "/imgs/selectedPointMap.png",
    iconAnchor: [22, 62],
    popupAnchor: [10, -44],
    iconSize: [44, 72],
  });
  useEffect(() => {
    getKmzMission(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionsOfProject]);

  const parser = new DOMParser();
  const [kmlList, setKmlList] = useState({});
  const [kmlListPreview, setKmlListPreview] = useState();
  const [map, setMap] = useState(null);
  const closeModalNewImageLightningPoint = () => {
    setModalNewImageLightningPoint(false);
    setModalNewImageLightningPointMessage("");
  };
  const [modalNewImageLightningPoint, setModalNewImageLightningPoint] =
    useState(false);
  const [
    modalNewImageLightningPointMessage,
    setModalNewImageLightningPointMessage,
  ] = useState("");
  const [modalNewPublicLightPoint, setModalNewPublicLightPoint] =
    useState(false);
  const [newPublicLightPointMessage, setNewPublicLightPointMessage] =
    useState("");
  const showPublicLoghtPointMessage = (msg) => {
    setNewPublicLightPointMessage(msg);
    setTimeout(() => {
      setNewPublicLightPointMessage("");
    }, 2000);
  };

  const [loadingVector, setLoadingVector] = useState();

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoadingVector(loadingIcon());
      }, 1000);
    } else {
      setLoadingVector(loadingIcon());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const [userPermission, setUserPermission] = useState(null);

  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  const getExtension = (fileName) => {
    let ext = "";
    let aux = fileName.split(".");
    ext = aux[aux.length - 1];
    return ext;
  };

  const formataTempoDecorrido = (seconds_int) => {
    let dateObj = new Date(seconds_int * 1000);
    let hours = dateObj.getUTCHours().toString().padStart(2, "0");
    let minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");
    let seconds = Math.floor(dateObj.getSeconds()).toString().padStart(2, "0");
    let timeString = "";
    if (hours !== "00") {
      timeString += hours + "h ";
    }
    if (hours !== "00" || minutes !== "00") {
      timeString += minutes + "m ";
    }
    timeString += seconds + "s";
    return timeString;
  };

  const loadingIcon = () => {
    return (
      <div
        key={Math.random()}
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  const loadingKmzIcon = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: loadingKmz ? "flex" : "none",
        }}
      >
        <Row style={{ margin: "auto" }}>
          <Spinner
            color="primary"
            style={{
              margin: "auto",
              width: "100px",
              height: "100px",
            }}
          >
            Loading...
          </Spinner>
        </Row>
      </div>
    );
  };

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const deletePoint = (id) => {
    setLoading(true);
    fetch(apiUrl + "/publiclightingpoints/" + id, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        setModalNotificacao({
          title: "Ponto removido",
          content: "O ponto foi removido com sucesso.",
          okButton: true,
        });

        setOffCanvas(false);
        setNewPointPreviewMap([]);
        setModalNewPublicLightPoint(false);
        setUpdatePointsMap(Math.random());

        setCount(Math.random());
      })
      .catch((error) => {
        alert("Erro ao apagar o ponto: " + error);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [lampTypes, setLampTypes] = useState(null);

  const getLampTypes = () => {
    if (!lampTypes) {
      fetch(apiUrl + "/publiclightingpoints/lamp-types", {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          setLampTypes(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //setLoading(false);
        });
    }
  };

  async function getData() {
    setLoading(true);
    getMergedPoints();
    // Início obtém pontos do desenho "areas_of_interest"
    const promiseGetAreasOfInterest = new Promise((resolve, reject) => {
      fetch(apiUrl + "/projects/" + projectId, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((report) => {
          try {
            report = JSON.parse(report);
          } catch (error) {}
          setProjectDetails(report);
          setAreaOfInterest(report["areas_of_interest"]);
          resolve(report);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        })
        .finally(() => {});
    });
    // Fim obtém pontos do desenho "areas_of_interest"

    // Início obtém missões do projeto
    const promiseGetMissions = new Promise((resolve, reject) => {
      setMissionsOfProject([]);
      promiseGetAreasOfInterest.finally(() => {
        return fetch(
          apiUrl +
            "/projects/" +
            projectId +
            "/missions?size=" +
            numberValuesApi,
          {
            method: "GET",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + userPermission.access_token,
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("Erro");
            }
          })
          .then((mlista) => {
            try {
              mlista = JSON.parse(mlista);
            } catch (error) {
              console.log(error);
            }
            let aux = mlista.content;
            setMissionsOfProject(aux);
            resolve(mlista);
          })
          .finally(() => {})
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    });
    // Fim obtém missões do projeto

    // Início obtém pontos das missões do projeto

    const getMissionsPoints = (list, index, originMarkersPoints) => {
      return new Promise((resolve, reject) => {
        fetch(
          apiUrl +
            "/missions/" +
            list[index].id +
            "/public-lighting-points?" +
            // (showMergedPublicLightingPoints.toString() === "true"
            // (missionPointsEnabled && missionPointsEnabled.length === 1
            //   ?
            "ignore-merged=true" +
            // : "ignore-merged=false") +
            "&size=" +
            numberValuesApi,
          {
            method: "GET",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + userPermission.access_token,
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("Erro");
            }
          })
          .then((mlista) => {
            try {
              mlista = JSON.parse(mlista);
            } catch (error) {}
            mlista.content.forEach((point) => {
              point["mission"] = list[index].id;
            });
            let pointList = originMarkersPoints;

            let powerListAux = powerList;
            let typeListAux = typeList;

            let projectListAux = [];
            mlista.content.map((point) => {
              if (!activeProject) {
                let projectListExist = false;
                projectListAux.forEach((e) => {
                  if (e === point.project_id) {
                    projectListExist = true;
                  }
                });
                if (!projectListExist) {
                  projectListAux.push(point.project_id);
                }
              }

              let powerListExist = false;
              powerListAux.forEach((e) => {
                if (e === point.lamp_power) {
                  powerListExist = true;
                }
              });
              if (!powerListExist) {
                powerListAux.push(point.lamp_power);
              }

              let typeListExist = false;
              typeListAux.forEach((e) => {
                if (e === point.lamp_type) {
                  typeListExist = true;
                }
              });
              if (!typeListExist) {
                typeListAux.push(point.lamp_type);
              }

              pointList.push(point);
              return point;
            });

            setPowerList(powerListAux);
            setTypeList(typeListAux);

            let auxList = [];
            pointList.forEach((e) => {
              let multiFilter = [false, false];
              if (activeFilterPower.length === 0) multiFilter[0] = true;
              else
                activeFilterPower.forEach((filterPower) => {
                  if (e.lamp_power === filterPower) {
                    multiFilter[0] = true;
                  }
                });
              if (activeFilterType.length === 0) multiFilter[1] = true;
              else
                activeFilterType.forEach((filterType) => {
                  if (e.lamp_type === filterType) {
                    multiFilter[1] = true;
                  }
                });

              if (!multiFilter.includes(false)) {
                auxList.push(e);
              }
              return null;
            });

            if (cont < 2 && (pointList.length > 0 || auxList > 0))
              setCenterAuto(true);

            setOriginMarkers(auxList);
            setOriginMarkersFull(auxList);

            resolve(true);
          })
          .finally(() => {
            if (list.length > ++index) {
              getMissionsPoints(list, index, originMarkersPoints);
            } else {
              setLoading(false);
              if (originMarkersPoints.length === 0)
                setAreasOfInterestActive(true);
              else {
                setOriginMarkers(originMarkersPoints);
                setOriginMarkersFull(originMarkersPoints);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    };

    const promiseGetMissionPoints = new Promise((resolve, reject) => {
      getLampTypes();
      return promiseGetMissions.then((res) => {
        if (res.content && res.content.length > 0) {
          return getMissionsPoints(res.content, 0, []);
        } else {
          setLoading(false);
        }
      });
    });

    promiseGetMissionPoints.then((res) => {
      alert("finalizado", res);
    });
    // Fim obtém pontos das missões do projeto
  }

  const getMergedPoints = () => {
    fetch(
      //projects/{id}/publiclightingpoints?only-merged=false
      apiUrl +
        "/projects/" +
        projectId +
        "/publiclightingpoints?only-merged=true&size=" +
        numberValuesApi,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((mlista) => {
        try {
          mlista = JSON.parse(mlista);
        } catch (error) {}
        setMergedPoints(mlista.content);
        getAllMergedPoints(mlista.content);
      })
      .catch((error) => {
        console.log("get merged points error", error);
      });
  };

  const getAllMergedPoints = (mergedPoints) => {
    fetch(
      apiUrl +
        "/projects/" +
        projectId +
        "/publiclightingpoints/merged?size=" +
        numberValuesApi,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((mlista) => {
        try {
          mlista = JSON.parse(mlista);
        } catch (error) {}
        let auxMergedPoints = mergedPoints;
        auxMergedPoints.forEach((e) => {
          mlista.forEach((mergedPoint) => {
            if (e.id === mergedPoint.id) {
              e.mission_ids = mergedPoint.mission_ids;
            }
          });
        });
        setMergedPoints(auxMergedPoints);
      })
      .catch((error) => {
        console.log("get merged points error", error);
      });
  };

  const getKmzMission = (id, listOrthoMissions) => {
    if (missionsOfProject && missionsOfProject.length > 0) {
      let link = apiUrl + "/missions/" + missionsOfProject[id].id + "/kmz";

      let auxList = [];
      if (id.toString() !== "0") auxList = listOrthoMissions;
      fetch(link, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Não foi possível obter o kmz da missão");
          }
        })
        .then((mlista) => {
          try {
            mlista = JSON.parse(mlista);
          } catch (error) {}
          let exists = false;
          auxList.forEach((eid) => {
            if (eid.id === mlista.id) {
              exists = true;
            }
          });
          if (!exists) {
            let aux = {
              id: missionsOfProject[id].id,
              name: missionsOfProject[id].name,
              option: "KMZ",
              path: mlista,
              project_id: projectId,
            };
            auxList.push(aux);
          }
          return auxList;
        })
        .finally(() => {
          if (id < missionsOfProject.length - 1) {
            setTimeout(() => {
              getKmzMission(++id, auxList);
            }, 100);
          } else {
            setTimeout(() => {
              setKmzProject(auxList);
              setLoadingKmz(false);
              setLoading(false);
            }, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };

  const sortPoints = (field) => {
    let auxList = originMarkers;

    if (listFieldSorted === field) {
      auxList.sort(function (a, b) {
        if (a[field] > b[field]) {
          return -1;
        } else {
          return true;
        }
      });
      setListFieldSorted(null);
    } else {
      auxList.sort(function (a, b) {
        if (a[field] < b[field]) {
          return -1;
        } else {
          return true;
        }
      });
      setListFieldSorted(field);
    }

    setOriginMarkers(auxList);
  };

  useEffect(() => {
    let fullMarkers = originMarkersFull;
    if (mergedPoints.length > 0) {
      mergedPoints.map((e) => {
        e["merged"] = true;
        if (fullMarkers.filter((mark) => mark.id === e.id).length === 0)
          fullMarkers.push(e);
        return true;
      });
    }

    if (fullMarkers.length > 0) {
      let auxList = fullMarkers;

      let auxL = [];
      fullMarkers.forEach((point) => {
        let addMission = false;
        if (missionPointsEnabled.length > 0) {
          missionPointsEnabled.forEach((e) => {
            if (point.merged) {
              point.mission_ids.map((missionidFilter) => {
                if (missionidFilter === e) {
                  addMission = true;
                }
                return true;
              });
            }
            if (e === point.mission) {
              addMission = true;
            }
          });
        } else {
          addMission = true;
        }
        let addPower = false;
        if (activeFilterPower.length > 0) {
          activeFilterPower.forEach((e) => {
            if (e === point.lamp_power) {
              addPower = true;
            }
          });
        } else {
          addPower = true;
        }
        let addType = false;
        if (activeFilterType.length > 0) {
          activeFilterType.forEach((e) => {
            if (e === point.lamp_type) {
              addType = true;
            }
          });
        } else {
          addType = true;
        }
        if (addMission && addPower && addType) auxL.push(point);
      });
      auxList = auxL;
      setOriginMarkers(null);
      setTimeout(() => {
        setOriginMarkers(auxList);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionPointsEnabled, activeFilterPower, activeFilterType]);

  const [loadingUpdateOrtho, setLoadingUpdateOrtho] = useState(null);

  const updateOrthoPhotoMapFunction = (insideKmlList) => {
    setTimeout(() => {
      let useKmlList = insideKmlList;
      if (!useKmlList) {
        useKmlList = kmlList;
      }

      setKmlListPreview(null);

      if (!loadingUpdateOrtho) {
        setLoadingUpdateOrtho(true);
        let auxKmlListPreview = [];
        let activeLayer = 0;
        let diffLoadImages = 0.5;
        if (activeKmzSelected && activeKmzSelected.length > 0 && map) {
          let layersList = [];
          let idLayer = "layer_" + Math.random() * 100; // + Math.random();

          layersList = [];
          activeKmzSelected.map((kmzSelected) => {
            if (useKmlList && kmzSelected && useKmlList[kmzSelected.id]) {
              if (zoomLevelInfo > 20) {
                let objectiveLayer =
                  useKmlList[kmzSelected.id].length - 1 >= 0
                    ? useKmlList[kmzSelected.id].length - 1
                    : activeLayer; //5
                diffLoadImages = 0.0001;
                if (useKmlList[kmzSelected.id][objectiveLayer])
                  activeLayer = objectiveLayer;
              } else if (zoomLevelInfo > 19) {
                let objectiveLayer =
                  useKmlList[kmzSelected.id].length - 2 >= 0
                    ? useKmlList[kmzSelected.id].length - 2
                    : activeLayer; //4
                diffLoadImages = 0.0002;
                if (useKmlList[kmzSelected.id][objectiveLayer])
                  activeLayer = objectiveLayer;
              } else if (zoomLevelInfo > 18) {
                let objectiveLayer =
                  useKmlList[kmzSelected.id].length - 3 >= 0
                    ? useKmlList[kmzSelected.id].length - 3
                    : activeLayer; //3
                diffLoadImages = 0.0004;
                if (useKmlList[kmzSelected.id][objectiveLayer])
                  activeLayer = objectiveLayer;
              } else if (zoomLevelInfo > 17) {
                let objectiveLayer =
                  useKmlList[kmzSelected.id].length - 4 >= 0
                    ? useKmlList[kmzSelected.id].length - 4
                    : activeLayer; //2
                diffLoadImages = 0.0008;
                if (useKmlList[kmzSelected.id][objectiveLayer])
                  activeLayer = objectiveLayer;
              } else if (zoomLevelInfo > 16) {
                let objectiveLayer =
                  useKmlList[kmzSelected.id].length - 5 >= 0
                    ? useKmlList[kmzSelected.id].length - 5
                    : activeLayer; //1
                diffLoadImages = 0.01;
                if (useKmlList[kmzSelected.id][objectiveLayer])
                  activeLayer = objectiveLayer;
              } else activeLayer = 0;

              diffLoadImages = diffLoadImages * zoomLevelInfo;

              let i = useKmlList[kmzSelected.id][activeLayer];
              if (!i) {
                i = useKmlList[kmzSelected.id][activeLayer - 1];
              }
              if (i) {
                auxKmlListPreview = [];

                i.map((u) => {
                  try {
                    let pointer = parser
                      .parseFromString(u[1], "text/xml")
                      .getElementsByTagName("GroundOverlay")[0]
                      .getElementsByTagName("coordinates")[0];
                    pointer = pointer.textContent.split(",0");

                    let layers;

                    if (
                      !centerScreenMap ||
                      (pointer &&
                        pointer[3].split(",")[1] >
                          centerScreenMap.lat - diffLoadImages / 2 &&
                        pointer[3].split(",")[1] <
                          centerScreenMap.lat + diffLoadImages / 2 &&
                        pointer[3].split(",")[0] >
                          centerScreenMap.lng - diffLoadImages / 2 &&
                        pointer[3].split(",")[0] <
                          centerScreenMap.lng + diffLoadImages / 2)
                    ) {
                      cont++;
                      layers = new Leaflet.layerGroup();
                      Leaflet.imageOverlay
                        .rotated(
                          u[2],
                          Leaflet.latLng(
                            parseFloat(pointer[3].split(",")[1]),
                            parseFloat(pointer[3].split(",")[0])
                          ),
                          Leaflet.latLng(
                            parseFloat(pointer[2].split(",")[1]),
                            parseFloat(pointer[2].split(",")[0]) + 0.00000255
                          ),
                          Leaflet.latLng(
                            parseFloat(pointer[0].split(",")[1]) - 0.00000255,
                            parseFloat(pointer[0].split(",")[0])
                          ),
                          {
                            opacity: 1,
                          }
                        )
                        .addTo(layers);
                    }
                    if (layers) {
                      if (!layers.options) {
                        layers.options = {};
                      }
                      if (!layers.options.id) {
                        layers.options.id = idLayer;
                      }
                    }

                    layersList.push(layers);
                  } catch (error) {
                    console.log("Erro ao carregar a imagem (KMZ): " + error);
                  }
                  return true;
                });
              }
            }
            return true;
          });

          setTimeout(() => {
            map.eachLayer(function (layer) {
              if (
                layer &&
                layer.options &&
                layer.options.id &&
                layer.options.id.indexOf("layer") !== -1 &&
                layer.options.id.indexOf(idLayer) === -1
              ) {
                map.removeLayer(layer);
              }
            });
          }, 1);

          layersList.forEach((layerItem) => {
            if (map && layerItem) map.addLayer(layerItem);
          });

          setLoading(false);
          setKmlListPreview(
            <LayersControl position="bottomright">
              {auxKmlListPreview}
            </LayersControl>
          );
        } else {
          if (map)
            map.eachLayer(function (layer) {
              if (
                layer &&
                layer.options &&
                layer.options.id &&
                layer.options.id.indexOf("layer") !== -1
              ) {
                map.removeLayer(layer);
              }
            });
        }
      }
      setLoadingUpdateOrtho(false);
    }, 1);
  };

  useEffect(() => {
    setTimeout(() => {
      updateOrthoPhotoMapFunction(kmlList);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmlList, loadingKmz, zoomLevelInfo, centerScreenMap]);

  useEffect(() => {
    getKmzDataP1([], 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmzActive, activeKmzSelected]);

  // useEffect(() => {
  //   console.log(kmlList);
  //   if (kmlList.length <= 0) {
  //     console.log("KmlList vazia", kmlList);
  //   } else {
  //     console.log("KmlList cheia", kmlList);
  //   }
  //   setTimeout(() => {
  //     setUpdateOrthoMap(Math.random());
  //   }, 100);
  // }, [kmlList]);

  const [modalDetails, setModalDetails] = useState(false);
  // const [modalEdit, setModalEdit] = useState(false);
  const [modalContent, setModalContent] = useState({});
  // const [modalEditContent, setModalEditContent] = useState({});
  const toggle = () => setModalDetails(!modalDetails);
  // const toggleEdit = () => setModalEdit(!modalEdit);

  const [offCanvas, setOffCanvas] = useState(false);
  const [offCanvasContent, setOffCanvasContent] = useState();
  // const [offCanvasImages, setOffCanvasImages] = useState([]);
  const toggleOffCanvas = () => setOffCanvas(!offCanvas);

  const [hideSecondaryMenu, setHideSecondaryMenu] = useState();

  const [offCanvasListPoints, setOffCanvasListPoints] = useState(() => {
    if (typeVisualization && typeVisualization === "list") return true;
    else return false;
  });
  const toggleOffCanvasListPoints = () =>
    setOffCanvasListPoints(!offCanvasListPoints);

  const [imagesCropListOffCanvas, setImagesCropListOffCanvas] = useState({});
  const [selectedPointOffCanvas, setSelectedPointOffCanvas] = useState();

  // const [loadingPointsStatus, setLoadingPointsStatus] = useState(false);

  const [loadingPointsCount, setLoadingPointsCount] = useState(10);

  const [loadingCropList, setLoadingCropList] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCropList]);

  useEffect(() => {}, [imagesCropListOffCanvas]);

  const getMoreCropImageItem = (itemsCount, point) => {
    console.log(
      itemsCount,
      loadingPointsCount + "\n" + imagesCropListOffCanvas[point.point.id].length
    );
    setLoadingPointsCount(loadingPointsCount + itemsCount);
    setTimeout(() => {
      getCropImageItem(imagesCropListOffCanvas[point.point.id].length, point);
    }, 100);
  };

  const getCropImageItem = (index, point) => {
    setLoadingCropList(true);
    let auxLoadingPointsCount = 10;
    if (index === 0) {
      //console.log(loadingPointsCount, index);
      auxLoadingPointsCount = 10;
      setLoadingPointsCount(10);
    } else {
      auxLoadingPointsCount = loadingPointsCount;
    }
    fetch(
      apiUrl +
        "/detections/" +
        point.detections[index].id +
        "/image/?size=" +
        numberValuesApi,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then(async (ei0) => {
        try {
          ei0 = JSON.parse(ei0);
        } catch (error) {}

        // begin fix
        let auxList = imagesCropListOffCanvas;
        if (!auxList[point.point.id]) auxList[point.point.id] = [];
        let auxDataItem = {
          point: point.point,
          contentData: point.detections[index],
          image: ei0,
        };
        let exists = false;
        auxList[point.point.id].forEach((i) => {
          if (
            i["contentData"].id &&
            auxDataItem["contentData"].id &&
            i["contentData"].id === auxDataItem["contentData"].id
          )
            exists = true;
        });
        if (!exists) auxList[point.point.id].push(auxDataItem);
        setImagesCropListOffCanvas(auxList);

        // end fix

        if (
          projectDetails.status !== "Finalizado" &&
          auxList[point.point.id].length >= point.detections.length
        )
          setOffCanvasContent(
            <>
              <Link
                className="ms-auto mb-3"
                onClick={() => {
                  setModalNewImageLightningPointMessage(null);
                  return setModalNewImageLightningPoint(point.point.id);
                }}
              >
                <BsFileEarmarkPlus /> <small>Adicionar Imagem ao ponto</small>
              </Link>
            </>
          );
      })
      .finally(() => {
        if (
          ++index < auxLoadingPointsCount &&
          imagesCropListOffCanvas[point.point.id].length <
            point.detections.length
        ) {
          getCropImageItem(index, point);
        } else {
          setLoadingCropList(false);
          scrollToBottom();
        }
      });
  };

  const openPointDetails = (point) => {
    setHideSecondaryMenu(true);
    setPointDetailOffcanvas(point);

    GetTokenApi().then((res) => {
      setUserPermission(res);

      setLoadingCropList(true);
      fetch(
        apiUrl +
          "/publiclightingpoints/" +
          point.id +
          "/detections/?size=" +
          numberValuesApi,
        {
          method: "GET",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + res.access_token,
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then(async (ei) => {
          try {
            ei = JSON.parse(ei);
          } catch (error) {}
          // let contE = 0;

          if (ei.content && ei.content.length > 0) {
            let contentData = ei.content;
            contentData.sort(function (a, b) {
              if (a.quality > b.quality) {
                return -1;
              } else {
                return true;
              }
            });

            //console.log(ei);
            let auxSelectPointOffCanvas = {
              point: point,
              detections: contentData,
            };

            setSelectedPointOffCanvas(false);
            setLoadingPointsCount(10);
            setTimeout(() => {
              setSelectedPointOffCanvas(auxSelectPointOffCanvas);
              setTimeout(() => {
                getCropImageItem(0, auxSelectPointOffCanvas);
              }, 100);
            }, 1);
          } else {
            setOffCanvasContent(
              <>
                <Link
                  className="ms-auto mb-3"
                  onClick={() => setModalNewImageLightningPoint(point.id)}
                >
                  <BsFileEarmarkPlus /> <small>Adicionar Imagem ao ponto</small>
                </Link>
                <p>Sem prévia de imagens</p>
              </>
            );
          }
        })
        .finally(() => {
          setLoadingCropList(false);
        });
    });
    setOffCanvas(true);
  };

  useEffect(() => {
    if (userPermission) {
      setMissionsOfProject([]);
      setPowerList([]);
      setTypeList([]);
      setOriginMarkers(false);
      setCenterAuto(false);

      setPowerList([]);
      setTypeList([]);
      setOriginMarkers([]);
      setOriginMarkersFull([]);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeProject,
    updatePointsMap,
    count /*,showMergedPublicLightingPoints*/,
  ]);

  useEffect(() => {
    if (userPermission && (!originMarkers || originMarkers.length) <= 0) {
      setPowerList([]);
      setTypeList([]);
      setOriginMarkers([]);
      setOriginMarkersFull([]);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  useEffect(() => {
    setactiveProject(null);

    if (userPermission) {
      setPowerList([]);
      setTypeList([]);
      setOriginMarkers([]);
      setOriginMarkersFull([]);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberValuesApi]);

  useEffect(() => {
    //console.log("s", cont);
  }, [modalContent]);

  useEffect(() => {
    //console.log("s5", cont);
  }, [offCanvasContent]);

  function openDetailsModal(point, activeDetection) {
    let details = {
      header: "Ponto de detecção n° " + point.id,
      body: "",
      image: null,
    };
    setModalContent(details);
    fetch(
      apiUrl +
        "/publiclightingpoints/" +
        point.id +
        "/detections?size=" +
        numberValuesApi,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((ei) => {
        try {
          ei = JSON.parse(ei);
        } catch (error) {}
        const root = ReactDOM.createRoot(document.getElementById("canvas"));
        if (ei.content.length > 0) {
          fetch(
            apiUrl +
              "/detections/" +
              (activeDetection ? activeDetection.id : ei.content[0].id) +
              "/image?size=" +
              numberValuesApi,
            {
              method: "GET",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + userPermission.access_token,
              },
            }
          )
            .then((res0) => {
              if (res0.ok) {
                return res0.text();
              } else {
                throw new Error("Erro");
              }
            })
            .then((ei0) => {
              try {
                ei0 = JSON.parse(ei0);
              } catch (error) {}
              root.render(
                <Canvas
                  className={"m-0"}
                  authorizationToken={userPermission.access_token}
                  image={ei0.path}
                  detectionsData={ei.content}
                  activeDetection={activeDetection}
                />
              );
              setModalContent(details);
            });
        } else {
          root.render(<p>Nada a mostrar.</p>);
        }
      });
    toggle();
    return details;
  }

  const RecenterAutomatically = ({ lat, lng }) => {
    let diffLat = centerTag.latMax - centerTag.latMin;
    if (diffLat < 0) diffLat *= -1;
    const map = useMap();
    useEffect(() => {
      if (centerAuto) {
        let diffLat = centerTag.latMax - centerTag.latMin;

        if (diffLat < 0) diffLat *= -1;

        let zoomLevel = 17;

        if (diffLat * 10000 < 10) zoomLevel = 19;
        else if (diffLat * 10000 < 70) zoomLevel = 17;
        else if (diffLat * 10000 < 150) zoomLevel = 16;
        else if (diffLat * 10000 < 300) zoomLevel = 15;
        else if (diffLat * 10000 < 500) zoomLevel = 14;
        else if (diffLat * 10000 < 1000) zoomLevel = 13;
        else if (diffLat * 10000 < 8000) zoomLevel = 11;
        else if (diffLat * 10000 < 10000) zoomLevel = 8;
        else if (diffLat * 10000 < 35000) zoomLevel = 6;
        else zoomLevel = 2;

        let newLat = lat;
        let newLng = lng;
        if (lat === 0 || lng === 0) {
          // Lat e Lng do espírito santo
          newLat = -19.54708428614826;
          newLng = -40.513774803548415;
          zoomLevel = 7;
        }
        map.setView([newLat, newLng], zoomLevel);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    return null;
  };

  const ZoomUpdate = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        // let diffLat = centerTag.latMax - centerTag.latMin;
        // if (diffLat < 0) diffLat *= -1;
        // if (centerAuto) {
        // }
        setCenterAuto(false);
        setZoomLevelInfo(mapEvents.getZoom());
      },
      moveend: () => {
        setActiveZoomPage(
          ((window.outerHeight - 30) / window.innerHeight) * 100
        );
        if (!centerAuto)
          setTimeout(() => {
            setCenterScreenMap(map.getCenter());
          }, 1);
      },
    });
    return null;
  };

  let baseMaps = JSON.parse(process.env.REACT_APP_LEAFLET_BASEMAPS);

  const [baseMapSource, setBaseMapSource] = useState(baseMaps.Street);

  useEffect(() => {}, [baseMapSource]);

  const style = {
    width: "100%",
    height: "100%",
  };

  let centerTag = {
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  };
  const setFocusPoint = (point) => {
    let mapMarkers = [];
    if (originMarkers && originMarkers.length > 0) {
      mapMarkers = originMarkers; //.filter((i) => !i || !i.merged);
    }

    if (showOnlyMergedPoints && mergedPoints && mergedPoints.length > 0) {
      mergedPoints.forEach((o) => {
        o["merged"] = true;
        if (mapMarkers.filter((mark) => mark.id === o.id).length === 0)
          mapMarkers.push(o);
      });
    }

    if (mapMarkers.length > 0) {
      mapMarkers.map((value) => {
        value.radius = false;
        value.customColor = false;
        return value;
      });
      if (point) {
        point.radius = 15;
        point.customColor = selectedPointColor;
      }
    }
  };

  const [newPointPreviewMap, setNewPointPreviewMap] = useState([]);

  useEffect(() => {
    setNewPointPreviewMap([]);
  }, [modalNewPublicLightPoint]);

  useEffect(() => {
    if (activeKmzSelected) {
      let zoomLevelForInfo = zoomLevelInfo;
      setZoomLevelInfo(null);
      setTimeout(() => {
        setZoomLevelInfo(zoomLevelForInfo);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKmzSelected]);

  const getKmzDataP1 = (kmzFileResList, kmzItemActivePos) => {
    setLoadingKmz(true);
    if (activeKmzSelected && activeKmzSelected[kmzItemActivePos]) {
      let kmzItemActive = activeKmzSelected[kmzItemActivePos];
      if (kmzItemActive && kmzItemActive.path) {
        // if (!loading) setLoading(true);
        setProgressBar(0);
        axios({
          url: kmzItemActive.path,
          cancelToken: axiosCancelToken.token,
          responseType: "blob",
          onDownloadProgress(progress) {
            setProgressBar(progress.progress);
            setProgressBarState(progress);
            // if (progress.progress < 0.99 && !loading) {
            //   setLoading(true);
            // } else {
            //   // if (loading) setLoading(false);
            // }
          },
        })
          .then((res) => new File([res.data], "KMZ"))
          .then(async (res) => {
            // if (!kmlList[kmzItemActive.id]) {
            let file = {};
            file["file"] = res;
            file["data"] = kmzItemActive;
            kmzFileResList.push(file);
            // }
          })
          .finally(() => {
            //console.log(auxList);
            // setLoading(true);
            if (kmzItemActivePos < activeKmzSelected.length - 1) {
              getKmzDataP1(kmzFileResList, ++kmzItemActivePos);
            } else {
              getKmzDataP2(kmzFileResList, 0, []);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              setAxiosCancelToken(axios.CancelToken.source());
              // setLoadingKmz(false);
              setActiveKmzSelected(
                activeKmzSelected.filter((item) => item.id !== kmzItemActive.id)
              );
            } else {
              setModalNotificacao({
                title: "Erro ao carregar KMZ.",
                content: "Houve um erro ao carregar o arquivo KMZ. " + error,
                okButton: true,
              });
            }
            // setLoadingKmz(false);
            // setLoading(false);
            //alert("Houve um problema ao carregar o KMZ para esta missão.");
          });
      }
      // }
    } else {
      setLoadingKmz(false);
    }
  };

  const getKmzDataP2 = (kmlZipListFiles, position) => {
    // setLoading(true);
    let res = kmlZipListFiles[position];
    // let kmzItemActive = activeKmzSelected[position];
    // if (
    //   !auxKmlList[kmzItemActive.id] ||
    //   (auxKmlList[kmzItemActive.id] &&
    //     !auxKmlList[kmzItemActive.id][position]) ||
    //   exists
    // ) {

    if (res) {
      JSZip.loadAsync(res.file)
        .then(function (zip) {
          let objectsZip = Object.entries(zip.files);
          if (!res["contentZip"]) res["contentZip"] = {};
          res["contentZip"]["zip"] = zip;
          objectsZip.map((file) => {
            if (
              getExtension(file[0].toString()) === "kml" &&
              file[0].toString() !== "doc.kml"
            ) {
              if (!res["contentZip"]["contentKmls"])
                res["contentZip"]["contentKmls"] = [];
              res["contentZip"]["contentKmls"].push(file);
            }
            return true;
          });
        })
        .catch(function (err) {
          // setLoading(false);
          setLoadingKmz(false);
          console.error(err);
          if (err.name === "AbortError") {
            // handle abort()
            console.log("Aborted");
            setLoading(false);
          }
        })
        .finally(() => {
          if (position < kmlZipListFiles.length - 1) {
            getKmzDataP2(kmlZipListFiles, ++position);
          } else {
            getKmzDataP3(kmlZipListFiles, 0, 0, kmlList);
          }
        });
    } else {
      setLoadingKmz(false);
    }
  };

  const getKmzDataP3 = (
    kmlZipListFiles,
    filePos,
    kmlPos,
    auxKmlList,
    exists
  ) => {
    let file = kmlZipListFiles[filePos]["contentZip"]["contentKmls"][kmlPos];
    let zip = kmlZipListFiles[filePos]["contentZip"]["zip"];
    let kmzItemActive = kmlZipListFiles[filePos]["data"];

    let au = file[0].split("/");
    let position = au[0] > 0 ? au[0] : 0;

    if (
      !auxKmlList ||
      !auxKmlList[kmzItemActive.id] ||
      (auxKmlList[kmzItemActive.id] &&
        !auxKmlList[kmzItemActive.id][position]) ||
      exists
    ) {
      file[1]
        .async("text")
        .then(async (kmlFileContent) => {
          let converted = kmlFileContent.replaceAll("ns2:", "");

          let imgPathFile = file[0].replaceAll(".kml", ".jpg");
          if (!zip.files[imgPathFile]) {
            imgPathFile = file[0].replaceAll(".kml", ".png");
          }
          if (zip.files[imgPathFile]) {
            await zip.files[imgPathFile]
              .async("base64")
              .then((imgFileContent) => {
                if (!auxKmlList[kmzItemActive.id])
                  auxKmlList[kmzItemActive.id] = [];
                if (!auxKmlList[kmzItemActive.id][position])
                  auxKmlList[kmzItemActive.id][position] = [];
                var src = "data:image/jpeg;base64," + imgFileContent;
                auxKmlList[kmzItemActive.id][position].push([
                  file[0],
                  converted,
                  src,
                ]);
                // setKmlList(auxKmlList);
              })
              .catch(() => {});
          } else {
            let position = au[0] > 0 ? au[0] : 0;
            if (!auxKmlList[kmzItemActive.id])
              auxKmlList[kmzItemActive.id] = [];
            if (!auxKmlList[kmzItemActive.id][position])
              auxKmlList[kmzItemActive.id][position] = [];
            var src = "data:image/jpeg;base64,";
            console.log(position, kmzItemActive.id);
            auxKmlList[kmzItemActive.id][position].push([
              file[0],
              converted,
              src,
            ]);

            // setKmlList(auxKmlList);
          }
        })
        .catch(() => {
          setLoadingKmz(false);
        })
        .finally(() => {
          if (
            kmlPos <
            kmlZipListFiles[filePos]["contentZip"]["contentKmls"].length - 1
          ) {
            getKmzDataP3(kmlZipListFiles, filePos, ++kmlPos, auxKmlList, true);
          } else if (filePos < kmlZipListFiles.length - 1) {
            getKmzDataP3(kmlZipListFiles, ++filePos, 0, auxKmlList, true);
          } else {
            updateOrthoPhotoMapFunction(auxKmlList);
            setTimeout(() => {
              setProgressBar(0);
              // setLoading(false);
              setKmlList(auxKmlList);
              setLoadingKmz(false);
            }, 100);
          }
        });
    } else {
      if (
        kmlPos <
        kmlZipListFiles[filePos]["contentZip"]["contentKmls"].length - 1
      ) {
        getKmzDataP3(kmlZipListFiles, filePos, ++kmlPos, auxKmlList);
      } else if (filePos < kmlZipListFiles.length - 1) {
        getKmzDataP3(kmlZipListFiles, ++filePos, 0, auxKmlList);
      } else {
        updateOrthoPhotoMapFunction(auxKmlList);
        setTimeout(() => {
          setProgressBar(0);
          // setLoading(false);
          setKmlList(auxKmlList);
          setLoadingKmz(false);
        }, 100);
      }
    }
  };

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        if (newPointPreviewMap.length > 0) setNewPointPreviewMap([]);
        if (modalNewPublicLightPoint) {
          setNewPointPreviewMap([
            [e.latlng.lat.toString(), e.latlng.lng.toString()],
          ]);
        }
      },
    });
    return false;
  };
  const handleSubmitNewImagePoint = (e, publicLightningPointId) => {
    setLoading(true);
    e.preventDefault();
    console.log(e.target.imagePoint.files, publicLightningPointId);

    const uploadData = new FormData();
    if (
      e.target.imagePoint &&
      e.target.imagePoint.files &&
      e.target.imagePoint.files[0]
    ) {
      uploadData.append("image", e.target.imagePoint.files[0]);

      fetch(
        apiUrl +
          "/publiclightingpoints/" +
          publicLightningPointId +
          "/add-image",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + userPermission.access_token,
          },
          body: uploadData,
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error(
              "Houve um erro ao enviar a imagem. Erro " + response.status
            );
          }
        })
        .then((data) => {
          try {
            data = JSON.parse(data);
          } catch (error) {}
          console.log(data);
          if (data && data.response !== "OK") {
            let messageReturn = "Verifique a imagem.";
            if (data.response === "AlREADY_EXIST")
              messageReturn = "Existe no destino";
            else if (data.response === "ID_NOT_FOUND")
              messageReturn = "Não encontrada";
            else if (data.response === "COPY_ERROR")
              messageReturn = "Erro de cópia";
            else if (data.response === "NO_GPS")
              messageReturn = "Não foram encontrados dados de GPS";

            setModalNotificacao({
              title: "Não foi possível adicionar a imagem ao ponto",
              content: messageReturn,
              okButton: true,
            });
          } else if (data) {
            setModalNewImageLightningPointMessage("Adicionado");
            setModalNotificacao({
              title: "Imagem adicionada",
              content: "A imagem foi adicionada ao ponto.",
              okButton: true,
            });
          }
          setModalNewImageLightningPoint(false);
        })
        .catch((error) => {
          setModalNewImageLightningPointMessage(error.toString());
        })
        .finally(() => {
          let aux = imagesCropListOffCanvas;
          aux[selectedPointOffCanvas.point.id] = null;
          setImagesCropListOffCanvas(aux);
          openPointDetails(selectedPointOffCanvas.point);
          setLoading(false);
        });
    }
  };

  const handleSubmitNewPoint = (e) => {
    setLoading(true);

    let auxPointDetailOffcanvas = pointDetailOffcanvas;
    setPointDetailOffcanvas([]);
    e.preventDefault();
    let objectToSend = {};
    Object.entries(e.target).map((element) => {
      if (
        (element[1].value && element[1].name !== "mission") ||
        (element[1].name === "id" && element[1].value > 0)
      ) {
        objectToSend[element[1].name] = element[1].value;
      }
      return null;
    });
    //console.log(objectToSend);

    let methodFetch = "POST";
    let link = apiUrl + "/publiclightingpoints";
    if (objectToSend.id) {
      methodFetch = "PUT";
      link = apiUrl + "/publiclightingpoints/" + objectToSend.id;
    }

    fetch(link, {
      method: methodFetch,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(objectToSend),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        if (!objectToSend.id)
          setModalNotificacao({
            title: "Ponto adicionado.",
            content: "O ponto foi adicionado com sucesso.",
            okButton: true,
          });
        else
          setModalNotificacao({
            title: "Ponto alterado.",
            content: "O ponto foi alterado com sucesso.",
            okButton: true,
          });
        setUpdatePointsMap(Math.random());
        setNewPointPreviewMap([]);
        setModalNewPublicLightPoint(false);

        setCount(Math.random());
        setTimeout(() => {
          setPointDetailOffcanvas(auxPointDetailOffcanvas);
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        alert("Não foi possível realizar a ação.");
        setLoading(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setOffCanvas(false);
        setLoading(false);
      });
  };

  const pointShapeList = [
    [
      // Cruz
      [+0.00001, +0.00005],
      [+0.00001, +0.00001],
      [+0.00005, +0.00001],
      [+0.00005, -0.00001],
      [+0.00001, -0.00001],
      [+0.00001, -0.00005],
      [-0.00001, -0.00005],
      [-0.00001, -0.00001],
      [-0.00005, -0.00001],
      [-0.00005, +0.00001],
      [-0.00001, +0.00001],
      [-0.00001, +0.00005],
      [+0.00001, +0.00005],
    ],
    [
      //Losango
      [0, +0.00004],
      [+0.00005, 0],
      [0, -0.00004],
      [-0.00005, 0],
      [0, +0.00004],
    ],
    // [
    //   //Triangulo sólido
    //   [0, -0.00005],
    //   [-0.00005, +0.00005],
    //   [+0.00005, +0.00005],
    //   [0, -0.00005],
    // ],
    [
      //Ampulheta
      [+0.00005, -0.00005],
      [-0.00005, +0.00005],
      [-0.00005, -0.00005],
      [+0.00005, +0.00005],
      [+0.00005, -0.00005],
    ],
    [
      // Hexágono
      [+0.00002, +0.00005],
      [+0.00005, +0.00002],
      [+0.00005, -0.00002],
      [+0.00002, -0.00005],
      [-0.00002, -0.00005],
      [-0.00005, -0.00002],
      [-0.00005, +0.00002],
      [-0.00002, +0.00005],
      [+0.00002, +0.00005],
    ],
    [
      //Quadrado vazio
      [-0.00005, -0.00005],
      [-0.00005, +0.00005],
      [+0.00005, +0.00005],
      [+0.00005, -0.00005],
      [-0.00005, -0.00005],
      [-0.00002, -0.00002],
      [-0.00002, +0.00002],
      [+0.00002, +0.00002],
      [+0.00002, -0.00002],
      [-0.00002, -0.00002],
      [-0.00005, -0.00005],
    ],
    [
      //Quadrado
      [-0.00005, -0.00005],
      [-0.00005, +0.00005],
      [+0.00005, +0.00005],
      [+0.00005, -0.00005],
      [-0.00005, -0.00005],
    ],
  ];

  const getPointShape = (index, [lat, lng], resize) => {
    let coordsShape = [];
    pointShapeList[index].forEach((e) => {
      let zoomDiff = 1;
      if (resize) {
        if (zoomLevelInfo >= 21) {
          zoomDiff = 0.1;
        } else if (zoomLevelInfo >= 19) {
          zoomDiff = 0.3;
        } else if (zoomLevelInfo >= 18) {
          zoomDiff = 0.6;
        } else if (zoomLevelInfo >= 17) {
          zoomDiff = 1.5;
        } else if (zoomLevelInfo >= 16) {
          zoomDiff = 3;
        } else if (zoomLevelInfo >= 15) {
          zoomDiff = 6;
        } else {
          zoomDiff = 10;
        }
      }
      let aux = [lat + e[0] * zoomDiff, lng + e[1] * zoomDiff];
      coordsShape.push(aux);
    });
    return coordsShape;
  };

  const getPointsMarkers = () => {
    let mapMarkers = [];
    if (originMarkers && originMarkers.length > 0) {
      mapMarkers = originMarkers.filter((i) => !i || !i.merged);
    }

    if (
      // showOnlyMergedPoints &&
      mergedPoints &&
      mergedPoints.length > 0
      //  &&
      // activeFilterPower.length === 0 &&
      // activeFilterType.length === 0
      // ||
      // (missionPointsEnabled && missionPointsEnabled.length === 1)
    ) {
      mergedPoints.forEach((o) => {
        o["merged"] = true;
        let add = false;

        if (mapMarkers.filter((mark) => mark.id === o.id).length === 0) {
          if (o["mission_ids"]) {
            missionPointsEnabled.forEach((ty) => {
              o["mission_ids"].forEach((nt) => {
                if (ty === nt) add = true;
              });
            });
          }
          if (showOnlyMergedPoints && add) {
            mapMarkers.push(o);
          } else if (
            showOnlyMergedPoints &&
            missionPointsEnabled.length === 0 &&
            activeFilterPower.length === 0 &&
            activeFilterType.length === 0
          ) {
            mapMarkers.push(o);
          }
        }
        // if (mapMarkers.filter((mark) => mark.id === o.id).length === 0)
        //   mapMarkers.push(o);
      });
    }

    if (mapMarkers.length > 0) {
      let pointMarkers = [];

      mapMarkers.map((point) => {
        if (!centerTag.latMax || point.latitude < centerTag.latMax)
          centerTag.latMax = point.latitude;
        if (!centerTag.latMin || point.latitude > centerTag.latMin)
          centerTag.latMin = point.latitude;
        if (!centerTag.lngMax || point.longitude < centerTag.lngMax)
          centerTag.lngMax = point.longitude;
        if (!centerTag.lngMin || point.longitude > centerTag.lngMin)
          centerTag.lngMin = point.longitude;

        let args = {};

        let positions = null;

        activeFilterType.forEach((ft, cont = 0) => {
          if (ft === point.lamp_type && activeFilterPower.length > 0) {
            positions = getPointShape(
              cont,
              [point.latitude, point.longitude],
              true
            );
          }
          cont++;
        });

        if (point.radius) {
          pointMarkers.push(
            <Marker
              key={Math.random()}
              icon={selectedPointIcon}
              position={[point.latitude, point.longitude]}
              eventHandlers={{
                click: (record) => {
                  if (!modalNewPublicLightPoint) {
                    setFocusPoint(point);
                    setOffCanvasListPoints(false);
                    return openPointDetails(point);
                  } else {
                    return false;
                  }
                },
              }}
            ></Marker>
          );
        } else {
          let pointColor = "black";
          if (activeFilterPower && activeFilterPower.length > 0) {
            pointColor = colorScale[Math.floor(point.lamp_power / 10)];
          } else if (activeFilterType.length > 0) {
            Object.entries(lampTypes).map((type, contColor) => {
              if (type[0] === point.lamp_type) {
                pointColor = colorScale[contColor];
              }
              return false;
            });
          } else {
            pointColor = defaultPointColor;
          }

          args = {
            key: Math.random(),
            center: [point.latitude, point.longitude],
            radius: defaultRadiusMarker,
            fillOpacity: 0.6,
            color: pointColor,
            fillColor: pointColor,
          };

          if (positions && positions[0]) {
            pointMarkers.push(
              <Polygon
                {...args}
                positions={positions}
                eventHandlers={{
                  click: (record) => {
                    if (!modalNewPublicLightPoint) {
                      setFocusPoint(point);
                      setOffCanvasListPoints(false);
                      return openPointDetails(point);
                    } else {
                      return false;
                    }
                  },
                }}
              ></Polygon>
            );
          } else {
            pointMarkers.push(
              <CircleMarker
                {...args}
                eventHandlers={{
                  click: (record) => {
                    if (!modalNewPublicLightPoint) {
                      setFocusPoint(point);
                      setOffCanvasListPoints(false);
                      return openPointDetails(point);
                    } else {
                      return false;
                    }
                  },
                }}
              ></CircleMarker>
            );
          }

          if (cont++ === mapMarkers.length - 1) {
            pointMarkers.push(
              <RecenterAutomatically
                key={Math.random()}
                lat={(centerTag.latMax + centerTag.latMin) / 2}
                lng={(centerTag.lngMax + centerTag.lngMin) / 2}
              />
            );
          }
        }
        return null;
      });
      return pointMarkers;
    }
  };

  const sortCroppedImages = (list, field) => {
    return list.sort(function (a, b) {
      if (a[field] > b[field]) {
        return -1;
      } else {
        return true;
      }
    });
  };

  const generateEditPointForm = () => {
    return (
      <Form onSubmit={(e) => handleSubmitNewPoint(e)}>
        Coordenadas:
        <Row className="m-2">
          <Label for={"newPointLatitude"} sm={4}>
            Latitude:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointLatitude"}
              name={"latitude"}
              placeholder={"Latitude"}
              type="text"
              required
              onChange={(event) => {
                if (isNumber(event.target.value)) {
                  setNewPointPreviewMap([
                    [
                      event.target.value.replaceAll(",", "."),
                      newPointPreviewMap[0] && newPointPreviewMap[0][1]
                        ? newPointPreviewMap[0][1]
                        : modalNewPublicLightPoint.longitude,
                    ],
                  ]);
                } else {
                  showPublicLoghtPointMessage("Valor inválido.");
                }
              }}
              value={
                newPointPreviewMap.length > 0
                  ? newPointPreviewMap[0][0]
                  : modalNewPublicLightPoint.latitude
                  ? modalNewPublicLightPoint.latitude
                  : ""
              }
            />
          </Col>
        </Row>
        <Row className="m-2">
          <Label for={"newPointLongitude"} sm={4}>
            Longitude:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointLongitude"}
              name={"longitude"}
              placeholder={"Longitude"}
              type="text"
              required
              onChange={(event) => {
                //console.log(newPointPreviewMap[0][1]);
                if (isNumber(event.target.value)) {
                  setNewPointPreviewMap([
                    [
                      newPointPreviewMap[0] && newPointPreviewMap[0][0]
                        ? newPointPreviewMap[0][0]
                        : modalNewPublicLightPoint.latitude,
                      event.target.value.replaceAll(",", "."),
                    ],
                  ]);
                } else {
                  showPublicLoghtPointMessage("Valor inválido.");
                }
              }}
              value={
                newPointPreviewMap.length > 0
                  ? newPointPreviewMap[0][1]
                  : modalNewPublicLightPoint.longitude
                  ? modalNewPublicLightPoint.longitude
                  : ""
              }
            />
          </Col>
        </Row>
        <div className="text-end" style={{ fontSize: ".7em" }}>
          Clique no mapa para indicar a localização.
        </div>
        <Row>
          <Label for={"newPointAltitude"} sm={4}>
            Altitude:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointAltitude"}
              name={"altitude"}
              placeholder={"Altitude"}
              type="number"
              required
              defaultValue={
                modalNewPublicLightPoint.altitude
                  ? modalNewPublicLightPoint.altitude
                  : 0
              }
            />
          </Col>
        </Row>
        <Row>
          <Label for={"newPointLongitude"} sm={4}>
            Potência da lâmpada:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointPotencia"}
              name={"lamp_power"}
              placeholder={"Potência"}
              type="number"
              required
              defaultValue={
                modalNewPublicLightPoint.lamp_power
                  ? modalNewPublicLightPoint.lamp_power
                  : ""
              }
            />
          </Col>
        </Row>
        <Row>
          <Label for={"newPointTipo"} sm={4}>
            Tipo da lâmpada:
          </Label>
          <Col sm={8} className="m-auto">
            {lampTypes ? (
              <select
                className="form-control"
                id={"newPointTipo"}
                name={"lamp_type"}
                placeholder={"Tipo"}
                defaultValue={modalNewPublicLightPoint.lamp_type}
              >
                {Object.entries(lampTypes).map((type, cont) => {
                  return (
                    <option key={cont} value={type[0]}>
                      {type[1]}
                    </option>
                  );
                })}
              </select>
            ) : (
              ""
            )}
            {/* <Input
              id={"newPointTipo"}
              name={"lamp_type"}
              placeholder={"Tipo"}
              type="text"
              required
              defaultValue={
                modalNewPublicLightPoint.lamp_type
                  ? modalNewPublicLightPoint.lamp_type
                  : ""
              } 
            />*/}
          </Col>
        </Row>
        <Row style={{ display: "none" }}>
          <Label for={"newPointProjeto"} sm={4}>
            Projeto:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointProjeto"}
              name={"project_id"}
              placeholder={"Projeto"}
              type="hidden"
              defaultValue={projectId}
            />
          </Col>
        </Row>
        <Row style={{ display: "none" }}>
          <Label for={"newPointId"} sm={4}>
            Point Id:
          </Label>
          <Col sm={8} className="m-auto">
            <Input
              id={"newPointId"}
              name={"id"}
              placeholder={"Point ID"}
              type="hidden"
              value={
                modalNewPublicLightPoint.id ? modalNewPublicLightPoint.id : ""
              }
            />
          </Col>
        </Row>
        {newPublicLightPointMessage ? (
          <Row className="text-danger mt-2">
            <p className="text-center">{newPublicLightPointMessage}</p>
          </Row>
        ) : (
          <Row>
            {CheckPermission(
              userPermission && userPermission.role ? userPermission.role : "",
              "EMPLOYEE"
            ) && modalNewPublicLightPoint.id ? (
              <Col>
                <Button
                  outline
                  className="col-12 mt-3 btn-outline-danger"
                  onClick={() => {
                    setModalNewPublicLightPoint(false);
                    setModalNotificacao({
                      title: "Excluir ponto.",
                      content: "Deseja exluir o ponto?",
                      cancelButton: () => {
                        setModalNotificacao(false);
                        setModalNewPublicLightPoint(modalNewPublicLightPoint);
                      },
                      confirmButton: () =>
                        deletePoint(modalNewPublicLightPoint.id),
                    });
                  }}
                >
                  <BsTrash className="me-2" />
                  Apagar Ponto
                </Button>
              </Col>
            ) : (
              ""
            )}

            <Col>
              <Button className="col-12 mt-3">
                <BsSdCard className="me-2" />
                {!modalNewPublicLightPoint.id ? "Adicionar" : "Salvar"}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    );
  };

  const [listDetections, setListDetections] = useState(false);

  const genListDetections = () => {
    if (!loadingCropList) {
      if (selectedPointOffCanvas && imagesCropListOffCanvas) {
        if (imagesCropListOffCanvas[selectedPointOffCanvas.point.id]) {
          let listShowDetections = [];
          sortCroppedImages(
            imagesCropListOffCanvas[selectedPointOffCanvas.point.id],
            "quality"
          ).map((imageCropItem) => {
            listShowDetections.push(
              <ListGroupItem
                key={Math.random()}
                onClick={() => {
                  openDetailsModal(
                    imageCropItem.point,
                    imageCropItem.contentData
                  );
                }}
                action
                style={{
                  cursor: "pointer",
                  border: "none",
                  padding: "auto",
                }}
              >
                <Row>
                  <Col sm="3" className="p-auto m-auto">
                    <ImageCrop
                      className="m-auto"
                      style={{
                        margin: "auto",
                      }}
                      imageSrc={imageCropItem.image.path_low}
                      detectionData={imageCropItem.contentData}
                    />
                  </Col>
                  <Col sm="9" className="p-auto m-auto">
                    <Row>
                      <Col>
                        Qualidade:
                        <Progress
                          style={{
                            height: "10px",
                            fontSize: ".6em",
                          }}
                          color={
                            imageCropItem.contentData.quality > 0.5
                              ? "success"
                              : imageCropItem.contentData.quality > 0.2
                              ? "warning"
                              : "danger"
                          }
                          value={imageCropItem.contentData.quality * 100}
                        >
                          {Math.floor(imageCropItem.contentData.quality * 100)}%
                        </Progress>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            );
            return true;
          });
          setListDetections(listShowDetections);
        }
      }
    } else {
    }
  };

  useEffect(() => {
    setListDetections(false);
    setTimeout(() => {
      genListDetections();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPointOffCanvas, imagesCropListOffCanvas, loadingCropList]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Header
        title={projectDetails && projectDetails.name ? projectDetails.name : ""}
        url={"detalhesprojeto/" + projectId}
      />

      {/* Início modal de notificação */}
      <Modal // Modal de notificação
        style={{ zIndex: "2050" }}
        isOpen={modalNotificacao ? true : false}
      >
        <ModalHeader>
          {modalNotificacao && modalNotificacao.title
            ? modalNotificacao.title
            : ""}
        </ModalHeader>
        <ModalBody>
          <div className="row m-1">
            {modalNotificacao && modalNotificacao.content
              ? modalNotificacao.content
              : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          {modalNotificacao && modalNotificacao.cancelButton ? (
            <Button color="secondary" onClick={modalNotificacao.cancelButton}>
              Cancelar
            </Button>
          ) : (
            ""
          )}

          {modalNotificacao && modalNotificacao.okButton ? (
            <Button
              color="secondary"
              onClick={() => setModalNotificacao(false)}
            >
              Ok
            </Button>
          ) : (
            ""
          )}
          {/* {modalNotificacao && modalNotificacao.actionButton ? (
            <Button color="secondary" onClick={modalNotificacao.actionButton}>
              Ok
            </Button>
          ) : (
            ""
          )} */}
          {modalNotificacao && modalNotificacao.confirmButton ? (
            <Button color="success" onClick={modalNotificacao.confirmButton}>
              Confirmar
            </Button>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
      {/* Fim modal de notificação */}

      <SideBarMapRight
        showLeftMenu={offCanvasListPoints ? "show" : "hide"}
        baseMaps={[
          [
            "Base Maps",
            baseMaps,
            baseMapSource,
            setBaseMapSource,
            <BsMapFill />,
          ],
        ]}
        areasOfInterest={[
          [
            "Contorno",
            areaOfInterest,
            areasOfInterestActive,
            setAreasOfInterestActive,
            <BsFillLayersFill />,
            <BsLayers />,
          ],
        ]}
        enablePoints={[
          [
            "Marcações",
            null,
            enablePointsView,
            setEnablePointsView,
            <BsFillFlagFill />,
            <BsFlag />,
          ],
        ]}
      />
      <SideBarMap
        toast={props.toast}
        loading={loadingKmz}
        lampTypes={lampTypes}
        showLeftMenu={offCanvasListPoints ? "show" : "hide"}
        hideSecondaryMenu={hideSecondaryMenu}
        pointTable={[[setOffCanvasListPoints, offCanvasListPoints]]}
        filters={[
          [
            "Filtro de potência",
            powerList
              .filter((item) => item !== null && item !== "Não definido")
              .sort((a, b) => a - b),
            activeFilterPower,
            setActiveFilterPower,
            <BsPlugFill />,
          ],
          [
            "Filtro de tipo",
            typeList
              .filter((item) => item !== null && item !== "Não definido")
              .sort(),
            activeFilterType,
            setActiveFilterType,
            <BsFillLightbulbFill />,
          ],
        ]}
        missions={[
          [
            "Missões",
            missionsOfProject
              ? missionsOfProject.map((mission) => {
                  return [
                    mission.id,
                    mission.name,
                    missionsOfProject.filter((i) => i.id === mission.id),
                  ];
                })
              : null,
            missionPointsEnabled,
            setMissionPointsEnabled,
            <BsFillBookmarkPlusFill />,
            showOnlyMergedPoints,
            setShowOnlyMergedPoints,
          ],
        ]}
        qtdePontos={[
          ["Qtde Pontos", null, numberValuesApi, setNumberValuesApi],
        ]}
        orthophoto={[
          [
            "Orthophoto",
            kmzProject, // ? kmzProject : [],
            kmzActive,
            setKmzActive,
            setActiveKmzSelected,
            setKmlList,
            kmlList,
            setLoadingKmz,
            activeKmzSelected,
          ],
        ]}
      />
      <div
        id="ColorTableSubtitle"
        style={{
          position: "absolute",
          right: offCanvas ? "400px" : "20px",
          transition: "right .5s ease-in",
          bottom: "30px",
          zIndex: "600",
          width:
            activeFilterType && activeFilterType.length > 0 ? "180px" : "90px",
          borderRadius: "10px",
        }}
      >
        {activeFilterType && activeFilterType.length > 0 ? (
          <div
            id="ColorTableSubtitle"
            style={{
              borderRadius: "10px",
            }}
            className="bg-light m-1"
          >
            <Row className="m-0 p-0" style={{ overflow: "hidden" }}>
              {activeFilterType &&
              activeFilterType.length > 0 &&
              activeFilterPower &&
              activeFilterPower.length > 0
                ? activeFilterType.map((a, cont = 0) => {
                    return (
                      <div className="col-12 row m-0 p-1" key={Math.random()}>
                        <div
                          className="mt-0 mb-0 me-0 ms-auto p-0"
                          style={{ width: "auto", marginLeft: "auto" }}
                        >
                          <small>{lampTypes[a]}</small>
                        </div>
                        <div
                          className="p-0 mt-0 mb-0 me-0 ms-1"
                          style={{
                            width: "30px",
                            height: "30px",
                            overflow: "hidden !important",
                          }}
                        >
                          <canvas
                            // width={"20"}
                            // height={"50"}
                            style={
                              {
                                // width: "40px",
                                // height: "40px",
                                //border: "1px solid black",
                              }
                            }
                            ref={(a) => {
                              if (a) {
                                var ctx = a.getContext("2d");

                                ctx.strokeStyle = "#3b3b3b";
                                ctx.fillStyle = "#3b3b3b";

                                ctx.beginPath();
                                getPointShape(cont, [0, 0]).map(
                                  (coords, cont = 0) => {
                                    if (cont++ === 0)
                                      ctx.moveTo(
                                        coords[1] * -200000 + 13,
                                        coords[0] * -200000 + 13
                                      );
                                    ctx.lineTo(
                                      coords[1] * -200000 + 13,
                                      coords[0] * -200000 + 13
                                    );
                                    return null;
                                  }
                                );
                                ctx.stroke();
                              }
                            }}
                          ></canvas>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </Row>
          </div>
        ) : (
          ""
        )}
        {(activeFilterPower && activeFilterPower.length > 0) ||
        ((!activeFilterPower || activeFilterPower.length === 0) &&
          activeFilterType &&
          activeFilterType.length > 0) ? (
          <div
            id="ColorTableSubtitle"
            style={{
              borderRadius: "10px",
            }}
            className="bg-light m-1"
          >
            {activeFilterPower && activeFilterPower.length > 0
              ? colorScale
                ? colorScale.map((color, pos) => {
                    return activeFilterPower.map((e) => {
                      if (
                        pos >= Math.floor(e / 10) &&
                        pos < Math.floor(e / 10) + 1
                      ) {
                        return (
                          <Row
                            className="text-end"
                            style={{ width: "100%" }}
                            key={Math.random()}
                          >
                            <Col className="ms-auto">{pos * 10}w</Col>
                            <div
                              className="mt-auto mb-auto p-0"
                              style={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "5px",
                                backgroundColor: color,
                              }}
                            ></div>
                          </Row>
                        );
                      } else return null;
                    });
                  })
                : ""
              : activeFilterType.map((item, contColor) => {
                  let contColor_ = 0;
                  Object.entries(lampTypes).map((type, cont) => {
                    if (type[0] === item) {
                      contColor_ = cont;
                    }
                    return true;
                  });

                  return (
                    <Row
                      className="text-end"
                      style={{ width: "100%" }}
                      key={Math.random()}
                    >
                      <Col className="ms-auto">
                        <small>{lampTypes[item]}</small>
                      </Col>
                      <div
                        className="mt-auto mb-auto p-0"
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "5px",
                          backgroundColor: !item
                            ? "black"
                            : colorScale[contColor_],
                        }}
                      ></div>
                    </Row>
                  );
                })}
          </div>
        ) : (
          ""
        )}
      </div>
      {loading && loadingVector}
      {!loading && loadingKmzIcon()}
      {/* Begin Modal new image in public light point */}
      <Card
        style={{
          right: "5%",
          top: "5%",
          position: "absolute",
          zIndex: "2000",
          display: modalNewImageLightningPoint ? "block" : "none",
        }}
      >
        <CardTitle>
          <Row className="m-2 p-0">
            <Col style={{ fontSize: "1.2em" }}>
              Nova Imagem - Public Lightining Point
            </Col>
            <Col
              sm={1}
              onClick={() => closeModalNewImageLightningPoint()}
              style={{ cursor: "pointer" }}
            >
              <BsXLg />
            </Col>
          </Row>
        </CardTitle>
        <hr className="m-0" />
        <CardBody className="m-0">
          <Form
            onSubmit={(e) =>
              handleSubmitNewImagePoint(e, modalNewImageLightningPoint)
            }
          >
            Imagem:
            <Row className="m-2">
              <Col sm={12} className="m-auto">
                <Input
                  id={"imagePoint"}
                  name={"imagePoint"}
                  type="file"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col
                className="m-auto text-center"
                style={{ maxWidth: "400px" }}
                sm={12}
              >
                {modalNewImageLightningPointMessage}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="col-12 mt-3">Adicionar</Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      {/* End Modal new image in public light point */}
      {/* Begin Modal new public light point */}
      <Card
        style={{
          right: "5%",
          top: "5%",
          position: "absolute",
          zIndex: "2000",
          display: modalNewPublicLightPoint ? "block" : "none",
        }}
      >
        <CardTitle>
          <Row className="m-2 p-0">
            <Col style={{ fontSize: "1.2em" }}>
              {!modalNewPublicLightPoint.id
                ? "Novo Public Lightining Point"
                : "Editar Public Lightning Point"}
            </Col>
            <Col
              className="col-1"
              onClick={() => setModalNewPublicLightPoint(false)}
              style={{ cursor: "pointer" }}
            >
              <BsXLg />
            </Col>
          </Row>
        </CardTitle>
        <hr className="m-0" />
        <CardBody className="m-0">
          {modalNewPublicLightPoint ? generateEditPointForm() : ""}
        </CardBody>
      </Card>

      {/* End Modal new public light point */}

      {/* Begin Modal Lightning point details */}
      <Modal
        //fullscreen
        size="xl"
        isOpen={modalDetails}
        toggle={toggle}
        //className={className}
        backdrop={true}
        keyboard={false}
        style={{ zIndex: "1600" }}
      >
        <ModalHeader toggle={toggle}>{modalContent.header}</ModalHeader>
        <ModalBody className={"m-0 p-0"}>
          {<div id={"canvas"} style={{ width: "100% !important" }}></div>}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {/* End Modal Lightning point details */}

      <MapContainer
        center={[-20.76021534717294, -41.67064717252183]}
        maxZoom={21}
        zoom={16}
        scrollWheelZoom={true}
        style={style}
        ref={setMap}
        zoomControl={false}
      >
        <MapEvents />
        <ZoomUpdate />
        <TileLayer
          attribution={'<a href="http://www.google.com">Google&copy</a>'}
          url={baseMapSource.url}
          subdomains={baseMapSource.subDomains}
          maxZoom={21}
        />
        {enablePointsView ? (
          zoomLevelInfo > minZoomToGroupPoints ||
          zoomLevelInfo < maxZoomToGroupPoints ? (
            (originMarkers && originMarkers.length > 0) ||
            (mergedPoints && mergedPoints.length > 0) ? (
              <MarkerClusterGroup
                key={Math.random()}
                //disableClusteringAtZoom={maxZoomToGroupPoints}
                spiderfyDistanceMultiplier={0}
                maxClusterRadius={15}
                showCoverageOnHover={false}
              >
                {getPointsMarkers()}
              </MarkerClusterGroup>
            ) : (
              ""
            )
          ) : (originMarkers && originMarkers.length > 0) ||
            (mergedPoints && mergedPoints.length > 0) ? (
            getPointsMarkers()
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {newPointPreviewMap.map((point) => {
          return (
            <Marker
              key={Math.random()}
              position={[point[0], point[1]]}
              fillColor={"#2058b3"}
              radius={6}
              fillOpacity={0.6}
              color={"#2058b3"}
              icon={selectedPointIcon}
            />
          );
        })}
        {/* Begin areas_of_interest */}
        {areasOfInterestActive && areaOfInterest
          ? areaOfInterest.map((point, cont = 0) => {
              //console.log(point);
              let contPoint = 0;
              //if (true) {
              point.forEach((elt) => {
                if (!centerTag.latMax || elt[0] < centerTag.latMax)
                  centerTag.latMax = elt[0];
                if (!centerTag.latMin || elt[0] > centerTag.latMin)
                  centerTag.latMin = elt[0];
                if (!centerTag.lngMax || elt[1] < centerTag.lngMax)
                  centerTag.lngMax = elt[1];
                if (!centerTag.lngMin || elt[1] > centerTag.lngMin)
                  centerTag.lngMin = elt[1];
                contPoint++;
              });
              //}
              //setZoom(zoomLevel);
              //cont++;
              let recenter = null;
              if (
                areaOfInterest.length === 1 ||
                (cont++ === areaOfInterest.length - 1 &&
                  contPoint === point.length)
              ) {
                //setZoom(zoomLevel);
                recenter = (
                  <RecenterAutomatically
                    key={Math.random()}
                    lat={(centerTag.latMax + centerTag.latMin) / 2}
                    lng={(centerTag.lngMax + centerTag.lngMin) / 2}
                  />
                );
              }
              return (
                <div key={Math.random()}>
                  <Polygon
                    key={Math.random()}
                    pathOptions={{
                      color: colorLayersList[cont][1]
                        ? colorLayersList[cont][1]
                        : "purple",
                      fillOpacity: 0,
                    }}
                    positions={point}
                  />
                  {recenter}
                </div>
              );
            })
          : "não carregou"}
        {/* End areas_of_interest */}
        {kmlListPreview ? kmlListPreview : null}
      </MapContainer>
      <div>
        <Offcanvas
          backdrop={false}
          direction="end"
          isOpen={offCanvas}
          toggle={toggleOffCanvas}
        >
          <OffcanvasHeader
            toggle={() => {
              toggleOffCanvas();
              return setFocusPoint();
            }}
          >
            Detalhes do ponto
          </OffcanvasHeader>
          <OffcanvasBody>
            <Table hover>
              <tbody>
                <tr>
                  <th>Id da detecção:</th>
                  <td>
                    {pointDetailOffcanvas && pointDetailOffcanvas.id
                      ? pointDetailOffcanvas.id
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Latitude:</th>
                  <td>
                    {pointDetailOffcanvas && pointDetailOffcanvas.latitude
                      ? Math.floor(pointDetailOffcanvas.latitude * 1000000) /
                        1000000
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Longitude:</th>
                  <td>
                    {pointDetailOffcanvas && pointDetailOffcanvas.longitude
                      ? Math.floor(pointDetailOffcanvas.longitude * 1000000) /
                        1000000
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Altitude:</th>
                  <td>
                    {pointDetailOffcanvas && pointDetailOffcanvas.altitude
                      ? Math.floor(pointDetailOffcanvas.altitude) + "m"
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Tipo de lâmpada:</th>
                  <td>
                    {pointDetailOffcanvas &&
                    pointDetailOffcanvas.lamp_type &&
                    pointDetailOffcanvas.lamp_type !== ""
                      ? lampTypes
                        ? lampTypes[pointDetailOffcanvas.lamp_type]
                        : pointDetailOffcanvas.lamp_type
                      : "Não definido"}
                  </td>
                </tr>
                <tr>
                  <th>Potência da lâmpada:</th>
                  <td>
                    {pointDetailOffcanvas &&
                    pointDetailOffcanvas.lamp_power &&
                    pointDetailOffcanvas.lamp_power !== ""
                      ? pointDetailOffcanvas.lamp_power + "w"
                      : "Não definido"}
                  </td>
                </tr>
                {pointDetailOffcanvas && pointDetailOffcanvas.edited ? (
                  <tr>
                    <td colSpan={2} className="text-center">
                      <i>
                        <small>Ponto editado/inserido manualmente</small>
                      </i>
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <th>Confiança:</th>
                      <td>
                        <Progress
                          style={{
                            height: "20px",
                          }}
                          color={
                            pointDetailOffcanvas.classification_confidence > 0.5
                              ? "success"
                              : pointDetailOffcanvas.classification_confidence >
                                0.2
                              ? "warning"
                              : "danger"
                          }
                          value={
                            pointDetailOffcanvas.classification_confidence * 100
                          }
                        >
                          {Math.ceil(
                            pointDetailOffcanvas.classification_confidence * 100
                          )}
                          %
                        </Progress>
                      </td>
                    </tr>
                    <tr>
                      <th
                        onClick={() => {
                          scrollToBottom();
                        }}
                      >
                        Precisão GPS:
                      </th>
                      <td>
                        <Progress
                          style={{
                            height: "20px",
                          }}
                          color={
                            pointDetailOffcanvas.gps_confidence > 0.5
                              ? "success"
                              : pointDetailOffcanvas.gps_confidence > 0.2
                              ? "warning"
                              : "danger"
                          }
                          value={pointDetailOffcanvas.gps_confidence * 100}
                        >
                          {Math.ceil(pointDetailOffcanvas.gps_confidence * 100)}
                          %
                        </Progress>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
            <Row style={{ marginBottom: "1em" }}>
              {CheckPermission(
                userPermission && userPermission.role
                  ? userPermission.role
                  : "",
                "EMPLOYEE"
              ) ? (
                <Col>
                  <button
                    onClick={() => {
                      return setModalNewPublicLightPoint(pointDetailOffcanvas);
                    }}
                    className="btn btn-outline-secondary col-12"
                    type="button"
                  >
                    Editar ponto
                  </button>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row className="pt-0 le-0 ps-0" style={{ paddingBottom: "80px" }}>
              <ListGroup className="p-0">
                <>
                  {offCanvasContent}
                  {selectedPointOffCanvas &&
                  imagesCropListOffCanvas &&
                  imagesCropListOffCanvas[selectedPointOffCanvas.point.id] ? (
                    <>
                      <small
                        className="text-end"
                        style={{
                          fontSize: ".7em",
                        }}
                      >
                        {
                          imagesCropListOffCanvas[
                            selectedPointOffCanvas.point.id
                          ].length
                        }{" "}
                        de {selectedPointOffCanvas.detections.length} detecções
                        carregadas
                      </small>
                    </>
                  ) : (
                    ""
                  )}
                  {listDetections ? (
                    listDetections
                  ) : (
                    <div className="m-auto text-center">
                      <Spinner
                        color="primary"
                        style={{
                          margin: "auto",
                          width: "100px",
                          height: "100px",
                        }}
                      >
                        Loading...
                      </Spinner>
                    </div>
                  )}
                  <div ref={scrollImagesCropListCanvas} />
                </>
              </ListGroup>
            </Row>
            {imagesCropListOffCanvas &&
            selectedPointOffCanvas &&
            imagesCropListOffCanvas[selectedPointOffCanvas.point.id] &&
            imagesCropListOffCanvas[selectedPointOffCanvas.point.id].length <
              selectedPointOffCanvas.detections.length ? (
              <Row
                className="m-0 bg-white"
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "40px",
                  zIndex: "500",
                  width: "100%",
                }}
              >
                <Button
                  disabled={loadingCropList ? true : false}
                  outline
                  className={"btn btn-outline-secondary"}
                  onClick={() => {
                    getMoreCropImageItem(10, selectedPointOffCanvas);
                  }}
                >
                  {loadingCropList ? (
                    <Spinner
                      style={{ margin: "auto", height: "15px", width: "15px" }}
                      color="secondary"
                      className="me-3"
                    >
                      Loading...
                    </Spinner>
                  ) : (
                    false
                  )}
                  Mostrar mais...
                </Button>
              </Row>
            ) : (
              ""
            )}
          </OffcanvasBody>
        </Offcanvas>

        <div className="sticky-top" style={{ zIndex: "1000 !important" }}>
          <Offcanvas
            id="listaPontos"
            backdrop={false}
            direction="end"
            isOpen={offCanvasListPoints}
            toggle={toggleOffCanvasListPoints}
            style={{
              minWidth: "100%",
              zIndex: "1100",
            }}
          >
            <OffcanvasHeader toggle={toggleOffCanvasListPoints}>
              {/* <Link
                className="text-end "
                style={{ marginRight: "0px" }}
                to={"/home"}
              >
                <BsArrowLeft
                  className="nav-button-circle "
                  style={{ boxShadow: "none" }}
                />
              </Link> */}
              <div className="ms-4">Lista de pontos</div>
            </OffcanvasHeader>
            <OffcanvasBody>
              <Table hover>
                <tbody>
                  <tr>
                    <th style={{ textAlign: "center" }}>
                      Status
                      <Link
                        className="ms-2"
                        onClick={() => sortPoints("lamp_power")}
                      >
                        {listFieldSorted === "lamp_power" ? (
                          <BsFilter style={{ color: "#007d34" }} />
                        ) : (
                          <BsArrowDownUp style={{ color: "#999999" }} />
                        )}
                      </Link>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      ID
                      <Link className="ms-2" onClick={() => sortPoints("id")}>
                        {listFieldSorted === "id" ? (
                          <BsFilter style={{ color: "#007d34" }} />
                        ) : (
                          <BsArrowDownUp style={{ color: "#999999" }} />
                        )}
                      </Link>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Tipo
                      <Link
                        className="ms-2"
                        onClick={() => sortPoints("lamp_type")}
                      >
                        {listFieldSorted === "lamp_type" ? (
                          <BsFilter style={{ color: "#007d34" }} />
                        ) : (
                          <BsArrowDownUp style={{ color: "#999999" }} />
                        )}
                      </Link>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Potência
                      <Link
                        className="ms-2"
                        onClick={() => sortPoints("lamp_power")}
                      >
                        {listFieldSorted === "lamp_power" ? (
                          <BsFilter style={{ color: "#007d34" }} />
                        ) : (
                          <BsArrowDownUp style={{ color: "#999999" }} />
                        )}
                      </Link>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Latitude
                      <Link
                        className="ms-2"
                        onClick={() => sortPoints("latitude")}
                      >
                        {listFieldSorted === "latitude" ? (
                          <BsFilter style={{ color: "#007d34" }} />
                        ) : (
                          <BsArrowDownUp style={{ color: "#999999" }} />
                        )}
                      </Link>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Longitude
                      <Link
                        className="ms-2"
                        onClick={() => sortPoints("longitude")}
                      >
                        {listFieldSorted === "longitude" ? (
                          <BsFilter style={{ color: "#007d34" }} />
                        ) : (
                          <BsArrowDownUp style={{ color: "#999999" }} />
                        )}
                      </Link>
                    </th>
                    {/* <th></th> */}
                  </tr>
                  {originMarkers && originMarkers.length > 0
                    ? originMarkers.map((point) => {
                        return (
                          <tr
                            key={Math.random()}
                            style={{ marginBottom: "1em", cursor: "pointer" }}
                            onClick={() => openPointDetails(point)}
                          >
                            <td>
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: "auto",
                                  borderRadius: "50%",
                                  backgroundColor: point.lamp_power
                                    ? "green"
                                    : "red",
                                }}
                              />
                            </td>
                            <td style={{ textAlign: "center" }}>#{point.id}</td>
                            <td style={{ textAlign: "center" }}>
                              {point.lamp_type ? point.lamp_type : "-"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {point.lamp_power ? point.lamp_power + "w" : "-"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {Math.floor(point.latitude * 10000) / 10000}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {Math.floor(point.longitude * 10000) / 10000}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </Table>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      </div>
      <Card
        style={{
          right: "2%",
          bottom: "5%",
          width: "300px",
          position: "absolute",
          zIndex: "4000",
          display: !progressBar && progressBar === 0 ? "none" : "block",
        }}
      >
        <CardBody className="m-0">
          {progressBar && progressBar === 1 ? "Carregando" : "Baixando KMZ..."}

          {progressBarState &&
          progressBarState.estimated &&
          progressBarState.estimated > 0 ? (
            <div>
              <small style={{ fontSize: "0.65em" }}>
                {/* Tempo estimado: {Math.floor(progressBarState.estimated)} s */}
                Tempo estimado:{" "}
                {formataTempoDecorrido(progressBarState.estimated)}
              </small>
            </div>
          ) : (
            ""
          )}

          {progressBar && (progressBar < 0.02 || progressBar === 1) ? (
            <div className="text-center">
              <Spinner
                color="primary"
                style={{
                  margin: "auto",
                  width: "15px",
                  height: "15px",
                }}
              >
                Loading...
              </Spinner>
            </div>
          ) : (
            <Progress
              className="mt-2 mb-2"
              style={{
                height: "20px",
              }}
              color={"success"}
              value={progressBar && progressBar * 100}
            >
              {Math.ceil(progressBar && progressBar * 100)}%
            </Progress>
          )}
          {progressBar && progressBar === 1 ? (
            ""
          ) : (
            <div
              className="btn btn-outline-secondary col-12 m-0"
              onClick={() => {
                setProgressBar(0);
                axiosCancelToken.cancel();
                setLoadingKmz(false);
              }}
            >
              Cancelar
            </div>
          )}
        </CardBody>
      </Card>

      <Card
        style={{
          right: "2%",
          bottom: "5%",
          width: "300px",
          position: "absolute",
          zIndex: "4000",
          display:
            alertZoom &&
            activeZoomPage &&
            (activeZoomPage < 90 || activeZoomPage > 110)
              ? "block"
              : "none",
        }}
      >
        <CardTitle className="m-0">
          <Row className="m-2">
            <Col>
              <b>Alerta de zoom</b>
            </Col>
            <Col
              className="col-3 text-end"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAlertZoom(false);
              }}
            >
              X
            </Col>
          </Row>
          <hr className="m-0 p-0" />
        </CardTitle>
        <CardBody
          className="m-0"
          style={{
            fontSize: activeZoomPage ? (250 - activeZoomPage) / 5 : "0.8em",
          }}
        >
          <div>
            Parece que você está utilizando um nível de zoom muito auto/baixo.
          </div>
          <div>Isso pode distorcer alguns elementos visuais.</div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Map;
