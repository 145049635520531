import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Container,
} from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const NewMission = () => {
  // Define o título da aba/página
  document.title = "Criar Missão";

  //Recupera dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  //Recupera dados recebidos pela url
  let { id: projectId } = useParams();

  // Controles de exeibição do modal
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
    buttonAction: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = "";
    if (!content.buttonAction) content.buttonAction = false;

    setModalContent(content);
    setModal(!modal);
  };

  // Hook de armazenamento do token de autenticação
  const [userPermission, setUserPermission] = useState(null);

  // Requisição do token de autenticação
  if (!userPermission)
    GetTokenApi().then((res) => {
      //console.log(res);
      setUserPermission(res);
    });

  // Hook de armazenamento dos dados da missão
  const [projectMissionData, setProjectMissionData] = useState(null);

  // Obtenção dos dados da missão
  const getProjectMissionData = () => {
    fetch(apiUrl + "/projects/" + projectId + "/missions", {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((pulist) => {
        try {
          pulist = JSON.parse(pulist);
        } catch (error) {
          throw error;
        }
        setProjectMissionData(pulist.content);
      })
      .finally(() => {
        // Desativa o indicador de carregamento
        setLoading(false);
      });
  };

  // Hook do indicador de carregamento
  const [loading, setLoading] = useState(true);

  // Indicador de carregamento
  const loadingIcon = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.4)",
          zIndex: "3000",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: loading ? "flex" : "none",
        }}
      >
        <Spinner
          color="primary"
          style={{
            margin: "auto",
            width: "100px",
            height: "100px",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  };

  // Formatação em dois dígitos
  function doisDigitos(number) {
    var output = number + "";
    while (output.length < 2) {
      output = "0" + output;
    }
    return output;
  }

  // Aguarda a obtenção do token de acesso e inicia a requisição dos dados da missão
  useEffect(() => {
    if (userPermission) {
      getProjectMissionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  let fields = [
    {
      id: "name",
      name: "Nome",
      type: "text",
      initialValue: projectMissionData
        ? "Missão " + doisDigitos(projectMissionData.length + 1)
        : "",
      hidden: false,
      required: true,
    },
    {
      id: "description",
      name: "Descrição",
      type: "text",
      initialValue: null,
      hidden: false,
    },
    {
      id: "user_id",
      name: "Responsável",
      type: "text",
      initialValue: 7,
      hidden: true,
    },
    {
      id: "project_id",
      name: "Id do projeto",
      type: "text",
      initialValue: projectId,
      hidden: true,
    },
  ];

  // Constrói visualmente os campos do formulário
  const createField = (fieldData) => {
    return (
      <FormGroup row hidden={fieldData["hidden"]} key={Math.random()}>
        <Label for={fieldData["id"]} sm={3}>
          {fieldData["name"]}
        </Label>
        <Col sm={9}>
          <Input
            id={fieldData["id"]}
            name={fieldData["id"]}
            placeholder={fieldData["name"]}
            type={fieldData["type"]}
            defaultValue={fieldData["initialValue"]}
            required={fieldData["required"]}
          />
        </Col>
      </FormGroup>
    );
  };

  // Detecta se a string recebida pela API é uma data
  const dateDetect = (stringDate) => {
    let aux = stringDate.split("-");
    if (aux[0].length === 4 && aux[1].length === 2 && aux[2].length === 2)
      return true;
  };

  // Converte uma data no formato string para o padrão UTC
  const convertUTC = (stringDate) => {
    var date = new Date(stringDate);
    return date.toISOString();
  };

  // Recebe os dados preenchidos pelo utilizador no formulário
  const onFormSubmit = (values) => {
    values.preventDefault();

    // Padroniza as informações para o padrão da API
    let fieldsAndValues = {};
    fields.forEach((ele) => {
      let value = values.target[ele.id].value;

      if (value) {
        if (dateDetect(value)) fieldsAndValues[ele.id] = convertUTC(value);
        else fieldsAndValues[ele.id] = value;
      }
    });

    // Realiza a requisição para envio dos dados
    fetch(apiUrl + "/missions", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: JSON.stringify(fieldsAndValues),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {
          throw error;
        }
        toggleModal({
          title: "Inserir nova missão",
          content:
            "Nova missão inserida!\n\nVocê poderá vê-la na página de missões do projeto.",
          button: "Enviar imagens",
          buttonHref: "/missoes/" + data.id + "/imagens/" + projectId,
        });
      })
      .catch((error) => {
        toggleModal({
          title: "Inserir nova missão",
          content:
            "Houve um erro ao inserir a missão.<br><br>Detalhes:<br>" + error,
          buttonAction: () => setModal(false),
        });
        console.error("Error:", error);
      });
  };

  // Constrói o layout da página
  return (
    <div className="p-2">
      {loadingIcon()}
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        <ModalFooter>
          <Link
            className="btn btn-outline-secondary"
            to={modalContent.buttonHref ? modalContent.buttonHref : false}
            onClick={
              modalContent.buttonAction
                ? modalContent.buttonAction
                : () => setModal(false)
            }
          >
            {modalContent.button}
          </Link>
        </ModalFooter>
      </Modal>
      <Header />
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <Tooltip id="my-tooltip" />
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container  /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <h2 className="col-10 m-0 text-start">
                {/* Inicio botão voltar pagina */}
                <div className="m-2">
                  <Link
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Voltar a Pagina Anterior"
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={"/missoes/" + projectId}
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  Nova Missão
                </div>
                {/* Final Botão voltar pagina */}
              </h2>
              <div className="col-2 pr-0 m-0 row"></div>
            </div>
            <hr />
            <div
              className="main-content mt-0 p-3"
              style={{ display: "flex", padding: "5px" }}
            >
              <div style={{ width: "100%" }} id="uprojetos">
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "EMPLOYEE"
                ) ? (
                  <Form onSubmit={onFormSubmit}>
                    {fields.map((e) => {
                      return createField(e);
                    })}
                    <div className="row">
                      <Button
                        className="col-8 m-auto"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Criar uma nova Missão para o Projeto"
                      >
                        Criar Missão
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <h4>Sem permissão para acessar esta área.</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewMission;
