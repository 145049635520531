import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Spinner,
} from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";

const NewReport = (props) => {
  const navigate = useNavigate();

  // Define o título da aba/página
  document.title = "Criar novo Relatório";

  // Recupera os dados do arquivo ".env"
  let apiUrl = process.env.REACT_APP_API_URL;

  // Recupera os parâmetros recebidos via url
  let { id: projectId } = useParams();

  // Hook para armazenamento do dicionário dos tipos de relatórios
  const [typesData, setTypesData] = useState();

  // Hooke para armazenamento do arquivo a ser enviado
  const [fileToSend, setFileToSend] = useState();

  // Hook para armazenamento da descrição do tipo de relatório selecionado
  const [detailsSelectedType, setDetailsSelectedType] = useState();

  // Hook para verificação da necessidade de envio de um arquivo conforme
  // o tipo de relatório solicitado
  const [uploadFileNeeded, setUploadFileNeeded] = useState();

  // Controle de exibição do modal
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = null;
    if (!content.actionButton) content.actionButton = null;

    setModalContent(content);
    setModal(!modal);
  };

  // Obtenção do dicionário dos tipos de relatórios
  function getTypesData() {
    fetch(apiUrl + "/reports/options", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setTypesData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // Controles e requisição das permissões do usuário
  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  // Ao receber o token de acesso é iniciado a requisição para a
  // obtenção dos tipos de relatórios disponíveis
  useEffect(() => {
    if (userPermission) {
      getTypesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermission]);

  // Indicador de carregamento
  const getLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  // Recebimento dos dados do relatório enviados pelo utilizador
  const onFormSubmit = (values) => {
    values.preventDefault();

    // Formatação dos dados para envio à API
    let fieldsAndValues = {};

    let formData = new FormData();
    Object.entries(values.target.elements).forEach((ele) => {
      let value = ele[1].value;

      if (value) {
        if (ele[1].id !== "file") {
          fieldsAndValues[ele[1].id] = value;
        }
      }
    });

    // Alteração do tipo de requisição para o padrão da API
    const jsontoSend = new Blob([JSON.stringify(fieldsAndValues)], {
      type: "application/json",
    });

    formData.append("report", jsontoSend, "report");

    // Verificação da existencia de um arquivo a enviar
    if (fileToSend) {
      formData.append("reference", fileToSend);
    } else {
      formData.append("reference", null);
    }

    // Exibe o modal com o indicador de carregamento
    // toggleModal({
    //   title: "Inserir novo relatório",
    //   content: (
    //     <div className="d-flex justify-content-center">
    //       <div className="spinner-border" role="status" id="loading">
    //         <span className="visually-hidden">Loading...</span>
    //       </div>
    //     </div>
    //   ),
    //   buttonHref: null,
    //   actionButton: null,
    // });
    toggleModal({
      title: "Inserir novo relatório",
      content:
        "O processamento do relatório foi iniciado, em breve o relatório poderá ser visto na lista de relatórios.",
      // buttonHref: "/relatorios/" + projectId,
      buttonHref: null,
      actionButton: () => navigate("/relatorios/" + projectId),
    });

    props.toast({
      title: "Geração de relatórios",
      message: (
        <>
          <div style={{ display: "flex" }}>
            <Spinner
              className="ms-2 me-2"
              style={{
                display: "block",
                width: "20px",
                height: "20px",
              }}
              color="primary"
            />
            Gerando...
          </div>
        </>
      ),
      timer: 3000,
    });
    // Realiza a requisição para a criação do relatório
    fetch(apiUrl + "/reports/create", {
      method: "POST",
      headers: {
        accept: "*/*",
        Authorization: "Bearer " + userPermission.access_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro " + response.status);
        }
      })
      .then((data) => {
        props.toast({
          title: "Geração de relatórios",
          message: (
            <div>
              <div>Relatório gerado.</div>
              <Button
                className="pt-1 pb-1 col-12"
                onClick={() => navigate("/relatorios/" + projectId)}
              >
                Ver lista de relatórios
              </Button>
            </div>
          ),
          timer: 3000,
        });
      })
      .catch((error) => {
        toggleModal({
          title: "Inserir novo relatório",
          content:
            "Houve um erro ao inserir o relatório.\n\nDetalhes:\n" + error,
          buttonHref: null,
          actionButton: () => setModal(false),
        });
        console.error(error);
      });
  };

  // Recebe o arquivo selecionado pelo utilizador para envio
  // e define dentro do hook de envio de arquivo.
  const handleUpload = (files) => {
    setFileToSend(files.target.files[0]);
  };

  //Constrói o layout da página
  return (
    <div className="p-2">
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        {modalContent.buttonHref || modalContent.actionButton ? (
          <ModalFooter>
            {modalContent.actionButton && (
              <Button
                className="btn btn-outline-secondary text-light"
                onClick={
                  modalContent.actionButton ? modalContent.actionButton : false
                }
              >
                {modalContent.button}
              </Button>
            )}

            {modalContent.buttonHref && (
              <Link
                className="btn btn-outline-secondary"
                to={modalContent.buttonHref ? modalContent.buttonHref : false}
              >
                {modalContent.button}
              </Link>
            )}
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
      <Header />
      <Container
        className="p-0"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <div className="container" style={{ marginTop: "10vh " }}>
          <div
            id="basicInformation"
            className="container /*main-content*/ recuadoOut"
          >
            <div className="row pt-4">
              <h2 className="col-10 m-0 text-start">
                {/* Inicio botão voltar pagina */}
                <div className="m-2">
                  <Link
                    className="text-end "
                    style={{ marginRight: "0px" }}
                    to={"/relatorios/" + projectId}
                  >
                    <BsArrowLeft
                      className="nav-button-circle "
                      style={{ boxShadow: "none" }}
                    />
                  </Link>
                  Novo Relatório
                </div>
                {/* Final Botão voltar pagina */}
              </h2>
              <div className="col-2 pr-0 m-0 row"></div>
            </div>
            <hr />
            <div
              className="main-content p-3 mt-0"
              style={{ display: "flex", padding: "5px" }}
            >
              <div style={{ width: "100%" }} id="uprojetos">
                {CheckPermission(
                  userPermission && userPermission.role
                    ? userPermission.role
                    : "",
                  "EMPLOYEE"
                ) ? (
                  !typesData ? (
                    getLoading()
                  ) : (
                    <Form onSubmit={(e) => onFormSubmit(e)}>
                      <FormGroup row>
                        <Label for={"name"} sm={3}>
                          Nome
                        </Label>
                        <Col sm={9}>
                          <Input
                            id={"name"}
                            required
                            name={"Nome"}
                            placeholder={"Nome"}
                            type={"text"}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for={"role"} sm={3}>
                          Tipo
                        </Label>
                        <Col sm={9}>
                          <Input
                            key={Math.random()}
                            required
                            className="form-select"
                            id={"option"}
                            name={"Tipo"}
                            type={"select"}
                            onChange={(record) => {
                              let exists = false;
                              typesData.forEach((r) => {
                                if (r.option === record.target.value) {
                                  if (
                                    r.option === "LOAD_COMPARISON" ||
                                    r.option === "COMPARISON"
                                  ) {
                                    setUploadFileNeeded(true);
                                  } else {
                                    setUploadFileNeeded(false);
                                  }
                                  setDetailsSelectedType(r);
                                  exists = true;
                                }
                              });
                              if (!exists) {
                                setDetailsSelectedType(false);
                              }
                              // this.selected = record.target.value;
                            }}
                            defaultValue={
                              detailsSelectedType && detailsSelectedType.option
                            }
                          >
                            <option value="">Selecione</option>
                            {typesData
                              ? typesData.map((typesItem) => {
                                  return (
                                    <option
                                      key={Math.random()}
                                      value={typesItem.option}
                                    >
                                      {typesItem.name}
                                    </option>
                                  );
                                })
                              : ""}
                          </Input>

                          {detailsSelectedType && (
                            <small className={"m-2 p-0"}>
                              *{detailsSelectedType.description}
                            </small>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={9}>
                          <Input
                            id={"project_id"}
                            name={"project"}
                            type={"text"}
                            hidden={true}
                            defaultValue={projectId}
                          />
                        </Col>
                      </FormGroup>
                      {uploadFileNeeded ? (
                        <FormGroup row>
                          <Label for={"file"} sm={3}>
                            Arquivo
                          </Label>
                          <Col sm={9}>
                            <Input
                              required={uploadFileNeeded ? true : false}
                              onChange={(file) => handleUpload(file)}
                              id={"file"}
                              name={"file"}
                              type={"file"}
                              accept=".xlsx,.xls"
                            />
                          </Col>
                        </FormGroup>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        <Button className="col-8 m-auto">
                          Criar Relatório
                        </Button>
                      </div>
                    </Form>
                  )
                ) : (
                  <h4>Sem permissão para acessar esta área.</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NewReport;
