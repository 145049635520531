import {
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import React from "react";
import { Spinner, Row, Col } from "reactstrap";

const ORIGIN = Object.freeze({ x: 0, y: 0 });

const { devicePixelRatio: ratio = 1 } = window;

function diffPoints(p1, p2) {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1, p2) {
  return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1, scale) {
  return { x: p1.x / scale, y: p1.y / scale };
}

function convertToColor(string) {
  let sum = 0;
  for (let i = 0; i < string.length; i++) {
    let code = string.charCodeAt(i);
    sum += code;
  }
  while (sum.toString().length <= 6) {
    sum = sum * 3;
  }
  let hexStr = sum.toString(16);
  return hexStr;
}

const imgSprite = new Image();

const ZOOM_SENSITIVITY = 1000;
export default function Canvas(props) {
  const canvasRef = useRef();
  let apiUrl = process.env.REACT_APP_API_URL;

  let imageRatio = imgSprite.width / imgSprite.height;
  // let canvasWidth = window.innerWidth * 1; //0.7;
  let canvasWidth = parseInt(window.innerWidth) - 18; //0.7;
  if (window.innerWidth >= 1200) canvasWidth = 1140;
  else if (window.innerWidth >= 992) canvasWidth = 798;
  else if (window.innerWidth >= 768) canvasWidth = 498;
  else if (window.innerWidth >= 576) canvasWidth = 498;

  let canvasHeight = (canvasWidth / imageRatio) * 1; // 0.7;
  const activeImageDetection =
    props.activeDetection && props.activeDetection != null
      ? props.activeDetection
      : props.detectionsData[0];

  const [activeImagePath, setactiveImagePath] = useState(null);
  const [updateImage, setUpdateImage] = useState(null);
  const [context, setContext] = useState(null);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState(ORIGIN);
  const [mousePos, setMousePos] = useState(ORIGIN);
  const [viewportTopLeft, setViewportTopLeft] = useState(ORIGIN);
  const isResetRef = useRef(false);
  const lastMousePosRef = useRef(ORIGIN);
  const lastOffsetRef = useRef(ORIGIN);
  const [loadingImage, setLoadingImage] = useState(true);

  imgSprite.onload = function () {
    setUpdateImage(activeImageDetection);
    setLoadingImage(false);
  };
  useEffect(() => {
    if (activeImageDetection) {
      setLoadingImage(true);
      fetch(
        apiUrl + "/detections/" + activeImageDetection.id + "/image?size=",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.authorizationToken,
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Erro");
          }
        })
        .then((ei0) => {
          try {
            ei0 = JSON.parse(ei0);
          } catch (error) {}
          setactiveImagePath(ei0.path);
          setUpdateImage(Math.random());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageDetection]);

  useEffect(() => {
    if (activeImageDetection) {
      imgSprite.src = activeImagePath;

      var canvas = canvasRef.current;
      var ctxBg = canvas.getContext("2d");
      ctxBg.drawImage(
        imgSprite,
        0,
        0,
        canvasWidth * scale,
        (canvasWidth * scale) / imageRatio
      );

      // props.detectionsData.forEach(ep => {
      //     ctxBg.strokeStyle = "#" + convertToColor(ep.algorithm);
      //     ctxBg.strokeRect(
      //         (ep.xcenter_bb - ep.width_bb / 2) * canvas.width * scale,
      //         (ep.ycenter_bb - ep.height_bb / 2) * canvas.height * scale,
      //         ep.width_bb * canvas.width * scale,
      //         ep.height_bb * canvas.height * scale
      //     );

      //     ctxBg.fillStyle = "#" + convertToColor(ep.algorithm);
      //     ctxBg.font = (20 * scale|0) + 'px Verdana';
      //     ctxBg.fillText(
      //         ep.algorithm,
      //         (ep.xcenter_bb - ep.width_bb / 2) * (canvas.width + 20) * scale,
      //         (ep.ycenter_bb - ep.height_bb / 2) * (canvas.height - 20) * scale
      //     );
      // });
      ctxBg.strokeStyle = "#" + convertToColor(activeImageDetection.algorithm);
      ctxBg.strokeRect(
        (activeImageDetection.xcenter_bb - activeImageDetection.width_bb / 2) *
          canvas.width *
          scale,
        (activeImageDetection.ycenter_bb - activeImageDetection.height_bb / 2) *
          canvas.height *
          scale,
        activeImageDetection.width_bb * canvas.width * scale,
        activeImageDetection.height_bb * canvas.height * scale
      );

      ctxBg.fillStyle = "#" + convertToColor(activeImageDetection.algorithm);
      ctxBg.lineWidth = 3;
      ctxBg.font = "bold " + ((15 * scale) | 0) + "px Verdana";
      ctxBg.fillText(
        //activeImageDetection.algorithm,
        //activeImageDetection.id,
        "Qual.: " + Math.ceil(activeImageDetection.quality * 10000) / 100 + "%",
        (activeImageDetection.xcenter_bb - activeImageDetection.width_bb / 2) *
          (canvas.width + 20) *
          scale,
        (activeImageDetection.ycenter_bb - activeImageDetection.height_bb / 2) *
          (canvas.height - 20) *
          scale
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateImage]);

  useEffect(() => {
    lastOffsetRef.current = offset;
  }, [offset]);

  const reset = useCallback(
    (context) => {
      if (context && !isResetRef.current) {
        context.canvas.width = canvasWidth * ratio;
        context.canvas.height = canvasHeight * ratio;
        context.scale(ratio, ratio);
        setScale(1);

        setContext(context);
        setOffset(ORIGIN);
        setMousePos(ORIGIN);
        setViewportTopLeft(ORIGIN);
        lastOffsetRef.current = ORIGIN;
        lastMousePosRef.current = ORIGIN;

        isResetRef.current = true;
      }
    },
    [canvasWidth, canvasHeight]
  );

  // const [moveStart, setMoveStart] = useState(false);

  // const [viewPortState, setViewPortState] = useState(null);

  // useEffect(() => {
  //   if (viewportTopLeft && viewportTopLeft.x !== 0 && viewportTopLeft.y !== 0) {
  //     setViewPortState(viewportTopLeft);
  //   }
  // }, [viewportTopLeft]);

  const mouseMove = useCallback(
    (event) => {
      if (context) {
        const lastMousePos = lastMousePosRef.current;
        const currentMousePos = { x: event.pageX, y: event.pageY };
        lastMousePosRef.current = currentMousePos;

        const mouseDiff = diffPoints(currentMousePos, lastMousePos);
        setOffset((prevOffset) => {
          // console.log(prevOffset, mouseDiff);
          // let viewPort = JSON.parse(JSON.stringify(viewportTopLeft));
          // console.log(viewPort);
          // if (
          //   viewPortState &&
          //   viewPortState.x <= 0
          //   //  ||
          //   // (mouseDiff && mouseDiff.x > -1)
          //   // ((prevOffset && prevOffset.x > -1) ||
          //   //   (mouseDiff && mouseDiff.x > -1)) &&
          //   // ((prevOffset && prevOffset.y > -1) ||
          //   //   (mouseDiff && mouseDiff.y > -1))
          //   // (prevOffset && prevOffset.y > 0) ||
          //   // (mouseDiff && mouseDiff.y > 0)
          // ) {
          //   console.log("mve");
          return addPoints(prevOffset, mouseDiff);
          // }
          // else return addPoints(prevOffset, { x: 0, y: 0 });
        });
      }
    },
    [context]
  );

  const mouseUp = useCallback(() => {
    document.removeEventListener("mousemove", mouseMove);
    document.removeEventListener("mouseup", mouseUp);
  }, [mouseMove]);

  const startPan = useCallback(
    (event) => {
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
      lastMousePosRef.current = { x: event.pageX, y: event.pageY };
    },
    [mouseMove, mouseUp]
  );

  useLayoutEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext("2d");

      if (renderCtx) {
        reset(renderCtx);
      }
    }
  }, [reset, canvasHeight, canvasWidth]);
  useLayoutEffect(() => {
    if (context && lastOffsetRef.current) {
      const offsetDiff = scalePoint(
        diffPoints(offset, lastOffsetRef.current),
        scale
      );
      context.translate(offsetDiff.x, offsetDiff.y);
      setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
      isResetRef.current = false;
    }
  }, [context, offset, scale]);

  // draw
  useLayoutEffect(() => {
    if (context) {
      const storedTransform = context.getTransform();
      // eslint-disable-next-line
      context.canvas.width = context.canvas.width;
      context.setTransform(storedTransform);
      setUpdateImage(Math.random);
    }
  }, [canvasWidth, canvasHeight, context, scale, offset, viewportTopLeft]);

  useEffect(() => {
    const canvasElem = canvasRef.current;

    function handleUpdateMouse(event) {
      event.preventDefault();
      if (canvasRef.current) {
        const viewportMousePos = { x: event.clientX, y: event.clientY };

        const topLeftCanvasPos = {
          x: (window.innerWidth - canvasRef.current.width) / 2,
          y: 100,
        };
        let mousePosition = diffPoints(viewportMousePos, topLeftCanvasPos);

        setMousePos(mousePosition);
      }
    }
    canvasElem.removeEventListener("mousemove", handleUpdateMouse);
    canvasElem.addEventListener("mousemove", handleUpdateMouse);
  }, []);

  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }
    function handleWheel(event) {
      event.preventDefault();
      if (context) {
        // console.log(mousePos);
        const zoom = 1 - event.deltaY / ZOOM_SENSITIVITY;
        if (scale * zoom > 0.9 && scale * zoom < 2.5) {
          const viewportTopLeftDelta = {
            x: mousePos.x * scale * (zoom - 1 / zoom),
            y: mousePos.y * scale * (zoom - 1 / zoom),
          };
          const newViewportTopLeft = addPoints(
            viewportTopLeft,
            viewportTopLeftDelta
          );

          context.translate(viewportTopLeft.x, viewportTopLeft.y);
          context.scale(zoom, zoom);
          context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);

          setViewportTopLeft(newViewportTopLeft);

          setScale(scale * zoom);
        }
        isResetRef.current = false;
      }
    }

    canvasElem.addEventListener("wheel", handleWheel);
    return () => canvasElem.removeEventListener("wheel", handleWheel);
  }, [context, mousePos.x, mousePos.y, viewportTopLeft, scale]);

  return (
    <Row className="m-0 p-0">
      <Col className="m-0 p-0">
        <canvas
          onMouseDown={startPan}
          ref={canvasRef}
          width={canvasWidth * ratio}
          height={canvasHeight && ratio ? canvasHeight * ratio : 1000}
          className={"m-0 p-0"}
          style={{
            display: loadingImage ? "none" : "block",
            border: "2px solid #000",
            width: `${canvasWidth}px`,
            height: "auto",
            //height: `${canvasHeight}px`
            backgroundColor: "black",
          }}
        ></canvas>
        <div
          style={{
            textAlign: "center",
            display: loadingImage ? "block" : "none",
          }}
        >
          <Spinner
            color="primary"
            style={{
              margin: "auto",
              height: "8em",
              width: "8em",
            }}
          ></Spinner>
          <p color="primary">Carregando imagem...</p>
        </div>
      </Col>
    </Row>
    // <div row>

    // </div>
  );
}
